import { Tooltip } from "react-tooltip";
import usePathName from "../../hooks/usePathName";
import { api } from "../../config/Api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slice/BaseSlice";
import { handleCatchResponse } from "../../helper/helper";
import { useEffect, useState } from "react";
import { dataService } from "../../config/DataService";
import { AxiosError } from "axios";
import { DashboardFilterInterface, dashboardInterface } from "../../helper/interface";
import { Formik } from "formik";
import Filter from "./DashboardFilter";
import { User_Type, camelCaseToTitleCase, dashboardFilterValidation } from "../../helper/constant";

const DashboardStatistics = () => {
    usePathName([{ pathName: "Dashboard", href: "/dashboard" }]);
    const dispatch = useDispatch();
    const [dashBoardData, setDashBoardData] = useState<dashboardInterface | null>(null);

    const initialValues: DashboardFilterInterface = {
        userType: User_Type.ALL,
        relieverId: null,
        schedulerId: null,
        filterType: null,
        from: null,
        to: null,
    };

    const fetchData = async (values?: DashboardFilterInterface) => {
        try {
            dispatch(setLoading(true));

            const payload = {
                ...(values?.filterType ? { filterType: values?.filterType } : {}),
                ...(values?.userType && values.userType !== User_Type.ALL ? { userType: values.userType } : {}),
                ...(values?.relieverId ? { relieverId: values?.relieverId?.relieverId } : {}),
                ...(values?.schedulerId ? { schedulerId: values?.schedulerId?.schedulerId } : {}),
                ...(values?.from ? { from: values?.from } : {}),
                ...(values?.to ? { to: values?.to } : {}),
            };

            const response = await dataService.post(api.admin.dashboard, payload);

            setDashBoardData(response.data.data);
        } catch (error) {
            console.log("error ", error);

            handleCatchResponse(error as AxiosError);
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        fetchData(initialValues);
    }, []);
    return (
        <>
            <div className="col-span-12 lg:col-span-8 xl:col-span-6 mt-10">
                <div>
                    <div className="grid grid-cols-12 gap-6 mt-16">
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 cursor-pointer">
                            <div className="report-box">
                                <div className="box p-5">
                                    <Tooltip id="simpleTooltipForGrowthUsers" place="top" className="z-50" />
                                    <div className="text-2xl font-semibold leading-8 mt-6 flex items-center justify-between">
                                        {dashBoardData?.totalUser}
                                    </div>
                                    <div className="flex text-lg font-semibold text-slate-500 mt-2">
                                        <span className="mr-2"></span>
                                        <span>Total Users</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 cursor-pointer">
                            <div className="report-box">
                                <div className="box p-5">
                                    <Tooltip id="simpleTooltipForGrowthUsers" place="top" className="z-50" />
                                    <div className="text-2xl font-semibold leading-8 mt-6 flex items-center justify-between">
                                        {dashBoardData?.totalReliever}
                                    </div>
                                    <div className="flex text-lg font-semibold text-slate-500 mt-2">
                                        <span className="mr-2"></span>
                                        <span>Total Reliever</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 cursor-pointer">
                            <div className="report-box">
                                <div className="box p-5">
                                    <Tooltip id="simpleTooltipForGrowthUsers" place="top" className="z-50" />
                                    <div className="text-2xl font-semibold leading-8 mt-6 flex items-center justify-between">
                                        {dashBoardData?.totalScheduler}
                                    </div>
                                    <div className="flex text-lg font-semibold text-slate-500 mt-2">
                                        <span className="mr-2"></span>
                                        <span>Total Scheduler</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 cursor-pointer">
                            <div className="report-box">
                                <div className="box p-5">
                                    <Tooltip id="simpleTooltipForGrowthUsers" place="top" className="z-50" />
                                    <div className="text-2xl font-semibold leading-8 mt-6 flex items-center justify-between">
                                        {dashBoardData?.analytics?.totalCompletedJob}
                                    </div>
                                    <div className="flex text-lg font-semibold text-slate-500 mt-2">
                                        <span className="mr-2"></span>
                                        <span>Completed Job</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
                        <div className="flex justify-between">
                            <div className="intro-y items-center h-10">
                                <h2 className="text-lg font-medium truncate mr-5">Analytics</h2>
                            </div>

                            <div className="mt-5 sm:mt-0 mr-2 sm:mr-0 w-3/4 items-center">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    onSubmit={fetchData}
                                    validationSchema={dashboardFilterValidation}
                                >
                                    {(props) => <Filter {...props} fetchData={fetchData} />}
                                </Formik>
                            </div>
                        </div>

                        {dashBoardData?.analytics && (
                            <div className="flex items-center justify-between flex-wrap !w-full">
                                <div className="intro-y box p-3 mt-2 flex justify-center">
                                    <div className="flex flex-wrap " style={{ columnGap: "50px" }}>
                                        {Object.keys(dashBoardData?.analytics).map((objKey, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center mt-4 justify-between dashboard-data-analytics"
                                                style={{ width: "calc(25% - 50px)" }}
                                            >
                                                <div className="flex items-center">
                                                    <div className="w-2 h-2 bg-pending rounded-full mr-3"></div>
                                                    <span className="truncate">{camelCaseToTitleCase(objKey)}</span>
                                                </div>
                                                <span className="font-medium xl:ml-auto">
                                                    {dashBoardData?.analytics[objKey as keyof typeof dashBoardData.analytics]}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardStatistics;
