import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import usePathName from "../../hooks/usePathName";
import { useNavigate } from "react-router-dom";
import { InvoiceInterface } from "../../helper/interface";
import { ArrowLeftCircle } from "react-feather";
import AddEditInvoiceForm from "./AddEditInvoiceForm";
import { onAddInvoice } from "../../service/invoice";
import { addOrEditInvoiceValidation } from "../../helper/constant";

const AddInvoice: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "invoice", href: "/invoice" },
        { pathName: "Add", href: "/invoice/add " },
    ]);

    const onSubmit = (values: InvoiceInterface) => {
        onAddInvoice(values, dispatch, navigate);
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/invoice")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add invoice</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik
                    initialValues={{
                        fromDate: "",
                        toDate: "",
                        invoiceFile: "",
                        description: "",
                        amount: "",
                        schedulerId: "",
                        dueDate: "",
                    }}
                    validationSchema={addOrEditInvoiceValidation}
                    onSubmit={onSubmit}
                >
                    {(props) => <AddEditInvoiceForm {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddInvoice;
