import React, { useCallback, useState } from "react";
import usePathName from "../../../hooks/usePathName";
import { ArrowLeftCircle, Edit, Eye, Trash2 } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import {
    DocumentEditInterface,
    DocumentStateInterface,
    DocumentsInterface,
    RelieverInterface,
} from "../../../helper/interface";
import { api } from "../../../config/Api";
import DocumentUpload from "../../modal/DocumentUpload";
import { onDeleteDocument, uploadDocument } from "../../../service/user";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import DeleteModal from "../../modal/DeleteModal";
import useTable from "../../../hooks/useTable";
import CustomTable from "../../table/CustomTable";
import moment from "moment";
import EditDocumentModal from "../../modal/EditDocumentModal";

const Documents = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const loading = useSelector((state: StateInterface) => state.base.loading);

    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [files, setFiles] = useState<DocumentStateInterface[]>([]);
    const [editDocument, setEditDocument] = useState<DocumentEditInterface | null>(null);

    const { relieverId = "" } = useParams();

    const documentTable = useTable<DocumentsInterface, RelieverInterface>("document", `${api.admin.relieverDocuments}/${relieverId}`);

    usePathName([
        { pathName: "User", href: "/user/reliever" },
        { pathName: "Reliever", href: "/user/reliever" },
        { pathName: "Documents", href: "/user/reliever/documents" },
    ]);

    const cols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: DocumentsInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "PREVIEW",
            key: "url",
            render: (record: DocumentsInterface) => {
                return (
                    <span>
                        <a href={record.url} target="_blank" className="text-primary flex justify-center">
                            <Eye className="w-5 h-5" />
                        </a>
                    </span>
                );
            },
        },
        {
            title: "NAME",
            key: "name",
            render: (record: DocumentsInterface) => {
                return (
                    <>
                        <Tooltip id="simpleTooltipForDocumentName" place="top" />
                        <span className="documentName" data-tooltip-id="simpleTooltipForDocumentName" data-tooltip-content={record.name}>
                            {record.name}
                        </span>
                    </>
                );
            },
        },
        {
            title: "EXPIRY",
            key: "expiryDate",
            sortable: true,
            render: (record: DocumentsInterface) => {
                return (
                    <span className={`${record?.expiryDate && new Date() > new Date(record?.expiryDate) ? "text-warning" : ""}`}>
                        {record.expiryDate ? moment(record?.expiryDate).format("DD-MM-YYYY") : "-"}
                    </span>
                );
            },
        },
        {
            title: "STATUS",
            key: "status",
            render: (record: DocumentsInterface) => {
                return <span>{record.status}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: DocumentsInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDeleteBreak" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteBreak"
                                data-tooltip-content={"Edit document"}
                                onClick={() =>
                                    setEditDocument({
                                        documentId: record.documentId,
                                        documentType: record.documentType,
                                        expiryDate: record.expiryDate,
                                        name: record.name,
                                        status: record.status,
                                    })
                                }
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                Edit
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteBreak"
                                data-tooltip-content={"Delete document"}
                                onClick={() => setDeleteId(record.documentId)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const handleCancelForUploadModal = () => {
        setIsOpen(false);
        setFiles([]);
    };

    const onReset = () => {
        setFiles([]);
    };

    const onUploadSuccess = () => {
        documentTable.fetchApi();
        handleCancelForUploadModal();
    };

    const onSubmit = () => {
        uploadDocument(files, +relieverId, dispatch, onUploadSuccess);
    };

    const deleteDocumentClick = useCallback(() => {
        if (deleteId) {
            const onSuccess = () => {
                setDeleteId(null);
                documentTable.fetchApi();
            };
            onDeleteDocument(deleteId, dispatch, onSuccess);
        }
    }, [deleteId, documentTable.fetchApi]);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5 document-list">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/user/reliever")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">
                    Reliever documents ({documentTable?.res?.extraData?.name})
                </div>
                <Tooltip id="simpleTooltipAddDocument" place="top" />
                <button
                    className="btn btn-primary mr-5 sm:w-auto"
                    data-tooltip-id="simpleTooltipAddDocument"
                    data-tooltip-content="Add new document"
                    onClick={() => setIsOpen(true)}
                    disabled={documentTable?.res?.extraData?.deletedAt ? true : false}
                >
                    Add
                </button>
            </div>
            {deleteId ? (
                <DeleteModal
                    handleCancel={() => setDeleteId(null)}
                    isOpen={!!deleteId}
                    onDelete={deleteDocumentClick}
                    subTitle="Do you really want to delete this document?"
                />
            ) : null}
            {editDocument?.documentId ? (
                <EditDocumentModal
                    handleCancel={() => setEditDocument(null)}
                    id={editDocument?.documentId}
                    isOpen={!!editDocument?.documentId}
                    onSubmitSuccess={() => {
                        setEditDocument(null);
                        documentTable.fetchApi();
                    }}
                    value={editDocument}
                />
            ) : null}
            <DocumentUpload
                isOpen={isOpen}
                handleCancel={handleCancelForUploadModal}
                files={files}
                setFiles={setFiles}
                onReset={onReset}
                onSubmit={onSubmit}
                subTitle="Upload document"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={cols}
                    data={documentTable.tableData ?? []}
                    tableKey="documentId"
                    reload={documentTable.fetchApi}
                    currentOrder={documentTable.currentOrder}
                    error={documentTable.error}
                    loading={documentTable.loading}
                    tableOffset={documentTable.tableOffset}
                    onSetOrderBy={documentTable.onSetOrderBy}
                />
            </div>
        </div>
    );
};

export default Documents;
