import React from "react";

const DeleteSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            icon-name="x-circle"
            data-lucide="x-circle"
            className="lucide lucide-x-circle w-16 h-16 text-danger mx-auto mt-3"
        >
            <circle cx={12} cy={12} r={10} />
            <line x1={15} y1={9} x2={9} y2={15} />
            <line x1={9} y1={9} x2={15} y2={15} />
        </svg>
    );
};

export default DeleteSvg;
