import { AlertTriangle, Edit, FilePlus, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../config/Api";
import { InvoiceTableInterface } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { useCallback, useState } from "react";
import DeleteModal from "../modal/DeleteModal";
import { onInvoiceDelete } from "../../service/invoice";
import { useDispatch } from "react-redux";
import SearchForm from "../table/SearchForm";

const Invoice = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([{ pathName: "Invoice", href: "/invoice" }]);

    const [invoice, setInvoice] = useState<InvoiceTableInterface>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const invoiceTable = useTable<InvoiceTableInterface>("invoice", api.admin.getInvoice);

    const onDeleteClick = useCallback((record: InvoiceTableInterface) => {
        setInvoice(record);
        setIsOpen(true);
    }, []);

    const onDelete = () => {
        if (invoice?.invoiceId) {
            onInvoiceDelete(invoice?.invoiceId, dispatch, invoiceTable.fetchApi, setIsOpen);
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const invoiceCols = [
        {
            title: "#",
            key: "id",
            render: (record: InvoiceTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "SCHEDULER",
            key: "schedulername",
            sortable: false,
            render: (record: InvoiceTableInterface) => {
                return <span>{record.scheduler.name}</span>;
            },
        },
        {
            title: "DESCRIPTION",
            key: "description",
            sortable: false,
            render: (record: InvoiceTableInterface) => {
                return <span>{record.description}</span>;
            },
        },
        {
            title: "FROM DATE",
            key: "from date",
            sortable: false,
            render: (record: InvoiceTableInterface) => {
                const clockin = moment(record?.fromDate).format("DD/MM/YYYY");
                return <span>{clockin}</span>;
            },
        },
        {
            title: "TO DATE",
            key: "to date",
            sortable: false,
            render: (record: InvoiceTableInterface) => {
                const clockout = moment(record?.toDate).format("DD/MM/YYYY");
                return <span>{clockout}</span>;
            },
        },
        {
            title: "DUE DATE",
            key: "due date",
            sortable: false,
            render: (record: InvoiceTableInterface) => {
                const dueDate = moment(record?.dueDate).format("DD/MM/YYYY");
                return <span>{dueDate}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: InvoiceTableInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center cursor-pointer">
                            {record.invoiceFile ? (
                                <div className="flex justify-center items-center text-primary">
                                    <Tooltip id="docs" place="top" />
                                    <div
                                        className="flex items-center mr-3 cursor-pointer"
                                        data-tooltip-id="docs"
                                        data-tooltip-content={"Show invoice"}
                                    >
                                        <Link to={record.invoiceFile} target="_blank">
                                            <FilePlus className="w-4 h-4 mr-1" />
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="flex justify-center items-center text-primary">
                                <Tooltip id="simpleTooltipEditDeleteAgeGroup" place="top" />
                                <div
                                    className="flex items-center mr-3 cursor-pointer"
                                    data-tooltip-id="simpleTooltipEditDeleteAgeGroup"
                                    data-tooltip-content={"Edit invoice"}
                                    onClick={() => navigate(`/invoice/edit/${record.invoiceId}`)}
                                >
                                    <Edit className="w-4 h-4 mr-1" />
                                    Edit
                                </div>
                            </div>
                            <button
                                className={`flex items-center ${record?.deletedAt ? "color-red" : "text-danger"} cursor-pointer`}
                                data-tooltip-id="deleteInvoice"
                                data-tooltip-content={"Delete reliever"}
                                onClick={() => onDeleteClick(record)}
                            >
                                {record?.deletedAt ? <AlertTriangle className="w-4 h-4 mr-1" /> : <Trash2 className="w-4 h-4 mr-1" />}
                                Delete
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this reliever?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={invoiceCols}
                    data={invoiceTable.tableData ?? []}
                    tableKey="invoiceId"
                    reload={invoiceTable.fetchApi}
                    currentOrder={invoiceTable.currentOrder}
                    error={invoiceTable.error}
                    loading={invoiceTable.loading}
                    tableOffset={invoiceTable.tableOffset}
                    onSetOrderBy={invoiceTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={invoiceTable.resetSearch}
                                onSearch={invoiceTable.onSearch}
                                searchPlaceHolder="Search by name or email"
                            />
                            <div className="flex flex-col w-full mt-5 sm:mt-0 sm:flex-row sm:w-auto mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltipInvoice" place="top" />
                                <button
                                    data-tooltip-id="simpleTooltipInvoice"
                                    data-tooltip-content="Add new invoice"
                                    className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    onClick={() => {
                                        navigate("/invoice/add");
                                    }}
                                >
                                    Add new
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Invoice;
