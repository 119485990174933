import React from "react";
import Modal from "./Modal";
import { NotificationModalInterface, SpecificUserNotification, StateInterface } from "../../helper/interface";
import { specificUserValidation } from "../../helper/constant";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../container/Loading";
import { Form, Formik, FormikHelpers } from "formik";
import { setLoading } from "../../store/slice/BaseSlice";
import { successToast } from "../../helper/toast";
import { handleCatchResponse } from "../../helper/helper";
import { dataService } from "../../config/DataService";
import { api } from "../../config/Api";
import { AxiosError } from "axios";

const NotifyUserModal = ({ isOpen, handleCancel, relieverId, schedulerId }: NotificationModalInterface) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);
    const dispatch = useDispatch();

    const onSubmit = async (values: SpecificUserNotification, { resetForm }: FormikHelpers<SpecificUserNotification>) => {
        try {
            dispatch(setLoading(true));

            const payload = {
                title: values.title,
                description: values.description,
                ...(relieverId ? { relieverId } : {}),
                ...(schedulerId ? { schedulerId } : {}),
            };

            const response = await dataService.post(api.admin.specificUserNotification, payload);

            handleCancel();
            successToast(response.data?.message);
        } catch (error) {
            console.log("error ", error);

            handleCatchResponse(error as AxiosError);
        } finally {
            resetForm();
            dispatch(setLoading(false));
        }
    };

    return (
        <Modal isOpen={isOpen}>
            <>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <div className="p-5 text-center">
                        <Formik
                            initialValues={{
                                title: "",
                                description: "",
                            }}
                            validationSchema={specificUserValidation}
                            onSubmit={onSubmit}
                        >
                            {({ handleChange, handleBlur, values, errors, touched, dirty }) => (
                                <Form style={{ textAlign: "left" }}>
                                    <h1 className="text-left font-bold">Send notification</h1>
                                    <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                        <div className="intro-y col-span-12 sm:col-span-6">
                                            <label htmlFor="title" className="form-label">
                                                Title
                                            </label>
                                            <input
                                                id="title"
                                                name="title"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.title}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.title && touched.title && "border-danger"}`}
                                                placeholder="Enter your notification title here"
                                            />
                                            {errors.title && touched.title && <div className="text-danger">{errors.title}</div>}
                                        </div>
                                        <div className="intro-y col-span-12 sm:col-span-6">
                                            <label htmlFor="description" className="form-label">
                                                Description
                                            </label>
                                            <input
                                                id="description"
                                                name="description"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.description}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.description && touched.description && "border-danger"}`}
                                                placeholder="Enter your description title here"
                                            />
                                            {errors.description && touched.description && (
                                                <div className="text-danger">{errors.description}</div>
                                            )}
                                        </div>
                                        <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                            <button className="btn btn-secondary w-24" type="reset" onClick={handleCancel}>
                                                cancel
                                            </button>
                                            <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </>
        </Modal>
    );
};

export default NotifyUserModal;
