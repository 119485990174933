import { Formik } from "formik";
import { addOrEditCentreValidation } from "../../helper/constant";
import AddOrEditCentre from "./AddOrEditCentre";
import { CentreInterface } from "../../helper/interface";
import { onEditCentre } from "../../service/centre";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import usePathName from "../../hooks/usePathName";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import Loading from "../../container/Loading";

const EditCentre = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { centreId = "" } = useParams();

    const { res, loading, error } = useFetch<CentreInterface>(`${api.admin.getCentre}/${centreId}`);

    usePathName([
        { pathName: "Centre", href: "/centre" },
        { pathName: "Edit", href: `/centre/edit/${centreId}` },
    ]);

    const onSubmit = (values: CentreInterface) => {
        const payload = {
            centreId: +centreId,
            centreName: values.centreName,
            postalCode: String(values.postalCode),
            schedulerId: values.schedulerId,
            streetAddress: values.streetAddress,
            addressLine: values.addressLine,
            suburbId: values.suburbId,
            showSelectReliever: values.showSelectReliever,
        };
        onEditCentre(payload, navigate, dispatch);
    };

    const oldVal = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/centre")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit centre</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addOrEditCentreValidation} onSubmit={onSubmit}>
                        {(props) => <AddOrEditCentre {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditCentre;
