import { FC } from "react";
import {
    Bell,
    Book,
    Briefcase,
    ChevronDown,
    ChevronUp,
    Coffee,
    FilePlus,
    MapPin,
    Settings,
    User,
    UserCheck,
    Users,
    Zap,
    File,
    Clock,
    Home,
} from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StateInterface } from "../../helper/interface";
import { useDispatch, useSelector } from "react-redux";
import { setTableData } from "../../store/slice/BaseSlice";

const Menu: FC = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    return (
        <>
            <nav className="side-nav">
                <ul>
                    <li>
                        <Link to="dashboard" className={`side-menu ${pathname.includes("dashboard") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Home />
                            </div>
                            <div className="side-menu__title"> Dashboard </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="user/scheduler" className={`side-menu ${pathname.includes("scheduler") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <User />
                            </div>
                            <div className="side-menu__title"> Schedulers </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="user/reliever" className={`side-menu ${pathname.includes("user/reliever") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <User />
                            </div>
                            <div className="side-menu__title"> Relievers </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="centre" className={`side-menu ${pathname.includes("centre") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <MapPin />
                            </div>
                            <div className="side-menu__title"> Centres </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="job/pending"
                            className={`side-menu ${pathname.includes("job/") && "side-menu--active"}`}
                            onClick={() => {
                                dispatch(setTableData({ ...tableData, tabFor: "pending" }));
                            }}
                        >
                            <div className="side-menu__icon">
                                <Briefcase />
                            </div>

                            <div className="side-menu__title flex justify-between">
                                Jobs
                                {pathname.includes("job/") ? (
                                    <ChevronUp aria-hidden="true" className="mr-4" />
                                ) : (
                                    <ChevronDown aria-hidden="true" className="mr-4" />
                                )}
                            </div>
                        </Link>

                        {pathname.includes("job/") && (
                            <ul
                                className="cursor-pointer side-menu__sub-open"
                                onClick={() => {
                                    navigate("/job/add");
                                }}
                            >
                                <div>
                                    <div className={`side-menu side-menu--active ${pathname.includes("job/add") ? "font-bold" : ""}`}>
                                        <div className="side-menu__icon">
                                            <FilePlus />
                                        </div>
                                        <div className="side-menu__title">Add new</div>
                                    </div>
                                </div>
                            </ul>
                        )}
                    </li>
                    <li>
                        <Link to="position" className={`side-menu ${pathname.includes("position") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Zap />
                            </div>
                            <div className="side-menu__title"> Positions </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="age-group" className={`side-menu ${pathname.includes("age-group") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Users />
                            </div>
                            <div className="side-menu__title"> Age groups </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="break" className={`side-menu ${pathname.includes("break") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Coffee />
                            </div>
                            <div className="side-menu__title"> Breaks </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="report" className={`side-menu ${pathname.includes("report") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Book />
                            </div>
                            <div className="side-menu__title"> Reports </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="availability" className={`side-menu ${pathname.includes("availability") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <UserCheck />
                            </div>
                            <div className="side-menu__title"> Availability </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="timesheet" className={`side-menu ${pathname.includes("timesheet") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Clock />
                            </div>
                            <div className="side-menu__title"> Timesheet </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="invoice" className={`side-menu ${pathname.includes("invoice") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <File />
                            </div>
                            <div className="side-menu__title"> Invoice </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="notification" className={`side-menu ${pathname.includes("notification") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Bell />
                            </div>
                            <div className="side-menu__title"> Notification </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="global-settings" className={`side-menu ${pathname.includes("global-settings") && "side-menu--active"}`}>
                            <div className="side-menu__icon">
                                <Settings />
                            </div>
                            <div className="side-menu__title"> Global settings </div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Menu;
