import { FC, PropsWithChildren } from "react";

interface Props {
    onChangeApproveType: (value: boolean) => void;
    showApproved: boolean;
}

const UserTabs: FC<PropsWithChildren & Props> = ({ children, onChangeApproveType, showApproved }) => {
    return (
        <>
            <div>
                <div className="box">
                    <ul className="nav nav-boxed-tabs mt-8" role="tablist">
                        <li className="nav-item flex-1" role="presentation">
                            <button
                                className={`nav-link w-full py-2 ${showApproved ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="true"
                                onClick={() => onChangeApproveType(true)}
                            >
                                Approved
                            </button>
                        </li>
                        <li className="nav-item flex-1" role="presentation" onClick={() => onChangeApproveType(false)}>
                            <button
                                className={`nav-link w-full py-2 ${!showApproved ? "active" : ""}`}
                                data-tw-toggle="pill"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                Un-Approved
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-5">{children}</div>
            </div>
        </>
    );
};

export default UserTabs;
