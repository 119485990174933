import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { addEditRelieverValidation } from "../../../helper/constant";
import usePathName from "../../../hooks/usePathName";
import AddEditReliever from "./AddEditReliever";
import { AddEditRelieverInterface } from "../../../helper/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onAddReliever } from "../../../service/user";
import { ArrowLeftCircle } from "react-feather";

const AddReliever: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([
        { pathName: "User", href: "/user/reliever" },
        { pathName: "Reliever", href: "/user/reliever" },
        { pathName: "Add", href: "/user/reliever/add" },
    ]);

    const onSubmit = (values: AddEditRelieverInterface, { resetForm }: FormikHelpers<AddEditRelieverInterface>) => {
        onAddReliever(values, dispatch, navigate, resetForm);
    };

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4">
                        <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/user/reliever")} />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new reliever</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik
                        initialValues={{
                            email: "",
                            name: "",
                            mobileNumber: "",
                            position: null,
                            wage: null,
                            registrationNo: null,
                            region: null,
                            district: null,
                            aidCertificate: false,
                            expiryOfAidCertificate: null,
                            suburb: null,
                            isActive: true,
                            about: null,
                            expiryOfRegistration: null,
                            unavailability: null,
                            academicTranscripts: null,
                            portrait: null,
                            cv: null,
                            workingEligibility: null,
                            primaryProofType: null,
                            primaryProofFile: null,
                            secondaryProofType: null,
                            secondaryProofFile: null,
                            workingHoursLetter: null,
                            IRDNumber: null,
                            account: null,
                            bank: null,
                            expiryOfPrimaryProofType: null,
                            expiryOfSecondaryProofType: null,
                            dob: null,
                            grade: null,
                            streetAddress: null,
                            postalCode: null,
                        }}
                        validationSchema={addEditRelieverValidation}
                        onSubmit={onSubmit}
                    >
                        {(props) => <AddEditReliever {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddReliever;
