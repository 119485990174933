import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { addOrEditAgeGroupValidation } from "../../helper/constant";
import { AgeGroupInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import AddEditAgeGroup from "./AddEditAgeGroup";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { onEditAgeGroup } from "../../service/ageGroup";

const EditAgeGroup: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ageGroupId = "" } = useParams();

    const { res, loading, error } = useFetch<AgeGroupInterface>(`${api.admin.getAgeGroup}/${ageGroupId}`);

    usePathName([
        { pathName: "Age group", href: "/age-group" },
        { pathName: "Edit", href: `/age-group/edit/${ageGroupId}` },
        { pathName: `${ageGroupId}`, href: `/age-group/edit/${ageGroupId}` },
    ]);

    const onSubmit = (values: AgeGroupInterface) => {
        const payload = {
            name: values.name,
            ageGroupId: +ageGroupId,
        };
        onEditAgeGroup(payload, navigate, dispatch);
    };

    const oldVal = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/age-group")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit age group</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addOrEditAgeGroupValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditAgeGroup {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditAgeGroup;
