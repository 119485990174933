import { useDispatch, useSelector } from "react-redux";
import { DocumentEditInterface, StateInterface, ZIndexMaintainer } from "../../helper/interface";
import Modal from "./Modal";
import Loading from "../../container/Loading";
import { Form, Formik, FormikHelpers } from "formik";
import { DocumentType, documentStatus, documentValidation } from "../../helper/constant";
import { Listbox, Transition } from "@headlessui/react";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { setLoading } from "../../store/slice/BaseSlice";
import { dataService } from "../../config/DataService";
import { api } from "../../config/Api";
import { successToast } from "../../helper/toast";
import { handleCatchResponse, onDatePickerClose, onDatePickerOpen } from "../../helper/helper";
import { AxiosError } from "axios";
import { X } from "react-feather";
import { useState } from "react";

interface Props {
    isOpen: boolean;
    handleCancel: () => void;
    onSubmitSuccess: () => void;
    value: DocumentEditInterface;
    id: number;
}

const EditDocumentModal = (props: Props) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);
    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const dispatch = useDispatch();

    const onSubmit = async (values: DocumentEditInterface, { resetForm }: FormikHelpers<DocumentEditInterface>) => {
        try {
            dispatch(setLoading(true));

            const payload = {
                status: values.status,
                documentType: values.documentType,
                expiryDate: values.expiryDate,
                documentId: props.id,
            };

            const response = await dataService.put(api.admin.updateDocument, payload);

            successToast(response.data?.message);
            props.onSubmitSuccess();
        } catch (error) {
            handleCatchResponse(error as AxiosError);
        } finally {
            resetForm();
            dispatch(setLoading(false));
        }
    };

    return (
        <Modal isOpen={props.isOpen}>
            <div className="modal-header">
                <h2 className="font-medium text-base w-full mr-auto">{props.value.name}</h2>
                <X className="w-8 h-8 text-slate-400 cursor-pointer" onClick={props.handleCancel}></X>
            </div>
            {loading ? (
                <Loading forTable={false} />
            ) : (
                <div className="p-3">
                    <Formik initialValues={props.value} onSubmit={onSubmit} validationSchema={documentValidation}>
                        {({ handleBlur, values, errors, touched, dirty, setFieldTouched, setFieldValue }) => (
                            <Form>
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                    <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                                        <label className="mb-2 form-label">Document type</label>
                                        <div className="tom-select">
                                            <Listbox
                                                value={values.documentType}
                                                onChange={(value: string) => {
                                                    setFieldValue("documentType", value);
                                                }}
                                                disabled={loading}
                                            >
                                                <Listbox.Button className="form-control ts-input">
                                                    <div className="item">{values?.documentType || "Select document type"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("deviceType", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {Object.values(DocumentType).map((data) => (
                                                                <Listbox.Option
                                                                    className={({ active }) =>
                                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                            active || values.documentType === data ? "optionColor" : null
                                                                        }`
                                                                    }
                                                                    key={data}
                                                                    value={data}
                                                                >
                                                                    {data || "Select document type"}
                                                                </Listbox.Option>
                                                            ))}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.documentType && touched.documentType && (
                                            <div className="text-danger">{errors.documentType}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                                        <label className="mb-2 form-label">Status</label>
                                        <div className="tom-select">
                                            <Listbox
                                                value={values.status}
                                                onChange={(value: string) => {
                                                    setFieldValue("status", value);
                                                }}
                                                disabled={loading}
                                            >
                                                <Listbox.Button className="form-control ts-input">
                                                    <div className="item">{values?.status || "Select status"}</div>
                                                </Listbox.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    onBlur={() => setFieldTouched("status", true)}
                                                >
                                                    <Listbox.Options className="ts-dropdown single w-full">
                                                        <div role="listbox" className="ts-dropdown-content">
                                                            {documentStatus.map((data) => (
                                                                <Listbox.Option
                                                                    className={({ active }) =>
                                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                            active || values.status === data ? "optionColor" : null
                                                                        }`
                                                                    }
                                                                    key={data}
                                                                    value={data}
                                                                >
                                                                    {data || "Select status"}
                                                                </Listbox.Option>
                                                            ))}
                                                        </div>
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                        {errors.status && touched.status && <div className="text-danger">{errors.status}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6 datePicker" style={{ zIndex: zIndex.expiryDate }}>
                                        <label htmlFor="dob" className="form-label">
                                            Expiry date
                                        </label>
                                        <CustomDatePicker
                                            handleBlur={handleBlur}
                                            id="expiryDate"
                                            name="expiryDate"
                                            onCalendarOpen={onDatePickerOpen(setZIndex, "expiryDate")}
                                            onCalendarClose={onDatePickerClose(setZIndex, "expiryDate")}
                                            disabled={loading}
                                            hasError={Boolean(errors.expiryDate && touched.expiryDate)}
                                            selected={values.expiryDate ? new Date(values.expiryDate) : null}
                                            setFieldValue={setFieldValue}
                                        />
                                        {errors.expiryDate && touched.expiryDate && <div className="text-danger">{errors.expiryDate}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                        <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                                            Reset
                                        </button>
                                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </Modal>
    );
};

export default EditDocumentModal;
