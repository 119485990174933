import { FC, useState, useCallback } from "react";
import { CheckSquare, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { ActivateDeactivateCentreInterface, CentreTableInterface } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import SearchForm from "../table/SearchForm";
import { api } from "../../config/Api";
import { onActivateDeactivateCentre, onDeleteCentre } from "../../service/centre";
import { useDispatch } from "react-redux";
import DeleteModal from "../modal/DeleteModal";
import { Tooltip } from "react-tooltip";

const Centre: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [centre, setCentre] = useState<CentreTableInterface | null>(null);

    usePathName([{ pathName: "Centre", href: "/centre" }]);

    const onDeleteClick = useCallback((record: CentreTableInterface) => {
        setCentre(record);
        setIsOpen(true);
    }, []);

    const centreTableCols = [
        {
            title: "#",
            key: "centreId",
            render: (record: CentreTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "centreName",
            sortable: true,
            render: (record: CentreTableInterface) => {
                return <span>{record.centreName}</span>;
            },
        },
        {
            title: "REGION",
            key: "region",
            render: (record: CentreTableInterface) => {
                return <span>{record.suburb.district?.region?.regionName}</span>;
            },
        },
        {
            title: "DISTRICT",
            key: "district",
            render: (record: CentreTableInterface) => {
                return <span>{record.suburb.district?.districtName}</span>;
            },
        },
        {
            title: "SUBURB",
            key: "suburb",
            render: (record: CentreTableInterface) => {
                return <span>{record.suburb.suburbName}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: CentreTableInterface) => {
                const payload = {
                    centreId: record.centreId,
                    isActive: !record.isActive,
                };
                return (
                    <div className="form-switch form-check flex justify-centre flex-col">
                        <input
                            id="isActive"
                            name="isActive"
                            onChange={() => onActivateDeactivate(payload)}
                            checked={record.isActive}
                            className={"form-check-input"}
                            type="checkbox"
                        />
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: CentreTableInterface) => {
                return (
                    <>
                        <div className="flex justify-centre items-centre text-primary">
                            <Tooltip id="simpleTooltipEditDeleteCentre" place="top" />
                            <div
                                className="flex items-centre mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteCentre"
                                data-tooltip-content={"Edit centre"}
                                onClick={() => navigate(`/centre/edit/${record.centreId}`)}
                            >
                                <CheckSquare className="w-4 h-4 mr-1" /> Edit
                            </div>
                            <div
                                className="flex items-centre text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteCentre"
                                data-tooltip-content={"Delete centre"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const centreTable = useTable<CentreTableInterface>("centre", api.admin.getCentre);

    const onActivateDeactivate = (payload: ActivateDeactivateCentreInterface) => {
        onActivateDeactivateCentre(payload, dispatch, centreTable.fetchApi);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const onDelete = () => {
        if (centre?.centreId) {
            onDeleteCentre(centre.centreId, dispatch, centreTable.fetchApi, setIsOpen);
        }
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this centre?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={centreTableCols}
                    data={centreTable.tableData ?? []}
                    tableKey="centreId"
                    reload={centreTable.fetchApi}
                    currentOrder={centreTable.currentOrder}
                    onSetOrderBy={centreTable.onSetOrderBy}
                    loading={centreTable.loading}
                    tableOffset={centreTable.tableOffset}
                    error={centreTable.error}
                    component={
                        <>
                            <SearchForm
                                resetSearch={centreTable.resetSearch}
                                onSearch={centreTable.onSearch}
                                searchPlaceHolder="Search by centre name"
                            />
                            <div className="flex flex-col w-full mt-5 sm:mt-0 sm:flex-row sm:w-auto mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltipCentre" place="top" />
                                <button
                                    className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipCentre"
                                    data-tooltip-content="Add new centre"
                                    onClick={() => {
                                        navigate("/centre/add");
                                    }}
                                >
                                    Add new
                                </button>
                                <button
                                    className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipCentre"
                                    data-tooltip-content="View all locations in New Zealand"
                                    onClick={() => {
                                        navigate("/centre/location");
                                    }}
                                >
                                    View all location
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Centre;
