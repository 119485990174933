import React from "react";
import Modal from "./Modal";
import { RelieverModalPayload, StateInterface } from "../../helper/interface";
import { useSelector } from "react-redux";
import Loading from "../../container/Loading";
import { Frown, X } from "react-feather";

const RelieverModal = ({ isOpen, handleCancel, relieverList }: RelieverModalPayload) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Modal isOpen={isOpen}>
            <div className="p-2 w-full overflow-auto">
                <div className="flex justify-between items-center p-2 border-b-2">
                    <h2 className="font-medium text-base w-full mr-auto">Accepted relievers</h2>
                    <X className="w-8 h-8 text-slate-400 cursor-pointer" onClick={handleCancel}></X>
                </div>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <>
                        {relieverList && relieverList.length !== 0 ? (
                            <table className="table">
                                <tbody>
                                    <tr className="tabulator-row tabulator-row-even">
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                    </tr>
                                    {relieverList?.map((ele, i: number) => (
                                        <tr key={ele?.relieverId}>
                                            <td>{i + 1}</td>
                                            <td>{ele?.name}</td>
                                            <td>{ele?.email}</td>
                                            <td>{ele?.mobileNumber}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="p-5 text-center">
                                <Frown className="w-16 h-16 text-success mx-auto mt-3" />
                                <p className="text-lg font-medium pt-4 pb-4">No accepted relievers</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default RelieverModal;
