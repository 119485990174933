import { useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Form } from "formik";
import useFetch from "../../hooks/useFetch";
import {
    CentreInterface,
    DistrictInterface,
    FormInterface,
    RegionInterface,
    SchedulerForSelect,
    StateInterface,
    SuburbInterface,
} from "../../helper/interface";
import { api } from "../../config/Api";
import Select, { MultiValue } from "react-select";
import { useSelector } from "react-redux";
import { showOnlyActiveUser } from "../../helper/constant";

const AddOrEditCentre = ({
    errors,
    touched,
    handleChange,
    setFieldValue,
    values,
    handleBlur,
    setFieldTouched,
    dirty,
}: FormInterface<CentreInterface>) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    const regions = useFetch<RegionInterface[]>(api.admin.getAllRegion);
    const districts = useFetch<DistrictInterface[]>(`${api.admin.getAllDistrict}/${values.region?.regionId ?? null}`);
    const suburbs = useFetch<SuburbInterface[]>(`${api.admin.getAllSuburb}/${values.district?.districtId ?? null}`);

    const schedulersData = useFetch<SchedulerForSelect[]>(`${api.admin.schedulersForCentre}?${showOnlyActiveUser()}`);

    useEffect(() => {
        const schedulerDetails = values.schedulerList;
        if (schedulerDetails) {
            const selectedSchedulers: SchedulerForSelect[] = [];
            schedulerDetails.forEach((data) => {
                selectedSchedulers.push(data.scheduler);
            });
            setFieldValue("schedulerId", selectedSchedulers);
        }
    }, [schedulersData.res]);

    const onChangeRegion = (value: RegionInterface) => {
        setFieldValue("region", value);
        setFieldValue("district", null);
        setFieldValue("suburb", null);
        districts.fetchApi(`${api.admin.getAllDistrict}/${value.regionId}`);
    };

    const onChangeDistrict = (value: DistrictInterface) => {
        setFieldValue("district", value);
        setFieldValue("suburb", null);
        suburbs.fetchApi(`${api.admin.getAllSuburb}/${value?.districtId}`);
    };

    const onChangeSuburb = (value: SuburbInterface) => {
        setFieldValue("suburb", value);
        setFieldValue("suburbId", value.suburbId);
    };

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="centreName" className="form-label">
                        Centre name
                    </label>
                    <input
                        id="centreName"
                        name="centreName"
                        type="text"
                        onChange={handleChange}
                        value={values.centreName}
                        onBlur={handleBlur}
                        className={`form-control ${errors.centreName && touched.centreName && "border-danger"}`}
                        placeholder="Name of the Centre"
                    />
                    {errors.centreName && touched.centreName && <div className="text-danger">{errors.centreName}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Schedulers</label>
                    <div className="">
                        {schedulersData?.res?.data ? (
                            <Select
                                isMulti
                                name="schedulerId"
                                options={schedulersData?.res?.data}
                                value={values?.schedulerId}
                                onBlur={() => setFieldTouched("schedulerId", true)}
                                onChange={(selectedItem: MultiValue<SchedulerForSelect>) => {
                                    setFieldValue("schedulerId", selectedItem);
                                }}
                                className={`form-control tom-select ${errors.schedulerId && touched.schedulerId && "border-danger"}`}
                                classNamePrefix="select"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.schedulerId}
                            />
                        ) : null}
                    </div>
                    {errors.schedulerId && touched.schedulerId && <div className="text-danger">{errors.schedulerId}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="streetAddress" className="form-label">
                        Street address
                    </label>
                    <input
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        onChange={handleChange}
                        value={values.streetAddress}
                        onBlur={handleBlur}
                        className={`form-control ${errors.streetAddress && touched.streetAddress && "border-danger"}`}
                        placeholder="1, ABC building"
                    />
                    {errors.streetAddress && touched.streetAddress && <div className="text-danger">{errors.streetAddress}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="addressLine" className="form-label">
                        Address Line
                    </label>
                    <input
                        id="addressLine"
                        name="addressLine"
                        type="text"
                        onChange={handleChange}
                        value={values.addressLine}
                        onBlur={handleBlur}
                        className={`form-control ${errors.addressLine && touched.addressLine && "border-danger"}`}
                        placeholder="Area or zone"
                    />
                    {errors.addressLine && touched.addressLine && <div className="text-danger">{errors.addressLine}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Region</label>
                    <div className="tom-select">
                        <Listbox value={values.region} onChange={onChangeRegion}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.region?.regionName ?? "Select a region"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("region", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {regions.res?.data ? (
                                            <>
                                                {regions.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.region?.regionName === data?.regionName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.regionId}
                                                        value={data}
                                                    >
                                                        {data.regionName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.region && touched.region && <div className="text-danger">{errors.region}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">District</label>
                    <div className="tom-select">
                        <Listbox value={values.district} onChange={onChangeDistrict} disabled={!values.region && true}>
                            <Listbox.Button className={`ts-input ${!values.region && "disabled"}`}>
                                <div className="item">{values?.district?.districtName ?? "Select a district"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("district", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {districts.res?.data ? (
                                            <>
                                                {districts.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.district?.districtName === data?.districtName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.districtId}
                                                        value={data}
                                                    >
                                                        {data.districtName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.district && touched.district && <div className="text-danger">{errors.district}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Suburb</label>
                    <div className="tom-select">
                        <Listbox value={values.suburb} name="suburb" onChange={onChangeSuburb} disabled={!values.district && true}>
                            <Listbox.Button id="suburb" className={`ts-input ${!values.district && "disabled"}`}>
                                <div className="item">{values?.suburb?.suburbName ?? "Select a suburb"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("suburb", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {suburbs.res?.data ? (
                                            <>
                                                {suburbs.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.suburb?.suburbName === data?.suburbName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.suburbId}
                                                        value={data}
                                                    >
                                                        {data.suburbName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                        {errors.suburb && touched.suburb && <div className="text-danger">{errors.suburb}</div>}
                    </div>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="postalCode" className="form-label">
                        Postal code
                    </label>
                    <input
                        id="postalCode"
                        name="postalCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postalCode}
                        type="number"
                        className={`form-control ${errors.postalCode && touched.postalCode && "border-danger"}`}
                        placeholder="2012"
                    />
                    {errors.postalCode && touched.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Show select relievers</label>
                    <div className="form-switch form-check" style={{ height: "37px" }}>
                        <input
                            id="showSelectReliever"
                            name="showSelectReliever"
                            onChange={() => setFieldValue("showSelectReliever", !values.showSelectReliever)}
                            checked={values.showSelectReliever}
                            className={"form-check-input"}
                            type="checkbox"
                        />
                    </div>
                </div>
                <div className="intro-y col-span-12 flex items-Centre justify-Centre sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddOrEditCentre;
