import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { handleCatchResponse } from "../helper/helper";
import { ActivateDeactivatePositionInterface, PositionInterface, ResponseInterface } from "../helper/interface";
import { setLoading } from "../store/slice/BaseSlice";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { FormikState } from "formik";
import { NavigateFunction } from "react-router-dom";

export const onAddPosition = async (
    payload: PositionInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<PositionInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.admin.addPosition, payload);

        const res: Required<ResponseInterface<PositionInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/position/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/position/edit/${res.data.positionId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);
        navigate("/position");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        resetForm();
        dispatch(setLoading(false));
    }
};

export const onEditPosition = async (data: Required<PositionInterface>, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            name: data.name,
        };

        const response = await dataService.post(`${api.admin.editPosition}/${data.positionId}`, payload);

        const res: Required<ResponseInterface<PositionInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/position/edit/${res.data.positionId ?? data.positionId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);
        navigate("/position");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeletePosition = async (positionId: number, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deletePosition}/${positionId}`);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onActivateDeactivatePosition = async (data: ActivateDeactivatePositionInterface, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.activateDeactivatePosition}/${data.positionId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};
