import "../assets/css/loading.css";
import { LoadingInterface } from "../helper/interface";

const Loading = ({ forTable = true }: LoadingInterface) => {
    return (
        <div className={`loading-container ${forTable && "min-height-300"}`}>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
