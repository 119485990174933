import { Dispatch } from "@reduxjs/toolkit";
import { ActivateDeactivateBreakInterface, BreakInterface, ResponseInterface } from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { FormikState } from "formik";
import { setLoading } from "../store/slice/BaseSlice";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";

export const onAddBreak = async (
    payload: BreakInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<BreakInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.admin.addBreak, payload);

        const res: Required<ResponseInterface<BreakInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/break/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/break/edit/${res.data.breakId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/break");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        resetForm();
        dispatch(setLoading(false));
    }
};

export const onEditBreak = async (data: BreakInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            breakTime: data.breakTime,
            time: data.time,
            isActive: data.isActive,
        };

        const response = await dataService.post(`${api.admin.editBreak}/${data.breakId}`, payload);

        const res: Required<ResponseInterface<BreakInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/break/edit/${res.data.breakId ?? data.breakId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);
        navigate("/break");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteBreak = async (breakId: number, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteBreak}/${breakId}`);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onActivateDeactivateBreak = async (data: ActivateDeactivateBreakInterface, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.activateDeactivateBreak}/${data.breakId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};
