import { Formik } from "formik";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { editJobTimeValidation, isDateInCurrentWeek } from "../../helper/constant";
import { JobHoursInterface, ResponseInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import EditRelieverJobTime from "./EditRelieverJobForm";
import moment from "moment";
import { setLoading } from "../../store/slice/BaseSlice";
import { dataService } from "../../config/DataService";
import { editSuccessToast } from "../../helper/toast";
import { handleCatchResponse } from "../../helper/helper";
import { AxiosError } from "axios";

const EditRelieverJobHour: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id = "" } = useParams();

    const { res, loading, error } = useFetch<JobHoursInterface>(`${api.admin.getRelieverJobHoursById}/${id}`);

    const onSubmit = async (values: JobHoursInterface) => {
        try {
            dispatch(setLoading(true));

            const selectedDate = moment.utc(values.date).format("YYYY-MM-DD");

            const startDateTime = moment(selectedDate + values.clockIn, "YYYY-MM-DD HH:mm:s").toISOString();
            const endDateTime = moment(selectedDate + values.clockOut, "YYYY-MM-DD HH:mm:s").toISOString();

            const payload = {
                clockIn: startDateTime,
                clockOut: endDateTime,
                breakTime: values.breakTime,
            };

            const response = await dataService.put(`${api.admin.updateRelieverJobHour}/${id}`, payload);

            const res: Required<ResponseInterface<JobHoursInterface>> = response.data;

            const toastLink = {
                primaryLinkTitle: "Edit",
                primaryLink: `/timesheet/edit/${id}`,
            };

            editSuccessToast(res.message, navigate, toastLink);

            navigate("/timesheet");
        } catch (error) {
            handleCatchResponse(error as AxiosError);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const oldVal = res?.data;

    useEffect(() => {

        const isDateInCurrentWeekOrNot = oldVal?.createdAt && isDateInCurrentWeek(oldVal?.createdAt);

        const date = moment.utc(oldVal?.createdAt).format("YYYY-MM-DD");

        const sTime = moment.utc(oldVal?.job?.startDate).format("HH:mm:ss");
        const eTime = moment.utc(oldVal?.job?.endDate).format("HH:mm:ss");

        const sCombinedDateTime = moment.utc(date + " " + sTime, "YYYY-MM-DD HH:mm:ss").toISOString();
        const eCombinedDateTime = moment.utc(date + " " + eTime, "YYYY-MM-DD HH:mm:ss").toISOString();

        let jobSDate = "";
        let jobEDate = "";

        if(!isDateInCurrentWeekOrNot && !oldVal?.clockIn && !oldVal?.clockOut) {
            jobSDate = sCombinedDateTime;
            jobEDate = eCombinedDateTime;
        }

        const startTimeValue = new Date(res?.data?.clockIn ?? jobSDate);
        if (oldVal) oldVal.date = new Date(res?.data?.createdAt ?? "");

        const startTime = startTimeValue.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

        const endTimeValue = new Date(res?.data?.clockOut ?? jobEDate);

        const endTime = endTimeValue.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

        if (oldVal) {
            oldVal.clockIn = startTime;
            oldVal.clockOut = endTime;
        }
    }, [res]);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/timesheet")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit Reliever Job Hours</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={editJobTimeValidation} onSubmit={onSubmit} enableReinitialize>
                        {(props) => <EditRelieverJobTime {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditRelieverJobHour;
