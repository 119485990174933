import React, { useState, useEffect } from "react";
import usePathName from "../../hooks/usePathName";
import { Listbox, Transition } from "@headlessui/react";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { DistrictInterface, RegionInterface, SuburbInterface } from "../../helper/interface";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const Location = () => {
    const [region, setRegion] = useState<RegionInterface | null>(null);
    const [district, setDistrict] = useState<DistrictInterface | null>(null);
    const [suburb, setSuburb] = useState<SuburbInterface | null>(null);

    const navigate = useNavigate();

    usePathName([
        { pathName: "Centre", href: "/centre" },
        { pathName: "Location", href: "/centre/location" },
    ]);

    const regions = useFetch<RegionInterface[]>(api.admin.getAllRegion);
    const districts = useFetch<DistrictInterface[]>(`${api.admin.getAllDistrict}/${region?.regionId}`);
    const suburbs = useFetch<SuburbInterface[]>(`${api.admin.getAllSuburb}/${region?.regionId}`);

    useEffect(() => {
        setDistrict(null);
        if (region) {
            districts.fetchApi(`${api.admin.getAllDistrict}/${region?.regionId}`);
        }
    }, [region]);

    useEffect(() => {
        setSuburb(null);
        if (district) {
            suburbs.fetchApi(`${api.admin.getAllSuburb}/${district?.districtId}`);
        }
    }, [district]);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <ArrowLeftCircle className="cursor-pointer text-primary ml-4" onClick={() => navigate("/centre")} />
                <div className="flex flex-col m-auto w-full">
                    <div className="font-medium text-center text-lg">View all available location </div>
                    <div className="text-slate-500 text-center mt-2">All regions, districts and suburbs in your country</div>
                </div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">Region</label>
                        <div className="tom-select">
                            <Listbox value={region} onChange={setRegion}>
                                <Listbox.Button className="items ts-input full has-items">
                                    <div className="item">{region?.regionName ?? "Select a region"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {regions.res?.data ? (
                                                <>
                                                    {regions.res?.data.map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || region === data ? "optionColor" : null
                                                                }`
                                                            }
                                                            key={data.regionId}
                                                            value={data}
                                                        >
                                                            {data.regionName}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">District</label>
                        <div className="tom-select">
                            <Listbox value={district} onChange={setDistrict}>
                                <Listbox.Button className={`items ts-input full has-items ${!region && "disabled"}`}>
                                    <div className="item">{district?.districtName ?? "Select a district"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {districts.res?.data ? (
                                                <>
                                                    {districts.res?.data.map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || district === data ? "optionColor" : null
                                                                }`
                                                            }
                                                            key={data.regionId}
                                                            value={data}
                                                        >
                                                            {data.districtName}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">Suburb</label>
                        <div className="tom-select">
                            <Listbox value={suburb} onChange={setSuburb}>
                                <Listbox.Button className={`items ts-input full has-items ${!district && "disabled"}`}>
                                    <div className="item">{suburb?.suburbName ?? "Select a suburb"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {suburbs.res?.data ? (
                                                <>
                                                    {suburbs.res?.data.map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || suburb === data ? "optionColor" : null
                                                                }`
                                                            }
                                                            key={data.suburbId}
                                                            value={data}
                                                        >
                                                            {data.suburbName}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
