import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { addOrEditBreakValidation } from "../../helper/constant";
import usePathName from "../../hooks/usePathName";
import { useNavigate } from "react-router-dom";
import { BreakInterface } from "../../helper/interface";
import AddEditBreak from "./AddEditBreak";
import { onAddBreak } from "../../service/break";
import { ArrowLeftCircle } from "react-feather";

const AddBreak: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "Break", href: "/break" },
        { pathName: "Add", href: "/break/add " },
    ]);

    const onSubmit = (values: BreakInterface, { resetForm }: FormikHelpers<BreakInterface>) => {
        onAddBreak(values, dispatch, navigate, resetForm);
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/break")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new break</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik
                    initialValues={{
                        breakTime: "",
                        time: 0,
                        isActive: false,
                    }}
                    validationSchema={addOrEditBreakValidation}
                    onSubmit={onSubmit}
                >
                    {(props) => <AddEditBreak {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddBreak;
