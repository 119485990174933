import { Dispatch } from "@reduxjs/toolkit";
import { ActivateDeactivateAgeGroup, AgeGroupInterface, ResponseInterface } from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { FormikState } from "formik";
import { setLoading } from "../store/slice/BaseSlice";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";

export const onAddAgeGroup = async (
    payload: AgeGroupInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<AgeGroupInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.admin.addAgeGroup, payload);

        const res: Required<ResponseInterface<AgeGroupInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/age-group/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/age-group/edit/${res.data.ageGroupId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/age-group");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        resetForm();
        dispatch(setLoading(false));
    }
};

export const onEditAgeGroup = async (data: AgeGroupInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            name: data.name,
        };

        const response = await dataService.post(`${api.admin.editAgeGroup}/${data.ageGroupId}`, payload);

        const res: Required<ResponseInterface<AgeGroupInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/age-group/edit/${res.data.ageGroupId ?? data.ageGroupId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);

        navigate("/age-group");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteAgeGroup = async (ageGroupId: number, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteAgeGroup}/${ageGroupId}`);
        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onActivateDeactivateAgeGroup = async (data: ActivateDeactivateAgeGroup, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.activateDeactivateAgeGroup}/${data.ageGroupId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};
