import usePathName from "../hooks/usePathName";
import { AvailabilityFilterInterface, AvailabilityInterface, AvailableRelieverTableInterface, status } from "../helper/interface";
import AvailabilityForm from "./AvailabilityForm";
import { Formik } from "formik";
import { availabilityValidation } from "../helper/constant";
import useTable from "../hooks/useTable";
import { api } from "../config/Api";
import moment from "moment";
import CustomTable from "../components/table/CustomTable";

const initialValue: AvailabilityInterface = {
    date: moment().format("YYYY-MM-DD"),
    position: {
        positionId: 1,
        name: "Qualified",
    },
    status: status.available,
};

const Availability = () => {
    usePathName([{ pathName: "Availability", href: "/availability" }]);

    const { date, status, position } = initialValue;

    const availableRelieverTable = useTable<AvailableRelieverTableInterface>("availableReliever", `${api.admin.getRelieverList}`, false, {
        date,
        status,
        positionId: position?.positionId ?? "",
    });

    const tableData = availableRelieverTable?.tableData;

    const onSubmit = (values: AvailabilityInterface) => {
        const { date, status, position } = values;
        const positionId = position.positionId;
        const filterValues = { date, status, positionId: positionId ?? "" };
        availableRelieverTable.setLParams(filterValues);
        availableRelieverTable.onSearch<AvailabilityFilterInterface>("", filterValues);
    };

    const columns = [
        {
            title: "#",
            key: "relieverId",
            render: (record: AvailableRelieverTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "name",
            render: (record: AvailableRelieverTableInterface) => {
                return <span>{record.name}</span>;
            },
        },
    ];

    return (
        <>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={columns}
                    data={tableData ?? []}
                    tableKey="relieverId"
                    currentOrder={availableRelieverTable.currentOrder}
                    reload={availableRelieverTable.fetchApi}
                    onSetOrderBy={availableRelieverTable.onSetOrderBy}
                    loading={availableRelieverTable.loading}
                    error={availableRelieverTable.error}
                    component={
                        <>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0 w-full items-baseline">
                                <Formik
                                    initialValues={initialValue}
                                    enableReinitialize
                                    validationSchema={availabilityValidation}
                                    onSubmit={onSubmit}
                                    onReset={onSubmit}
                                >
                                    {(props) => <AvailabilityForm {...props} />}
                                </Formik>
                            </div>
                        </>
                    }
                    tableOffset={availableRelieverTable.tableOffset}
                />
            </div>
        </>
    );
};

export default Availability;
