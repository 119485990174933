import { Form } from "formik";
import { useSelector } from "react-redux";
import { FormInterface, PositionInterface, StateInterface } from "../../helper/interface";

const AddOrEditPosition = ({ errors, touched, handleChange, values, handleBlur, dirty }: FormInterface<PositionInterface>) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="name" className="form-label">
                        Position
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        autoFocus
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        className={`form-control ${errors.name && touched.name && "border-danger"}`}
                        placeholder="Qualified"
                    />
                    {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddOrEditPosition;
