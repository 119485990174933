import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { addOrEditPositionValidation } from "../../helper/constant";
import { PositionInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import { onEditPosition } from "../../service/position";
import AddOrEditPosition from "./AddEditPosition";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";

const EditPosition: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { positionId = "" } = useParams();

    const { res, loading, error } = useFetch<PositionInterface>(`${api.admin.getPosition}/${positionId}`);

    usePathName([
        { pathName: "Position", href: "/position" },
        { pathName: "Edit", href: `/position/edit/${positionId}` },
        { pathName: `${positionId}`, href: `/position/edit/${positionId}` },
    ]);

    const onSubmitPosition = (values: PositionInterface) => {
        const payload = {
            name: values.name,
            positionId: +positionId,
        };
        onEditPosition(payload, navigate, dispatch);
    };

    const oldVal = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/position")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit position</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addOrEditPositionValidation} onSubmit={onSubmitPosition}>
                        {(props) => <AddOrEditPosition {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditPosition;
