const Host = process.env.REACT_APP_BACKEND_API;

const api = {
    admin: {
        login: `${Host}/admin/login`,
        changePassword: `${Host}/admin/changePassword`,
        addPosition: `${Host}/admin/addPosition`,
        editPosition: `${Host}/admin/updatePosition`,
        reliever: `${Host}/admin/reliever`,
        relieverUnAvailability: `${Host}/admin/relieverUnAvailability`,
        editReliever: `${Host}/admin/updateReliever`,
        addReliever: `${Host}/admin/addReliever`,
        deletePosition: `${Host}/admin/deletePosition`,
        getAllPosition: `${Host}/admin/position`,
        getPosition: `${Host}/admin/position`,
        getAllPositionForSelect: `${Host}/admin/position/getAll`,
        activateDeactivatePosition: `${Host}/admin/position`,
        addAgeGroup: `${Host}/admin/addAgeGroup`,
        editAgeGroup: `${Host}/admin/updateAgeGroup`,
        deleteAgeGroup: `${Host}/admin/deleteAgeGroup`,
        getAgeGroup: `${Host}/admin/ageGroup`,
        activateDeactivateAgeGroup: `${Host}/admin/ageGroup`,
        addBreak: `${Host}/admin/addBreak`,
        editBreak: `${Host}/admin/updateBreak`,
        deleteBreak: `${Host}/admin/deleteBreak`,
        getBreak: `${Host}/admin/breaks`,
        activateDeactivateBreak: `${Host}/admin/break`,
        getAllRegion: `${Host}/admin/region`,
        getAllDistrict: `${Host}/admin/district`,
        getAllSuburb: `${Host}/admin/suburb`,
        addScheduler: `${Host}/admin/addScheduler`,
        activateDeactivateScheduler: `${Host}/admin/scheduler`,
        approveDeclineScheduler: `${Host}/admin/scheduler/approveDecline`,
        approveDeclineReliever: `${Host}/admin/reliever/approveDecline`,
        editScheduler: `${Host}/admin/updateScheduler`,
        getScheduler: `${Host}/admin/schedulers`,
        deleteScheduler: `${Host}/admin/scheduler`,
        schedulersForCentre: `${Host}/admin/allSchedulers`,
        getCentre: `${Host}/admin/centre`,
        editCentre: `${Host}/admin/centre`,
        addCentre: `${Host}/admin/addCentre`,
        deleteCentre: `${Host}/admin/centre`,
        activateDeactivateCentre: `${Host}/admin/centre`,
        schedulerBulkUpload: `${Host}/admin/uploadSchedulers`,
        relieverBulkUpload: `${Host}/admin/uploadRelievers`,
        getSampleXlsxScheduler: `${Host}/admin/sampleCsv/scheduler`,
        getSampleXlsxReliever: `${Host}/admin/sampleCsv/reliever`,
        getCentreByScheduler: `${Host}/admin/centreByScheduler`,
        getCommonDataForJob: `${Host}/admin/commonDataForJobCreate`,
        addJob: `${Host}/admin/createJob`,
        getJobs: `${Host}/admin/jobs`,
        job: `${Host}/admin/job`,
        globalSettings: `${Host}/admin/globalSettings`,
        relieverDocuments: `${Host}/admin/relieverDocuments`,
        report: `${Host}/admin/report`,
        getRelieverList: `${Host}/admin/getRelieverList`,
        getFeedbackList: `${Host}/admin/feedback`,
        relieverForJobCreate: `${Host}/admin/relieverForJobCreate`,
        specificUserNotification: `${Host}/admin/notifyUser`,
        getRelieverJobHours: `${Host}/admin/getRelieverHoursList`,
        getRelieverJobHoursById: `${Host}/admin/relieverHours`,
        updateRelieverJobHour: `${Host}/admin/relieverHours`,
        getInvoice: `${Host}/admin/getInvoiceList`,
        getInvoiceById: `${Host}/admin/invoice`,
        addInvoice: `${Host}/admin/createInvoice`,
        updateInvoice: `${Host}/admin/updateInvoice`,
        deleteInvoice: `${Host}/admin/deleteInvoice`,
        sendNotification: `${Host}/admin/sendNotificationToUser`,
        updateDocument: `${Host}/admin/relieverDocuments`,
        deleteDocument: `${Host}/admin/relieverDocuments`,
        dashboard: `${Host}/admin/dashboard`,
        deleteTimeSheet: `${Host}/admin/timeSheet`,
    },
};

export { api };
