import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { addOrEditBreakValidation } from "../../helper/constant";
import { BreakInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import AddEditBreak from "./AddEditBreak";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { onEditBreak } from "../../service/break";

const EditBreak: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { breakId = "" } = useParams();

    const { res, loading, error } = useFetch<BreakInterface>(`${api.admin.getBreak}/${breakId}`);

    usePathName([
        { pathName: "Break", href: "/break" },
        { pathName: "Edit", href: `/break/edit/${breakId}` },
    ]);

    const onSubmitBreak = (values: BreakInterface) => {
        const payload = {
            breakTime: values.breakTime,
            time: values.time,
            breakId: +breakId,
        };
        onEditBreak(payload, navigate, dispatch);
    };

    const oldVal = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/break")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit break</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addOrEditBreakValidation} onSubmit={onSubmitBreak}>
                        {(props) => <AddEditBreak {...props} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditBreak;
