import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { addOrEditAgeGroupValidation } from "../../helper/constant";
import usePathName from "../../hooks/usePathName";
import { useNavigate } from "react-router-dom";
import { AgeGroupInterface } from "../../helper/interface";
import { onAddAgeGroup } from "../../service/ageGroup";
import AddEditAgeGroup from "./AddEditAgeGroup";
import { ArrowLeftCircle } from "react-feather";

const AddAgeGroup: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "Age group", href: "/age-group" },
        { pathName: "Add", href: "/age-group/add " },
    ]);

    const onSubmit = (values: AgeGroupInterface, { resetForm }: FormikHelpers<AgeGroupInterface>) => {
        onAddAgeGroup(values, dispatch, navigate, resetForm);
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/age-group")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new age group</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik
                    initialValues={{
                        name: "",
                    }}
                    validationSchema={addOrEditAgeGroupValidation}
                    onSubmit={onSubmit}
                >
                    {(props) => <AddEditAgeGroup {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddAgeGroup;
