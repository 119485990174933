import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../config/Api";
import Loading from "../../../container/Loading";
import { DocumentType, addEditRelieverValidation } from "../../../helper/constant";
import { AddEditRelieverInterface } from "../../../helper/interface";
import useFetch from "../../../hooks/useFetch";
import usePathName from "../../../hooks/usePathName";
import AddEditReliever from "./AddEditReliever";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { onEditReliever } from "../../../service/user";

const EditReliever: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { relieverId = "" } = useParams();

    const { res, loading, error } = useFetch<AddEditRelieverInterface>(`${api.admin.reliever}/${relieverId}`);

    usePathName([
        { pathName: "User", href: "/user/reliever" },
        { pathName: "Reliever", href: "/user/reliever" },
        { pathName: "Edit", href: `/user/reliever/edit/${relieverId}` },
    ]);

    const onSubmitBreak = (values: AddEditRelieverInterface) => {
        const { ...newValues } = values;

        const payload = {
            ...newValues,
            relieverId: +relieverId,
        };

        onEditReliever(payload, navigate, dispatch);
    };

    function addDashesToNumber(numberString: string): string {
        // Define the positions where dashes need to be inserted
        const dashPositions = [1, 5, 12];
        let formattedString = "";

        // Iterate through the number string
        for (let i = 0; i < numberString.length; i++) {
            // Add the current character to the formatted string
            formattedString += numberString[i];
            // Check if the current position is one of the dash positions
            if (dashPositions.includes(i)) {
                // If so, add a dash
                formattedString += "-";
            }
        }
        return formattedString;
    }

    const oldVal: AddEditRelieverInterface = {
        ...res?.data,
        bank: res?.data?.bankDetails?.bank,
        branch: res?.data?.bankDetails?.branch,
        account: res?.data?.bankDetails?.account ? addDashesToNumber(res?.data?.bankDetails?.account) : "",
        suffix: res?.data?.bankDetails?.suffix,
        bankAccountName: res?.data?.bankDetails?.bankAccountName,
        IRDFile: res?.data?.bankDetails?.IRDFile,
        primaryProofType: res?.data?.primaryProofType,
        secondaryProofType: res?.data?.secondaryProofType,
        IRDNumber: res?.data?.bankDetails?.IRDNumber,
        expiryOfPrimaryProofType:
            res?.data?.documents?.find((item) => item.documentType === DocumentType.PRIMARY_PROOF_FILE)?.expiryDate ?? null,
        expiryOfSecondaryProofType:
            res?.data?.documents?.find((item) => item.documentType === DocumentType.SECONDARY_PROOF_FILE)?.expiryDate ?? null,
        secondaryProofFile: res?.data?.documents?.find((item) => item.documentType === DocumentType.SECONDARY_PROOF_FILE)?.url ?? null,
        primaryProofFile: res?.data?.documents?.find((item) => item.documentType === DocumentType.PRIMARY_PROOF_FILE)?.url ?? null,
        addressLine: res?.data?.addressLine ?? "",
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/user/reliever")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit reliever</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addEditRelieverValidation} onSubmit={onSubmitBreak}>
                        {(props) => <AddEditReliever {...props} isEdit={true} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditReliever;
