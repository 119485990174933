import { Listbox, Transition } from "@headlessui/react";
import { Form } from "formik";
import { Filter_Type, User_Type, userTypeValue } from "../../helper/constant";
import { FilterForDashboard, RelieverForSelect, SchedulerForSelect, ZIndexMaintainer } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import Select, { SingleValue } from "react-select";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { onDatePickerClose, onDatePickerOpen } from "../../helper/helper";
import { useState } from "react";

const Filter = ({
    values,
    setFieldValue,
    setFieldTouched,
    resetForm,
    fetchData,
    errors,
    touched,
    handleBlur,
    dirty,
}: FilterForDashboard) => {
    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const schedulersData = useFetch<SchedulerForSelect[]>(api.admin.schedulersForCentre);

    const relieverData = useFetch<RelieverForSelect[]>(api.admin.reliever);

    const handleSchedulerChange = (selectedItem: SingleValue<SchedulerForSelect>) => {
        setFieldValue("schedulerId", selectedItem);
    };
    const handleRelieverChange = (selectedItem: SingleValue<RelieverForSelect>) => {
        setFieldValue("relieverId", selectedItem);
    };

    const handleFilterTypeChange = (value: string) => {
        setFieldValue("filterType", value);
    };

    const handleReset = () => {
        resetForm();
        fetchData({ userType: User_Type.ALL });
    };

    return (
        <Form id="tabulator-html-filter-form" className="flex items-center job-pending-style align-items-baseline ">
            <div className="flex items-start flex-wrap">
                <div className="flex flex-col  sm:mr-4 py-1 job-filter-form ">
                    <div className="flex items-center sm:mr-4" style={{ zIndex: zIndex.userType }}>
                        <label className="form-label">User Type</label>
                        <div>
                            {Object.values(User_Type) ? (
                                <Select
                                    name="userType"
                                    options={userTypeValue}
                                    value={userTypeValue.find((ele) => ele.value === values.userType)}
                                    onChange={(selectedItem) => selectedItem && setFieldValue("userType", selectedItem.value)}
                                    classNamePrefix="select"
                                    className={"form-control job-filter-style py-1"}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    onMenuOpen={onDatePickerOpen(setZIndex, "userType")}
                                    onMenuClose={onDatePickerClose(setZIndex, "userType")}
                                />
                            ) : null}
                        </div>
                    </div>

                    {User_Type.SCHEDULER === values.userType ? (
                        <div className="flex items-center sm:mr-4 mt-3 z-50" style={{ zIndex: zIndex.schedulerMenu }}>
                            <label className="form-label">Schedulers</label>
                            <div>
                                {schedulersData?.res?.data ? (
                                    <Select
                                        name="schedulerId"
                                        options={schedulersData?.res?.data}
                                        value={values?.schedulerId}
                                        onChange={(selectedItem) => handleSchedulerChange(selectedItem)}
                                        classNamePrefix="select"
                                        className={"form-control job-filter-style py-1"}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.schedulerId}
                                        onMenuOpen={onDatePickerOpen(setZIndex, "schedulerMenu")}
                                        onMenuClose={onDatePickerClose(setZIndex, "schedulerMenu")}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {User_Type.RELIEVER === values.userType ? (
                        <div className="sm:flex flex items-center sm:mr-4  mt-3 z-50" style={{ zIndex: zIndex.relieverMenu }}>
                            <label className="form-label">Reliever</label>
                            <div>
                                {relieverData?.res?.data ? (
                                    <Select
                                        name="relieverId"
                                        options={relieverData?.res?.data}
                                        value={values?.relieverId}
                                        onChange={(selectedItem) => handleRelieverChange(selectedItem)}
                                        className={"form-control job-filter-style py-1"}
                                        classNamePrefix="select"
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.relieverId}
                                        onMenuOpen={onDatePickerOpen(setZIndex, "relieverMenu")}
                                        onMenuClose={onDatePickerClose(setZIndex, "relieverMenu")}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="flex flex-col sm:mr-4 py-1 job-filter-form ">
                    <div className="flex items-center z-[51]">
                        <label className="flex-none mr-2 mb-0">Filter Type</label>
                        <div className="tom-select">
                            <Listbox value={values.filterType} onChange={handleFilterTypeChange}>
                                <Listbox.Button className="form-control ts-input">
                                    <div className="item">{values?.filterType ?? "Select filter type"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    onBlur={() => setFieldTouched("filterType", true)}
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {Filter_Type ? (
                                                <>
                                                    {Object.values(Filter_Type).map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || values.filterType === data ? "optionColor" : null
                                                                }`
                                                            }
                                                            key={data}
                                                            value={data}
                                                        >
                                                            {data || "Select filter type"}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>

                    {values.filterType === Filter_Type.CUSTOM ? (
                        <div className="flex flex-col mt-3 z-50" style={{ zIndex: zIndex.from || zIndex.to }}>
                            <div>
                                <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                                    <label htmlFor="from">From</label>
                                    <CustomDatePicker
                                        id="from"
                                        name="from"
                                        maxDate={new Date()}
                                        selected={values.from ? new Date(values.from) : null}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}
                                        onCalendarOpen={onDatePickerOpen(setZIndex, "from")}
                                        onCalendarClose={onDatePickerClose(setZIndex, "from")}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center sm:mr-4 py-1 job-filter-form">
                                    <label htmlFor="to" className="form-label">
                                        To
                                    </label>
                                    <CustomDatePicker
                                        id="to"
                                        name="to"
                                        maxDate={new Date()}
                                        selected={values.to ? new Date(values.to) : null}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}
                                        hasError={Boolean(errors.to && touched.to)}
                                        onCalendarOpen={onDatePickerOpen(setZIndex, "to")}
                                        onCalendarClose={onDatePickerClose(setZIndex, "to")}
                                    />
                                </div>
                                {errors.to && <div className="text-danger">{errors.to}</div>}
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="flex mt-1 md:mt-0">
                    <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16">
                        Go
                    </button>
                    <button
                        id="tabulator-html-filter-reset"
                        type="button"
                        className="btn btn-secondary w-16 ml-2"
                        onClick={handleReset}
                        disabled={!dirty}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default Filter;
