import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { addOrEditSchedulerValidation } from "../../../helper/constant";
import usePathName from "../../../hooks/usePathName";
import AddEditScheduler from "./AddEditScheduler";
import { SchedulerInterface } from "../../../helper/interface";
import { onAddScheduler } from "../../../service/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowLeftCircle } from "react-feather";

const AddScheduler: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([
        { pathName: "User", href: "/user/scheduler" },
        { pathName: "Scheduler", href: "/user/scheduler" },
        { pathName: "Add", href: "/user/scheduler/add" },
    ]);

    const onSubmit = (values: SchedulerInterface, { resetForm }: FormikHelpers<SchedulerInterface>) => {
        onAddScheduler(values, dispatch, navigate, resetForm);
    };

    return (
        <>
            <div className="intro-y box py-10 sm:py-10 mt-5">
                <div className="flex w-full items-center">
                    <div className="flex-2 ml-4">
                        <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/user/scheduler")} />
                    </div>
                    <div className="flex-1 font-medium text-center text-lg -ml-2">Add new scheduler</div>
                </div>
                <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                    <Formik
                        initialValues={{
                            email: "",
                            name: "",
                            mobileNumber: "",
                            centre: null,
                            dob: null,
                        }}
                        validationSchema={addOrEditSchedulerValidation}
                        onSubmit={onSubmit}
                    >
                        {(props) => <AddEditScheduler {...props} />}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddScheduler;
