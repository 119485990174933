import { useState, useCallback } from "react";
import { Edit, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { api } from "../../config/Api";
import { ActivateDeactivateBreakInterface, BreakInterface, BreakTableInterface } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { useDispatch } from "react-redux";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../modal/DeleteModal";
import { onActivateDeactivateBreak, onDeleteBreak } from "../../service/break";
import { Tooltip } from "react-tooltip";

const Break = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [breakObj, setBreakObj] = useState<BreakInterface | null>(null);
    const dispatch = useDispatch();
    usePathName([{ pathName: "Break", href: "/break" }]);

    const breakTable = useTable<BreakTableInterface>("break", api.admin.getBreak);

    const onDeleteClick = useCallback((record: BreakInterface) => {
        setBreakObj(record);
        setIsOpen(true);
    }, []);

    const ageGroupCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: BreakTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "BREAK TIME",
            key: "breakTime",
            sortable: true,
            render: (record: BreakTableInterface) => {
                return <span>{record.breakTime}</span>;
            },
        },
        {
            title: "TIME",
            key: "time",
            sortable: true,
            render: (record: BreakTableInterface) => {
                return <span>{record.time}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: BreakTableInterface) => {
                const payload = {
                    breakId: record.breakId,
                    isActive: !record.isActive,
                };
                return (
                    <div className="form-switch form-check flex justify-center flex-col">
                        <input
                            id="isActive"
                            name="isActive"
                            onChange={() => onActivateDeactivate(payload)}
                            checked={record.isActive}
                            className={"form-check-input"}
                            type="checkbox"
                        />
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: BreakTableInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDeleteBreak" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteBreak"
                                data-tooltip-content={"Edit break"}
                                onClick={() => navigate(`/break/edit/${record.breakId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                Edit
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteBreak"
                                data-tooltip-content={"Delete break"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onDelete = () => {
        if (breakObj?.breakId) {
            onDeleteBreak(breakObj?.breakId, dispatch, breakTable.fetchApi);
            setIsOpen(false);
        }
    };

    const onActivateDeactivate = (payload: ActivateDeactivateBreakInterface) => {
        onActivateDeactivateBreak(payload, dispatch, breakTable.fetchApi);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this break?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={ageGroupCols}
                    data={breakTable.tableData ?? []}
                    tableKey="breakId"
                    reload={breakTable.fetchApi}
                    currentOrder={breakTable.currentOrder}
                    error={breakTable.error}
                    loading={breakTable.loading}
                    tableOffset={breakTable.tableOffset}
                    onSetOrderBy={breakTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={breakTable.resetSearch}
                                onSearch={breakTable.onSearch}
                                searchPlaceHolder="Search by break time"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltipBreak" place="top" />
                                <button
                                    className="btn btn-outline-primary w-full md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipBreak"
                                    data-tooltip-content="Add new break"
                                    onClick={() => {
                                        navigate("/break/add");
                                    }}
                                >
                                    Add new
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Break;
