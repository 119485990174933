import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PathInterface } from "../helper/interface";
import { setPagePath } from "../store/slice/BaseSlice";

const usePathName = (props: PathInterface[]) => {
    const dispatch = useDispatch();

    const onSetPage = useCallback(() => {
        dispatch(setPagePath(props));
    }, [props]);

    useEffect(() => {
        onSetPage();
    }, []);

    return { onSetPage };
};

export default usePathName;
