import React from "react";
import usePathName from "../../hooks/usePathName";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddEditJob from "./AddEditJob";
import { Formik, FormikHelpers } from "formik";
import { addOrEditJobValidation } from "../../helper/constant";
import { AddEditJobInterface, StateInterface } from "../../helper/interface";
import { onAddJob } from "../../service/job";
import { ArrowLeftCircle } from "react-feather";
import { JobPayloadFn } from "./Utils";

const initialValues: AddEditJobInterface = {
    note: "",
    centreForJob: null,
    scheduler: null,
    ageGroupForJob: null,
    breakForJob: null,
    positionForJob: null,
    startTime: "09:00",
    endTime: "17:30",
    startDate: null,
    endDate: null,
    noOfRelievers: 1,
    repeat: null,
    daysOfWeek: undefined,
    relieverSelection: false,
    notifyRelievers: true,
    reliever: undefined,
    sendSpecific: false,
    inDistrict: false,
    inFavorite: false,
};

const AddJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    usePathName([
        { pathName: "Job", href: `/job/${tableData.tabFor}` },
        { pathName: "Add", href: "/job/add" },
    ]);

    const onSubmit = (values: AddEditJobInterface, { resetForm }: FormikHelpers<AddEditJobInterface>) => {
        const payload = JobPayloadFn(values);
        onAddJob(payload, dispatch, navigate, resetForm, tableData.tabFor);
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate(`/job/${tableData.tabFor}`)} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new job</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik initialValues={initialValues} validationSchema={addOrEditJobValidation} onSubmit={onSubmit}>
                    {(props) => <AddEditJob {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddJob;
