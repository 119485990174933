import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { addOrEditPositionValidation } from "../../helper/constant";
import usePathName from "../../hooks/usePathName";
import { onAddPosition } from "../../service/position";
import AddOrEditPosition from "./AddEditPosition";
import { useNavigate } from "react-router-dom";
import { PositionInterface } from "../../helper/interface";
import { ArrowLeftCircle } from "react-feather";

const AddPotion: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    usePathName([
        { pathName: "Position", href: "/position" },
        { pathName: "Add", href: "/position/add " },
    ]);

    const onSubmitPosition = (values: PositionInterface, { resetForm }: FormikHelpers<PositionInterface>) => {
        onAddPosition(values, dispatch, navigate, resetForm);
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/position")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new position</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik
                    initialValues={{
                        name: "",
                    }}
                    validationSchema={addOrEditPositionValidation}
                    onSubmit={onSubmitPosition}
                >
                    {(props) => <AddOrEditPosition {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddPotion;
