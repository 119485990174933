import { useEffect, FC } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/dashboard/Header";
import Menu from "../components/dashboard/Menu";
import MobileMenu from "../components/dashboard/MobileMenu";

const Dashboard: FC = () => {
    useEffect(() => {
        document.body.className = "main";
        return () => {
            document.body.className = "";
        };
    });

    return (
        <>
            <MobileMenu />
            <Header />
            <div className="wrapper">
                <div className="wrapper-box">
                    <Menu />
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
