import { FC, useEffect } from "react";
import Logo from "../assets/images/ArohaWhiteLogo.png";
import { Formik, Form } from "formik";
import { loginValidation } from "../helper/constant";
import { LoginInterface, StateInterface } from "../helper/interface";
import { onLogin } from "../service/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector((state: StateInterface) => state.base.loading);

    useEffect(() => {
        document.body.className = "login";
        return () => {
            document.body.className = "";
        };
    });

    const onLoginSubmit = (value: LoginInterface) => {
        onLogin(value, dispatch, navigate);
    };

    return (
        <div>
            <div className="container sm:px-10">
                <div className="block xl:grid grid-cols-2 gap-4">
                    <div className="hidden xl:flex flex-col min-h-screen">
                        <div className="my-auto">
                            <img alt="Aroha logo" className="-intro-x w-1/2 -mt-16" src={Logo} />
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                Your favourite relief
                                <br />
                                staffing app
                            </div>
                            <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
                                Aroha Connect is elegant, friendly and simplifies
                                <br />
                                your relief staffing journey
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0 flex-col">
                        <div className="max-[1024px]:!block auth-logo">
                            <div className="flex justify-center items-center my-5">
                                <img alt="Gondaliya parivar" className="-intro-y w-48" src={Logo} />
                            </div>
                        </div>
                        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                            <h2 className="intro-y xl:intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Welcome</h2>
                            <div className="intro-y xl:intro-x mt-8">
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password: "",
                                    }}
                                    validationSchema={loginValidation}
                                    onSubmit={onLoginSubmit}
                                >
                                    {({ errors, touched, handleChange, values, handleBlur }) => (
                                        <>
                                            <Form>
                                                <input
                                                    name="email"
                                                    autoFocus
                                                    type="text"
                                                    className={`intro-y xl:intro-x login__input form-control py-3 px-4 block ${
                                                        errors.email && touched.email && "border-danger"
                                                    }`}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder="Enter email"
                                                    onBlur={handleBlur}
                                                />
                                                {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                                                <input
                                                    name="password"
                                                    type="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className={`intro-y xl:intro-x login__input form-control py-3 px-4 block mt-4 ${
                                                        errors.password && touched.password && "border-danger"
                                                    }`}
                                                    placeholder="Enter password"
                                                    onBlur={handleBlur}
                                                />
                                                {errors.password && touched.password && (
                                                    <div className="text-danger">{errors.password}</div>
                                                )}
                                                <div className="intro-y xl:intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                                        disabled={loading}
                                                    >
                                                        Sign in
                                                    </button>
                                                </div>
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
