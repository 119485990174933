import { AlertTriangle, CheckCircle } from "react-feather";
import { toast } from "react-toastify";
import { ToastLinkInterface } from "./interface";
import { NavigateFunction } from "react-router-dom";

export const successToast = (text: string) => {
    toast(
        <div className="flex">
            <CheckCircle className="text-success"></CheckCircle>
            <div className="ml-4 mr-4">
                <div className="font-medium">{text}</div>
            </div>
        </div>,
        {
            hideProgressBar: true,
            autoClose: 2000,
        }
    );
};

export const addSuccessToast = (text: string, navigate: NavigateFunction, link: Required<ToastLinkInterface>) => {
    toast(
        <>
            <div className="ml-2 mr-2">
                <div className="font-medium">{text}</div>
                <div className="mt-2.5">
                    <div className="btn btn-primary py-1 px-2 mr-2" onClick={() => navigate(link.primaryLink)}>
                        {link.primaryLinkTitle}
                    </div>
                    <div className="btn btn-outline-secondary py-1 px-2" onClick={() => navigate(link.secondaryLink)}>
                        {link.secondaryLinkTitle}
                    </div>
                </div>
            </div>
        </>,
        {
            hideProgressBar: true,
        }
    );
};

export const editSuccessToast = (text: string, navigate: NavigateFunction, link: ToastLinkInterface) => {
    toast(
        <>
            <div className="ml-2 mr-2">
                <div className="font-medium">{text}</div>
                <div className="mt-2.5">
                    <div className="btn btn-primary py-1 px-2 mr-2" onClick={() => navigate(link.primaryLink)}>
                        {link.primaryLinkTitle}
                    </div>
                </div>
            </div>
        </>,
        {
            hideProgressBar: true,
        }
    );
};

export const errorToast = (text: string) => {
    toast(
        <div className="flex">
            <AlertTriangle className="text-danger"></AlertTriangle>
            <div className="ml-2 mr-2">
                <div className="font-medium">{text}</div>
            </div>
        </div>,
        {
            hideProgressBar: true,
        }
    );
};
