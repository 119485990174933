import { Dispatch } from "@reduxjs/toolkit";
import { GlobalSettingsInterface } from "../helper/interface";
import { setLoading } from "../store/slice/BaseSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { successToast } from "../helper/toast";

export const onEditGlobalSetting = async (data: Required<GlobalSettingsInterface>, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(`${api.admin.globalSettings}`, data, { headers: { "Content-Type": "multipart/form-data" } });

        const res = response.data;

        successToast(res.message);

        reload();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
