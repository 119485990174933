import { Form } from "formik";
import { useSelector } from "react-redux";
import { FormInterface, JobHoursInterface, StateInterface } from "../../helper/interface";
import moment from "moment";
import CustomDatePicker from "../datepicker/CustomDatePicker";

const EditRelieverJobTime = ({
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    setFieldValue,
}: FormInterface<JobHoursInterface>) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="clockIn" className="form-label">
                        Clock In Time
                    </label>
                    <input
                        id="clockIn"
                        name="clockIn"
                        type="time"
                        onChange={handleChange}
                        value={`${moment(values?.clockIn ?? "", "HH:mm").format("HH:mm")}`}
                        onBlur={handleBlur}
                        className={`form-control ${errors.clockIn && touched.clockIn && "border-danger"}`}
                    />
                    {errors.clockIn && touched.clockIn && <div className="text-danger">{errors.clockIn}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="clockOut" className="form-label">
                        Clock Out Time
                    </label>
                    <input
                        id="clockOut"
                        name="clockOut"
                        type="time"
                        onChange={handleChange}
                        value={`${moment(values?.clockOut ?? "", "HH:mm").format("HH:mm")}`}
                        onBlur={handleBlur}
                        className={`form-control ${errors.clockOut && touched.clockOut && "border-danger"}`}
                    />
                    {errors.clockOut && touched.clockOut && <div className="text-danger">{errors.clockOut}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                    <label htmlFor="date" className="form-label">
                        Job Date
                    </label>
                    <CustomDatePicker
                        id="date"
                        name="date"
                        selected={values.date ? new Date(moment(values.date).format("yyyy-MM-DD")) : null}
                        hasError={Boolean(errors.date && touched.date)}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        disabled
                    />
                    {errors.date && touched.date && <div className="text-danger">{errors.date}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="breakTime" className="form-label">
                        Break Time
                    </label>
                    <input
                        id="breakTime"
                        name="breakTime"
                        type="number"
                        min={0}
                        step="any"
                        onChange={handleChange}
                        value={values?.breakTime}
                        onBlur={handleBlur}
                        className={`form-control ${errors.breakTime && touched.breakTime && "border-danger"}`}
                        placeholder="breakTime"
                    />
                    {errors.breakTime && touched.breakTime && <div className="text-danger">{errors.breakTime}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading}>
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default EditRelieverJobTime;
