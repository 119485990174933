import { Formik, FormikHelpers, Form } from "formik";
import { changePasswordValidation } from "../../helper/constant";
import { ChangePasswordInterface, StateInterface } from "../../helper/interface";
import { useDispatch, useSelector } from "react-redux";
import { onChangePassword } from "../../service/auth";
import { Eye, EyeOff } from "react-feather";
import { useState } from "react";
import usePathName from "../../hooks/usePathName";

const ChangePassword = () => {
    const dispatch = useDispatch();

    usePathName([
        { pathName: "Home", href: "/dashboard" },
        { pathName: "Change password", href: "/change-password" },
    ]);

    const [show, setShow] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const onSubmit = (values: ChangePasswordInterface, { resetForm }: FormikHelpers<ChangePasswordInterface>) => {
        onChangePassword(values, dispatch, resetForm);
    };

    return (
        <>
            <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
                <div className="intro-y box lg:mt-5">
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                        }}
                        validationSchema={changePasswordValidation}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, handleChange, values, handleBlur, dirty }) => (
                            <Form>
                                <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Change Password</h2>
                                </div>
                                <div className="p-5">
                                    <div>
                                        <label htmlFor="oldPassword" className="form-label">
                                            Current Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="oldPassword"
                                                name="oldPassword"
                                                type={`${show.oldPassword ? "text" : "password"}`}
                                                className={`form-control ${errors.oldPassword && touched.oldPassword && "border-danger"}`}
                                                placeholder="Enter current password"
                                                value={values.oldPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                                <input className="hidden js-password-toggle" id="oldPassToggle" type="checkbox" />
                                                <label
                                                    className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                                                    htmlFor="oldPassToggle"
                                                    onClick={() => setShow({ ...show, oldPassword: !show.oldPassword })}
                                                >
                                                    {show.oldPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                                </label>
                                            </div>
                                        </div>

                                        {errors.oldPassword && touched.oldPassword && (
                                            <div className="text-danger">{errors.oldPassword}</div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="newPassword" className="form-label">
                                            New Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="newPassword"
                                                name="newPassword"
                                                type={`${show.newPassword ? "text" : "password"}`}
                                                placeholder="Enter new password"
                                                className={`form-control  ${errors.newPassword && touched.newPassword && "border-danger"}`}
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                                <input className="hidden js-password-toggle" id="toggle" type="checkbox" />
                                                <label
                                                    className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                                                    htmlFor="toggle"
                                                    onClick={() => setShow({ ...show, newPassword: !show.newPassword })}
                                                >
                                                    {show.newPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                                </label>
                                            </div>
                                        </div>

                                        {errors.newPassword && touched.newPassword && (
                                            <div className="text-danger">{errors.newPassword}</div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="confirmPassword" className="form-label">
                                            Confirm New Password
                                        </label>
                                        <div className="relative w-full">
                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type={`${show.confirmPassword ? "text" : "password"}`}
                                                className={`form-control ${
                                                    errors.confirmPassword && touched.confirmPassword && "border-danger"
                                                }`}
                                                placeholder="Enter new password again"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                                <input className="hidden js-password-toggle" id="oldPassToggle" type="checkbox" />
                                                <label
                                                    className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                                                    htmlFor="oldPassToggle"
                                                    onClick={() => setShow({ ...show, confirmPassword: !show.confirmPassword })}
                                                >
                                                    {show.confirmPassword ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                                                </label>
                                            </div>
                                        </div>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div className="text-danger">{errors.confirmPassword}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                        <button className="btn btn-secondary w-24 mr-2" type="reset" disabled={!dirty}>
                                            Reset
                                        </button>
                                        <button type="submit" className="btn btn-primary" disabled={loading || !dirty}>
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
