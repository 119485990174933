import { useRef, RefObject } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import {
    AddEditRelieverInterface,
    DistrictInterface,
    FormInterface,
    GradeType,
    PositionInterface,
    PrimaryProofTypeEnum,
    RegionInterface,
    SecondaryProofTypeEnum,
    StateInterface,
    SuburbInterface,
} from "../../../helper/interface";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import { api } from "../../../config/Api";
import { Listbox, Transition } from "@headlessui/react";
import UserAvatar from "../../../assets/images/userAvatar.png";
import { Calendar, DateObject } from "react-multi-date-picker";
import { getImageUrl, primaryProofTypeReqDate, secondaryProofTypeReqDate } from "../../../helper/constant";
import { Camera, HelpCircle } from "react-feather";
import CustomDatePicker from "../../datepicker/CustomDatePicker";
import { validateNumber } from "../../../helper/helper";
import { Tooltip } from "react-tooltip";
import { ImageComponent } from "../../imageComponent";

const AddEditReliever = ({
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    setFieldValue,
    dirty,
    setFieldTouched,
    isEdit = false,
}: FormInterface<AddEditRelieverInterface> & { isEdit?: boolean }) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    const profileImgRef = useRef() as RefObject<HTMLInputElement>;

    const deactivatedReliever = Boolean(values?.deletedAt);

    const regions = useFetch<RegionInterface[]>(api.admin.getAllRegion);
    const districts = useFetch<DistrictInterface[]>(`${api.admin.getAllDistrict}/${values.region?.regionId ?? null}`);
    const suburbs = useFetch<SuburbInterface[]>(`${api.admin.getAllSuburb}/${values.district?.districtId ?? null}`);
    const positions = useFetch<PositionInterface[]>(`${api.admin.getAllPositionForSelect}`);

    const onChangeRegion = (value: RegionInterface) => {
        setFieldValue("region", value);
        setFieldValue("district", null);
        setFieldValue("suburb", null);
        districts.fetchApi(`${api.admin.getAllDistrict}/${value.regionId}`);
    };

    const onChangeDistrict = (value: DistrictInterface) => {
        setFieldValue("district", value);
        setFieldValue("suburb", null);
        suburbs.fetchApi(`${api.admin.getAllSuburb}/${value?.districtId}`);
    };

    const onChangeSuburb = (value: SuburbInterface) => {
        setFieldValue("suburb", value);
    };

    const onChangePosition = (value: PositionInterface) => {
        setFieldValue("position", value);

        if (value.positionId === 1) {
            setFieldValue("expiryOfRegistration", null);
            setFieldValue("registrationNo", null);
        }
    };

    const onChangeAidCertificate = () => {
        const valueForUpdate = !values.aidCertificate;
        setFieldValue("aidCertificate", valueForUpdate);

        if (!valueForUpdate) {
            setFieldValue("expiryOfAidCertificate", null);
        }
    };

    const onChangeUnavailability = (selectedDate: DateObject | DateObject[] | null) => {
        const newSelectedDateDate = selectedDate?.toString().split(",");
        setFieldValue("unavailability", newSelectedDateDate);

        if (!selectedDate?.toString()) {
            setFieldValue("unavailability", null);
        }
    };

    const getMinDate = () => {
        const currentDate = moment().format("YYYY-MM-DD");
        const startOfDate = moment(currentDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        return startOfDate;
    };

    const getMaxDate = () => {
        const currentDate = moment().format("YYYY-MM-DD");
        const nextOfDate = moment(currentDate, "YYYY-MM-DD").add(2, "M").endOf("month").format("YYYY-MM-DD");
        return nextOfDate;
    };

    const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;

        const inputNumber = val.replace(/-/g, "").slice(0, 16);

        let value = "";

        for (let i = 0; i < inputNumber.length; i++) {
            if (i === 2 || i === 6 || i === 13) {
                value += "-";
            }
            value += inputNumber[i];
        }

        setFieldValue("account", value);
    };

    return (
        <Form className="addnewrelieverpage">
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="preview mb-5 col-span-12">
                    <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                        <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 avatar-circle image-fit relative">
                            <img
                                alt="Profile picture"
                                className="rounded-full img-bordered"
                                src={values?.profileImg ? getImageUrl(values?.profileImg) : UserAvatar}
                            />
                            <div
                                className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2 svg-bordered-white"
                                onClick={() => profileImgRef?.current?.click()}
                            >
                                <Camera className={`lucide lucide-camera w-4 h-4 text-white ${!deactivatedReliever && "cursor-pointer"}`} />
                                <input
                                    ref={profileImgRef}
                                    name="profileImg"
                                    type="file"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e?.target?.files?.[0]) {
                                            setFieldValue("profileImg", e?.target?.files?.[0]);
                                        }
                                    }}
                                    disabled={loading || deactivatedReliever}
                                    accept="image/*"
                                    className="hidden"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">Professional details</h2>
            </div>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={loading || deactivatedReliever}
                        placeholder="Example"
                        value={values.name}
                        type="text"
                        className={`form-control ${errors.name && touched.name && "border-danger"}`}
                    />
                    {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleChange}
                        value={values.email}
                        disabled={loading || deactivatedReliever}
                        onBlur={handleBlur}
                        className={`form-control ${errors.email && touched.email && "border-danger"}`}
                        placeholder="example@gmail.com"
                    />
                    {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="mobileNumber" className="form-label">
                        Mobile number
                    </label>
                    <div className="input-group">
                        <div className="input-group-text">+64</div>
                        <input
                            id="mobileNumber"
                            name="mobileNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobileNumber}
                            disabled={loading || deactivatedReliever}
                            type="number"
                            className={`form-control ${errors.mobileNumber && touched.mobileNumber && "border-danger"}`}
                            placeholder="89 256 4587"
                        />
                    </div>
                    {errors.mobileNumber && touched.mobileNumber && <div className="text-danger">{errors.mobileNumber}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="wage" className="form-label">
                        Wage
                    </label>
                    <div className="input-group">
                        <input
                            id="wage"
                            name="wage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.wage ?? ""}
                            type="number"
                            step="any"
                            disabled={loading || deactivatedReliever}
                            min={1}
                            className={`form-control ${errors.wage && touched.wage && "border-danger"}`}
                            placeholder="5.6"
                        />
                        <div className="input-group-text">$</div>
                    </div>
                    {errors.wage && touched.wage && <div className="text-danger">{errors.wage}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                    <label htmlFor="dob" className="form-label">
                        Date of birth
                    </label>
                    <CustomDatePicker
                        handleBlur={handleBlur}
                        id="dob"
                        name="dob"
                        disabled={loading || deactivatedReliever}
                        hasError={Boolean(errors.dob && touched.dob)}
                        selected={values.dob ? new Date(values.dob) : null}
                        setFieldValue={setFieldValue}
                        maxDate={new Date(moment().subtract(18, "years").format("yyyy-MM-DD"))}
                    />
                    {errors.dob && touched.dob && <div className="text-danger">{errors.dob}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                    <label className="mb-2 form-label">Position</label>
                    <div className="tom-select">
                        <Listbox value={values.position} onChange={onChangePosition} disabled={loading || deactivatedReliever}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.position?.name ?? "Select a position"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("position", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {positions.res?.data ? (
                                            <>
                                                {positions.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.position === data ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={data.positionId}
                                                        value={data}
                                                    >
                                                        {data.name}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.position && touched.position && <div className="text-danger">{errors.position}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                    <label className="mb-2 form-label">Grade</label>
                    <div className="tom-select">
                        <Listbox
                            value={values.grade}
                            onChange={(value: GradeType) => {
                                setFieldValue("grade", value);
                            }}
                            disabled={loading || deactivatedReliever}
                        >
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.grade ?? "Select grade"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("grade", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        <Listbox.Option
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                    active || !values.grade ? "optionColor" : null
                                                }`
                                            }
                                            value={""}
                                        >
                                            Select grade
                                        </Listbox.Option>
                                        {Object.values(GradeType).map((data, i) => (
                                            <Listbox.Option
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                        active || values.grade === data ? "optionColor" : null
                                                    }`
                                                }
                                                key={i}
                                                value={data}
                                            >
                                                {data}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.grade && touched.grade && <div className="text-danger">{errors.grade}</div>}
                </div>

                {values.position?.positionId === 1 ? (
                    <>
                        <div className="intro-y col-span-12 sm:col-span-6 formField">
                            <label htmlFor="registrationNo" className="form-label">
                                Registration number
                            </label>
                            <input
                                id="registrationNo"
                                name="registrationNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={loading || deactivatedReliever}
                                value={values?.registrationNo ?? ""}
                                type="number"
                                className={`form-control ${errors.registrationNo && touched.registrationNo && "border-danger"}`}
                                placeholder="1"
                            />
                            {errors.registrationNo && touched.registrationNo && <div className="text-danger">{errors.registrationNo}</div>}
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                            <label htmlFor="expiryOfRegistration" className="form-label">
                                Expiry date
                            </label>
                            <CustomDatePicker
                                id="expiryOfRegistration"
                                name="expiryOfRegistration"
                                handleBlur={handleBlur}
                                disabled={loading || deactivatedReliever}
                                hasError={Boolean(errors.expiryOfRegistration && touched.expiryOfRegistration)}
                                selected={values.expiryOfRegistration ? new Date(values.expiryOfRegistration) : null}
                                setFieldValue={setFieldValue}
                                minDate={new Date(moment().add(1, "days").format("yyyy-MM-DD"))}
                            />
                            {errors.expiryOfRegistration && touched.expiryOfRegistration && (
                                <div className="text-danger">{errors.expiryOfRegistration}</div>
                            )}
                        </div>
                    </>
                ) : null}

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Region</label>
                    <div className="tom-select">
                        <Listbox value={values.region} onChange={onChangeRegion} disabled={loading || deactivatedReliever}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.region?.regionName ?? "Select a region"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("region", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {regions.res?.data ? (
                                            <>
                                                {regions.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.region?.regionName === data.regionName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.regionId}
                                                        value={data}
                                                    >
                                                        {data.regionName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.region && touched.region && <div className="text-danger">{errors.region}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">District</label>
                    <div className="tom-select">
                        <Listbox
                            value={values.district}
                            onChange={onChangeDistrict}
                            disabled={(!values.region && true) || loading || deactivatedReliever}
                        >
                            <Listbox.Button className={`ts-input ${!values.region && "disabled"}`}>
                                <div className="item">{values?.district?.districtName ?? "Select a district"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("district", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {districts.res?.data ? (
                                            <>
                                                {districts.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.district?.districtName === data.districtName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.districtId}
                                                        value={data}
                                                    >
                                                        {data.districtName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.district && touched.district && <div className="text-danger">{errors.district}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Suburb</label>
                    <div className="tom-select">
                        <Listbox
                            value={values.suburb}
                            name="suburb"
                            onChange={onChangeSuburb}
                            disabled={(!values.district && true) || loading || deactivatedReliever}
                        >
                            <Listbox.Button id="suburb" className={`ts-input ${!values.district && "disabled"}`}>
                                <div className="item">{values?.suburb?.suburbName ?? "Select a suburb"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("suburb", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {suburbs.res?.data ? (
                                            <>
                                                {suburbs.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.suburb?.suburbName === data.suburbName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.suburbId}
                                                        value={data}
                                                    >
                                                        {data.suburbName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                        {errors.suburb && touched.suburb && <div className="text-danger">{errors.suburb}</div>}
                    </div>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="streetAddress" className="form-label">
                        Street address
                    </label>
                    <input
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        onChange={handleChange}
                        value={values.streetAddress ?? ""}
                        onBlur={handleBlur}
                        className={`form-control ${errors.streetAddress && touched.streetAddress && "border-danger"}`}
                        placeholder="1, ABC building"
                    />
                    {errors.streetAddress && touched.streetAddress && <div className="text-danger">{errors.streetAddress}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="addressLine" className="form-label">
                        Address Line
                    </label>
                    <input
                        id="addressLine"
                        name="addressLine"
                        type="text"
                        onChange={handleChange}
                        value={values.addressLine ?? ""}
                        onBlur={handleBlur}
                        className={`form-control ${errors.addressLine && touched.addressLine && "border-danger"}`}
                        placeholder="Area or zone"
                    />
                    {errors.addressLine && touched.addressLine && <div className="text-danger">{errors.addressLine}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="postalCode" className="form-label">
                        Postal code
                    </label>
                    <input
                        id="postalCode"
                        name="postalCode"
                        onChange={(e) => validateNumber(e.target.value, "postalCode", setFieldValue)}
                        onBlur={handleBlur}
                        value={values.postalCode ?? ""}
                        type="text"
                        className={`form-control ${errors.postalCode && touched.postalCode && "border-danger"}`}
                        placeholder="2012"
                    />
                    {errors.postalCode && touched.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="about" className="form-label">
                        About
                    </label>
                    <textarea
                        id="about"
                        name="about"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Example"
                        disabled={loading || deactivatedReliever}
                        value={values?.about ?? ""}
                        className={`form-control ${errors.about && touched.about && "border-danger"}`}
                    />
                    {errors.about && touched.about && <div className="text-danger">{errors.about}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">First aid certificate</label>
                    <div className="form-switch form-check" style={{ height: "37px" }}>
                        <input
                            id="aidCertificate"
                            name="aidCertificate"
                            onChange={onChangeAidCertificate}
                            checked={values.aidCertificate}
                            className={"form-check-input"}
                            type="checkbox"
                            disabled={loading || deactivatedReliever}
                        />
                    </div>
                </div>
                <div className="intro-y col-span-24 sm:col-span-6">
                    {values.aidCertificate ? (
                        <>
                            <label htmlFor="expiryOfAidCertificate" className="form-label">
                                Expiry date of first aid certificate
                            </label>
                            <CustomDatePicker
                                id="expiryOfAidCertificate"
                                name="expiryOfAidCertificate"
                                handleBlur={handleBlur}
                                minDate={new Date()}
                                hasError={Boolean(errors.expiryOfAidCertificate && touched.expiryOfAidCertificate)}
                                selected={values.expiryOfAidCertificate ? new Date(values.expiryOfAidCertificate) : null}
                                setFieldValue={setFieldValue}
                                disabled={loading || deactivatedReliever}
                            />
                            {errors.expiryOfAidCertificate && touched.expiryOfAidCertificate && (
                                <div className="text-danger">{errors.expiryOfAidCertificate}</div>
                            )}
                        </>
                    ) : null}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 ">
                    <div className="flex justify-between">
                        <label htmlFor="availability" className="form-label mb-3">
                            Availability
                        </label>

                        <div className="flex mb-2">
                            <div className="flex items-center">
                                <div className="w-4 h-4 inline-block rounded mr-2 available-badge"></div>
                                <span>Available</span>
                            </div>
                            <div className="flex items-center ml-3">
                                <div className="w-4 h-4 inline-block rounded mr-2 bg-red-500 unavailable-badge"></div>
                                <span>Unavailable</span>
                            </div>
                        </div>
                    </div>
                    <Calendar
                        className="m-auto unavailability-datepicker"
                        multiple
                        value={values.unavailability}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        currentDate={new DateObject()}
                        disabled={loading || deactivatedReliever}
                        onChange={(selectedDate) => onChangeUnavailability(selectedDate)}
                    />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">Bank Details</h2>
            </div>

            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="intro-y col-span-12 sm:col-span-6 formField">
                        <label htmlFor="account" className="form-label">
                            Account Number
                        </label>
                        <input
                            id="account"
                            name="account"
                            onChange={(e) => {
                                handleAccountNumberChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={loading || deactivatedReliever}
                            placeholder="Account"
                            value={values?.account ?? ""}
                            type="text"
                            className={`form-control ${errors.account && touched.account && "border-danger"}`}
                        />
                        {errors.account && touched.account && <div className="text-danger">{errors.account}</div>}
                    </div>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="intro-y col-span-12 sm:col-span-6 formField">
                        <label htmlFor="bank" className="form-label">
                            Bank Name
                        </label>
                        <input
                            id="bank"
                            name="bank"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={loading || deactivatedReliever}
                            placeholder="Bank Name"
                            value={values?.bank ?? ""}
                            type="text"
                            className={`form-control ${errors.bank && touched.bank && "border-danger"}`}
                        />
                        {errors.bank && touched.bank && <div className="text-danger">{errors.bank}</div>}
                    </div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center p-5 px-0 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">Documents</h2>
            </div>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Primary ID Proof</label>
                    <div className="tom-select mb-2">
                        <Listbox
                            value={values.primaryProofType}
                            name="primaryProofType"
                            onChange={(value) => {
                                setFieldValue("primaryProofType", value);
                            }}
                        >
                            <Listbox.Button id="primaryProofType" className={`ts-input ${!values.primaryProofType && ""}`}>
                                <div className="item">{values?.primaryProofType ?? "Select a primary proof type"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("primaryProofType", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {Object.values(PrimaryProofTypeEnum).map((value, index) => (
                                            <Listbox.Option
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                        active || values.primaryProofType === value ? "optionColor" : null
                                                    }`
                                                }
                                                key={index}
                                                value={value}
                                            >
                                                {value}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                        {errors.primaryProofType && touched.primaryProofType && (
                            <div className="text-danger">{errors.primaryProofType}</div>
                        )}
                    </div>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Primary ID Proof File</label>
                    <ImageComponent
                        setFieldValue={setFieldValue}
                        fieldName="primaryProofFile"
                        value={values?.primaryProofFile}
                        isEdit={isEdit}
                    />
                </div>

                {values.primaryProofType && primaryProofTypeReqDate.includes(String(values.primaryProofType)) && (
                    <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                        <label htmlFor="expiryOfPrimaryProofType" className="form-label">
                            Expiry Of Primary Proof Type
                        </label>
                        <CustomDatePicker
                            handleBlur={handleBlur}
                            id="expiryOfPrimaryProofType"
                            name="expiryOfPrimaryProofType"
                            disabled={loading || deactivatedReliever}
                            hasError={Boolean(errors.expiryOfPrimaryProofType && touched.expiryOfPrimaryProofType)}
                            selected={values.expiryOfPrimaryProofType ? new Date(values.expiryOfPrimaryProofType) : null}
                            setFieldValue={setFieldValue}
                            minDate={new Date()}
                        />
                        {errors.expiryOfPrimaryProofType && touched.expiryOfPrimaryProofType && (
                            <div className="text-danger">{errors.expiryOfPrimaryProofType}</div>
                        )}
                    </div>
                )}

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Secondary ID Proof</label>
                    <div className="tom-select mb-2">
                        <Listbox
                            value={values.secondaryProofType}
                            name="secondaryProofType"
                            onChange={(value) => {
                                setFieldValue("secondaryProofType", value);
                            }}
                        >
                            <Listbox.Button id="secondaryProofType" className={`ts-input ${!values.secondaryProofType && ""}`}>
                                <div className="item">{values?.secondaryProofType ?? "Select a secomndary proof type"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("secondaryProofType", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {Object.values(SecondaryProofTypeEnum).map((value, index) => (
                                            <Listbox.Option
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                        active || values.secondaryProofType === value ? "optionColor" : null
                                                    }`
                                                }
                                                key={index}
                                                value={value}
                                            >
                                                {value}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                        {errors.secondaryProofType && touched.secondaryProofType && (
                            <div className="text-danger">{errors.secondaryProofType}</div>
                        )}
                    </div>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Secondary ID Proof File</label>
                    <ImageComponent
                        setFieldValue={setFieldValue}
                        fieldName="secondaryProofFile"
                        value={values?.secondaryProofFile}
                        isEdit={isEdit}
                    />
                </div>

                {values.secondaryProofType && secondaryProofTypeReqDate.includes(String(values.secondaryProofType)) && (
                    <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                        <label htmlFor="expiryOfSecondaryProofType" className="form-label">
                            Expiry Of Secondary Proof Type
                        </label>
                        <CustomDatePicker
                            handleBlur={handleBlur}
                            id="expiryOfSecondaryProofType"
                            name="expiryOfSecondaryProofType"
                            disabled={loading || deactivatedReliever}
                            hasError={Boolean(errors.expiryOfSecondaryProofType && touched.expiryOfSecondaryProofType)}
                            selected={values.expiryOfSecondaryProofType ? new Date(values.expiryOfSecondaryProofType) : null}
                            setFieldValue={setFieldValue}
                            minDate={new Date()}
                        />
                        {errors.expiryOfSecondaryProofType && touched.expiryOfSecondaryProofType && (
                            <div className="text-danger">{errors.expiryOfSecondaryProofType}</div>
                        )}
                    </div>
                )}

                <Tooltip id="irdNumber" place="top" className="z-50" />
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="cancelJob" className="form-label">
                        IRD Number
                        <HelpCircle
                            className="inline w-4 ml-1"
                            data-tooltip-id="irdNumber"
                            data-tooltip-content="IRD Number (If your IRD Number has 8 digits, add a 0 before entering the rest of your IRD Number)"
                        />
                    </label>
                    <input
                        id="IRDNumber"
                        name="IRDNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={loading || deactivatedReliever}
                        placeholder="IRD Number"
                        value={values?.IRDNumber || ""}
                        type="text"
                        className={`form-control ${errors.IRDNumber && touched.IRDNumber && "border-danger"}`}
                    />
                    {errors.IRDNumber && touched.IRDNumber && <div className="text-danger">{errors.IRDNumber}</div>}
                </div>
            </div>

            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty || deactivatedReliever}>
                        Reset
                    </button>
                    <button className={"btn btn-primary w-24 ml-2"} disabled={loading || !dirty || deactivatedReliever} type="submit">
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddEditReliever;
