import { setLoading } from "../store/slice/BaseSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { successToast } from "../helper/toast";
import { AxiosError } from "axios";
import { handleCatchResponse } from "../helper/helper";
import { Dispatch } from "@reduxjs/toolkit";

export const onDeleteTimeSheet = async (timeSheetId: number, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteTimeSheet}/${timeSheetId}`);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};
