import { Dispatch } from "@reduxjs/toolkit";
import { AddEditJobInterface, ResponseInterface } from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { FormikState } from "formik";
import { setLoading } from "../store/slice/BaseSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { combineDateAndTime, daysOfWeeks } from "../helper/constant";

export const onAddJob = async (
    data: AddEditJobInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<AddEditJobInterface>>) => void,
    tabfor: string
) => {
    try {
        dispatch(setLoading(true));

        let daysOfWeek = data.daysOfWeek?.map((ele) => ele.value);

        if (daysOfWeek) {
            daysOfWeek = daysOfWeek.sort((a, b) => daysOfWeeks.indexOf(a) - daysOfWeeks.indexOf(b));
        }

        const { startDate, endDate } = await combineDateAndTime(
            data.startDate,
            data.startTime,
            data.endDate,
            data.endTime,
            data.repeat,
            daysOfWeek
        );

        const payload = {
            ageGroupId: data.ageGroupForJob?.ageGroupId,
            positionId: data.positionForJob?.positionId,
            breakId: data.breakForJob?.breakId,
            note: data.note,
            scheduler: data.scheduler?.schedulerId,
            centre: data.centreForJob?.centreId,
            repeat: data.repeat,
            endDate,
            startDate,
            noOfRelievers: data.noOfRelievers,
            relieverSelection: data.relieverSelection,
            notifyRelievers: data.notifyRelievers,
            inFavorite: data.inFavorite,
            inDistrict: data.inDistrict,
            sendSpecific: data.sendSpecific,
            reliever: data.reliever?.map((ele) => ele.relieverId),
            daysOfWeek,
        };

        const response = await dataService.post(api.admin.addJob, payload);

        const res: Required<ResponseInterface<AddEditJobInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/job/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/job/edit/${res?.data?.jobId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);

        resetForm();

        navigate(`/job/${tabfor}`);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditJob = async (
    data: AddEditJobInterface,
    jobId: number,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    tabfor: string
) => {
    try {
        dispatch(setLoading(true));

        let daysOfWeek = data.daysOfWeek?.map((ele) => ele.value);

        if (daysOfWeek) {
            daysOfWeek = daysOfWeek.sort((a, b) => daysOfWeeks.indexOf(a) - daysOfWeeks.indexOf(b));
        }

        const { startDate, endDate } = await combineDateAndTime(
            data.startDate,
            data.startTime,
            data.endDate,
            data.endTime,
            data.repeat,
            daysOfWeek
        );

        const payload = {
            ageGroupId: data.ageGroupForJob?.ageGroupId,
            positionId: data.positionForJob?.positionId,
            breakId: data.breakForJob?.breakId,
            note: data.note,
            scheduler: data.scheduler?.schedulerId,
            centre: data.centreForJob?.centreId,
            repeat: data.repeat,
            endDate,
            startDate,
            noOfRelievers: data.noOfRelievers,
            relieverSelection: data.relieverSelection,
            notifyRelievers: data.notifyRelievers,
            inFavorite: data.inFavorite,
            inDistrict: data.inDistrict,
            sendSpecific: data.sendSpecific,
            reliever: data.sendSpecific ? data.reliever?.map((ele) => ele.relieverId) : null,
            daysOfWeek,
        };

        const response = await dataService.put(`${api.admin.job}/${jobId}`, payload);

        const res: Required<ResponseInterface<AddEditJobInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/job/edit/${res?.data?.jobId ?? jobId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);

        navigate(`/job/${tabfor}`);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteJob = async (
    jobId: number,
    dispatch: Dispatch,
    reload: () => void,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.job}/${jobId}`);

        const res = response.data;

        successToast(res.message);

        setIsOpen(false);

        reload();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
