import React from "react";
import { Form } from "formik";
import {
    RelieverForSelect,
    RelieverInterface,
    ReportFormFields,
    ReportProps,
    SchedulerForSelect,
    StateInterface,
} from "../../helper/interface";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { Listbox, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { ReportUserType } from "../../helper/constant";
import CustomDatePicker from "../datepicker/CustomDatePicker";

const ReportFilter = ({
    values,
    setFieldTouched,
    setFieldValue,
    resetForm,
    setReportDetails,
    errors,
    touched,
    dirty,
    handleBlur,
}: ReportProps) => {
    const schedulersData = useFetch<SchedulerForSelect[]>(`${api.admin.schedulersForCentre}`);

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const relieverData = useFetch<RelieverInterface[]>(`${api.admin.reliever}`);

    const getMaxEndDate = () => {
        if (values.to) {
            return new Date(moment(values.to).format("YYYY-MM-DD"));
        } else {
            return new Date(moment().add(-1, "days").format("YYYY-MM-DD"));
        }
    };

    const getMinEndDate = () => {
        if (values.from) {
            return new Date(moment(values.from).format("YYYY-MM-DD"));
        }
    };

    const getMaxToDate = () => {
        return new Date(moment().add(-1, "days").format("YYYY-MM-DD"));
    };

    const onChangeScheduler = (value: SchedulerForSelect) => {
        setFieldValue("scheduler", value);
    };

    const onChangeReliever = (value: RelieverForSelect) => {
        setFieldValue("reliever", value);
    };

    const handleOptionChange = (value: ReportFormFields) => {
        resetForm();

        setReportDetails(null);

        setFieldValue("fieldName", value);
    };

    const handleResetField = () => {
        resetForm();

        setReportDetails(null);
    };

    return (
        <Form id="tabulator-html-filter-form" className="flex items-center align-items-baseline report-form">
            <div className="flex items-center sm:mr-4 py-1" style={{ zIndex: 60 }}>
                <label className="flex-none mr-2 mb-0">Select user type</label>
                <div className="tom-select">
                    <Listbox value={values.fieldName} onChange={handleOptionChange}>
                        <Listbox.Button className="form-control ts-input">
                            <div className="item">{values?.fieldName ?? "Select a scheduler"}</div>
                        </Listbox.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                            onBlur={() => setFieldTouched("fieldName", true)}
                        >
                            <Listbox.Options className="ts-dropdown single w-full">
                                <div role="listbox" className="ts-dropdown-content">
                                    {ReportUserType ? (
                                        <>
                                            {ReportUserType.map((data) => (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || values?.fieldName === data ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={data}
                                                    value={data}
                                                >
                                                    {data}
                                                </Listbox.Option>
                                            ))}
                                        </>
                                    ) : null}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </Listbox>
                </div>
            </div>

            <div className="flex items-center report-form datePicker">
                <div className="flex items-center sm:mr-4 py-1 flex-col">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label htmlFor="startDate" className="mb-0 mr-2">
                                From
                            </label>
                            <CustomDatePicker
                                id="from"
                                name="from"
                                maxDate={getMaxEndDate()}
                                selected={values.from ? new Date(values.from) : null}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                            />
                        </div>
                        {errors.from && touched.from && <div className="text-danger">{errors.from}</div>}
                    </div>
                </div>
                <div className="flex items-center sm:mr-4 py-1 flex-col">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label htmlFor="endDate" className="mb-0 mr-2">
                                To
                            </label>
                            <CustomDatePicker
                                id="to"
                                name="to"
                                minDate={getMinEndDate()}
                                maxDate={getMaxToDate()}
                                selected={values.to ? new Date(values.to) : null}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                            />
                        </div>
                        {errors.to && touched.to && <div className="text-danger">{errors.to}</div>}
                    </div>
                </div>
            </div>

            {ReportFormFields.scheduler === values.fieldName ? (
                <div className="flex items-center formField">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label className="mb-0 mr-2">Scheduler</label>
                            <div className="tom-select">
                                <Listbox value={values.scheduler} onChange={onChangeScheduler}>
                                    <Listbox.Button className="form-control ts-input w-full">
                                        <div className="item">{values?.scheduler?.name ?? "Select a scheduler"}</div>
                                    </Listbox.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                        onBlur={() => setFieldTouched("scheduler", true)}
                                    >
                                        <Listbox.Options className="ts-dropdown single w-full">
                                            <div role="listbox" className="ts-dropdown-content">
                                                {schedulersData?.res?.data ? (
                                                    <>
                                                        {schedulersData?.res?.data?.map((data) => (
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values?.scheduler?.name === data.name
                                                                            ? "optionColor"
                                                                            : null
                                                                    }`
                                                                }
                                                                key={data.schedulerId}
                                                                value={data}
                                                            >
                                                                {data.name}
                                                            </Listbox.Option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </div>
                                        </Listbox.Options>
                                    </Transition>
                                </Listbox>
                            </div>
                        </div>
                        {errors.scheduler && touched.scheduler && <div className="text-danger">{errors.scheduler}</div>}
                    </div>
                </div>
            ) : null}

            {ReportFormFields.reliever === values.fieldName ? (
                <div className="flex items-center formField">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label className="mb-0 mr-2">Reliever</label>
                            <div className="tom-select">
                                <Listbox value={values.reliever} onChange={onChangeReliever}>
                                    <Listbox.Button className="form-control ts-input w-full">
                                        <div className="item">{values?.reliever?.name ?? "Select a reliever"}</div>
                                    </Listbox.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                        onBlur={() => setFieldTouched("reliever", true)}
                                    >
                                        <Listbox.Options className="ts-dropdown single w-full">
                                            <div role="listbox" className="ts-dropdown-content">
                                                {relieverData?.res?.data ? (
                                                    <>
                                                        {relieverData?.res?.data?.map((data) => (
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values?.reliever?.name === data.name
                                                                            ? "optionColor"
                                                                            : null
                                                                    }`
                                                                }
                                                                key={data.relieverId}
                                                                value={data}
                                                            >
                                                                {data.name}
                                                            </Listbox.Option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </div>
                                        </Listbox.Options>
                                    </Transition>
                                </Listbox>
                            </div>
                        </div>
                        {errors.reliever && touched.reliever && <div className="text-danger">{errors.reliever}</div>}
                    </div>
                </div>
            ) : null}

            <div className="mt-2 ml-4 report-action-button">
                <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={loading || !dirty}>
                    Go
                </button>
                <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    className="btn btn-secondary w-16 ml-2"
                    onClick={handleResetField}
                    disabled={!dirty}
                >
                    Reset
                </button>
            </div>
        </Form>
    );
};

export default ReportFilter;
