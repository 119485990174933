import { FC, useState, useCallback } from "react";
import UserTabs from "../UserTabs";
import usePathName from "../../../hooks/usePathName";
import CustomTable from "../../table/CustomTable";
import useTable from "../../../hooks/useTable";
import { ActivateDeactivateRelieverInterface, ApprovalState, PreviewFilesInterface, RelieverInterface } from "../../../helper/interface";
import { AlertTriangle, CheckSquare, Download, FilePlus, FileText, Trash2, Bell, Send } from "react-feather";
import { api } from "../../../config/Api";
import SearchForm from "../../table/SearchForm";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    onActivateDeactivateReliever,
    onApproveDeclineUser,
    onDeleteReliever,
    onRelieverBulkUpload,
    onRelieverSampleFileDownload,
} from "../../../service/user";
import DeleteModal from "../../modal/DeleteModal";
import FileUploadModal from "../../modal/FileUploadModal";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import ApprovalUserModal from "../../modal/ApprovalUserModel";
import { ApprovalTypes, documentStatus } from "../../../helper/constant";
import RejectModal from "../../modal/RejectModal";
import NotifyUserModal from "../../modal/NotifyUser";

const Reliever: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isNotifyModalOpen, setNotifyModalOpen] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [files, setFiles] = useState<PreviewFilesInterface[]>([]);
    const [reliever, setReliever] = useState<RelieverInterface | null>(null);
    const [showApproved, setShowApproved] = useState(true);
    const [approvalId, setApprovalId] = useState<ApprovalState | null>(null);
    usePathName([{ pathName: "Reliever", href: "/user/reliever" }]);

    const relieverTable = useTable<RelieverInterface>("reliever", api.admin.reliever, false, {
        showApproved,
    });

    const onDeleteClick = useCallback((record: RelieverInterface) => {
        setReliever(record);
        setIsOpen(true);
    }, []);

    const onNotifyClick = useCallback((record: RelieverInterface) => {
        setReliever(record);
        setNotifyModalOpen(true);
    }, []);

    const handleNotifyModalCancel = () => {
        setNotifyModalOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const onDelete = () => {
        if (reliever?.relieverId) {
            onDeleteReliever(reliever?.relieverId, dispatch, relieverTable.fetchApi, setIsOpen);
        }
    };

    const approveToggle = {
        title: "APPROVED",
        key: "approval",
        render: (record: RelieverInterface) => {
            return (
                <span className={`${record.approval === ApprovalTypes.PENDING ? "text-warning" : "text-danger"}`}>{record.approval}</span>
            );
        },
    };

    const columns = [
        {
            title: "#",
            key: "schedulerId",
            render: (record: RelieverInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "name",
            sortable: true,
            render: (record: RelieverInterface) => {
                return <span>{record.name}</span>;
            },
        },
        {
            title: "EMAIL",
            key: "email",
            sortable: true,
            render: (record: RelieverInterface) => {
                return <span>{record.email}</span>;
            },
        },
        {
            title: "MOBILE NUMBER",
            key: "mobileNumber",
            render: (record: RelieverInterface) => {
                return <span>{record.mobileNumber}</span>;
            },
        },
        {
            title: "POSITION",
            key: "position",
            render: (record: RelieverInterface) => {
                return <span>{record?.position?.name ?? "-"}</span>;
            },
        },
        {
            title: "PLATFORM",
            key: "signUpPlatform",
            render: (record: RelieverInterface) => {
                return <span>{record.signUpPlatform}</span>;
            },
        },
        ...(!showApproved ? [approveToggle] : []),
        {
            title: "STATUS",
            key: "isActive",
            render: (record: RelieverInterface) => {
                const payload = {
                    relieverId: record.relieverId,
                    isActive: !record.isActive,
                };

                const isExpired =
                    record.positionId === 1 &&
                    record?.expiryOfRegistration &&
                    moment(record.expiryOfRegistration).startOf("days").toISOString() < moment().startOf("days").toISOString();

                return (
                    <div className="form-switch form-check flex justify-center flex-col">
                        <Tooltip id="simpleTooltipForActivateReliever" place="top" />
                        <input
                            id="isActive"
                            name="isActive"
                            data-tooltip-id="simpleTooltipForActivateReliever"
                            data-tooltip-content={
                                isExpired ? `Registration expired on ${moment(record.expiryOfRegistration).format("DD/MM/YYYY")}` : ""
                            }
                            onChange={() => onActivateDeactivate(payload)}
                            checked={record.isActive}
                            className={`form-check-input ${isExpired && "checkbox-style"} ${
                                record.isActive && isExpired && "checkbox-active"
                            }`}
                            type="checkbox"
                        />
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: RelieverInterface) => {
                const isDocPending = record?.documents?.some((doc) => doc.status === documentStatus[0]);
                const isDocExpire = record?.documents?.some((doc) => moment.utc(doc.expiryDate).isBefore(moment.utc(new Date())));

                return (
                    <>
                        <div className="flex justify-center items-center cursor-pointer">
                            <Tooltip id="simpleTooltipEditDeleteReliever" place="top" />
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteReliever"
                                data-tooltip-content={"View feedback"}
                                onClick={() => {
                                    navigate(`/feedback?relieverId=${record?.relieverId}`);
                                }}
                            >
                                <Send className="w-4 h-4 mr-1" />
                            </button>
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteReliever"
                                data-tooltip-content={"Send Notification"}
                                onClick={() => onNotifyClick(record)}
                            >
                                <Bell className="w-4 h-4 mr-1" />
                            </button>
                            {record.approval === ApprovalTypes.PENDING ? (
                                <>
                                    <button
                                        className="flex items-center mr-3 text-success"
                                        data-tooltip-id="simpleTooltipEditDeleteReliever"
                                        data-tooltip-content={"Edit reliever"}
                                        onClick={() => setApprovalId({ id: record.relieverId, status: ApprovalTypes.APPROVE })}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        className="flex items-center mr-3 text-danger"
                                        data-tooltip-id="simpleTooltipEditDeleteReliever"
                                        data-tooltip-content={"Edit reliever"}
                                        onClick={() => setApprovalId({ id: record.relieverId, status: ApprovalTypes.REJECTED })}
                                    >
                                        Reject
                                    </button>
                                </>
                            ) : null}
                            <button
                                className={`flex items-center mr-3 ${(isDocPending || isDocExpire) ? "text-warning" : "text-success"}`}
                                data-tooltip-id="simpleTooltipEditDeleteReliever"
                                data-tooltip-content={
                                    record?.documents?.length === 0 ? "No documents" : `Total ${record?.documents?.length} documents`
                                }
                                onClick={() => navigate(`/user/reliever/documents/${record.relieverId}`)}
                            >
                                <FilePlus className="w-4 h-4 mr-1" /> Docs
                            </button>
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteReliever"
                                data-tooltip-content={"Edit reliever"}
                                onClick={() => navigate(`/user/reliever/edit/${record.relieverId}`)}
                            >
                                <CheckSquare className="w-4 h-4 mr-1" /> Edit
                            </button>
                            <button
                                className={`flex items-center ${record?.deletedAt ? "color-red" : "text-danger"} cursor-pointer`}
                                data-tooltip-id="simpleTooltipEditDeleteReliever"
                                data-tooltip-content={"Delete reliever"}
                                onClick={() => onDeleteClick(record)}
                            >
                                {record?.deletedAt ? <AlertTriangle className="w-4 h-4 mr-1" /> : <Trash2 className="w-4 h-4 mr-1" />}
                                Delete
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    const onActivateDeactivate = (payload: ActivateDeactivateRelieverInterface) => {
        onActivateDeactivateReliever(payload, dispatch, relieverTable.fetchApi);
    };

    const onResetXlsxForm = () => {
        setFiles([]);
    };

    const handleCancelForUploadModal = () => {
        setUploadModal(false);
        onResetXlsxForm();
    };

    const onSuccessUpload = () => {
        relieverTable.fetchApi();
        handleCancelForUploadModal();
    };

    const onSubmitXlsxForm = () => {
        onRelieverBulkUpload(files[0], dispatch, onSuccessUpload);
    };

    const onSampleXlsxDownload = () => {
        onRelieverSampleFileDownload(dispatch);
    };

    const onChangeApproveType = useCallback(
        (value: boolean) => {
            setShowApproved(value);

            const filterValue = { showApproved: value };

            relieverTable.setLParams(filterValue);
            relieverTable.onSearch("", filterValue);
        },
        [relieverTable.setLParams, relieverTable.onSearch]
    );

    const onCancelApprovalModel = useCallback(() => {
        setApprovalId(null);
    }, []);

    const onApproveOrDecline = useCallback(
        (status: ApprovalTypes) => {
            // On response of success
            const onSuccess = () => {
                relieverTable.fetchApi();
                setApprovalId(null);
            };

            if (approvalId?.id) onApproveDeclineUser(approvalId.id, status, dispatch, onSuccess, api.admin.approveDeclineReliever);
        },
        [approvalId]
    );

    return (
        <UserTabs onChangeApproveType={onChangeApproveType} showApproved={showApproved}>
            <ApprovalUserModal
                handleCancel={onCancelApprovalModel}
                isOpen={!!approvalId?.id}
                onOk={() => onApproveOrDecline(ApprovalTypes.APPROVE)}
            />
            <RejectModal
                handleCancel={onCancelApprovalModel}
                isOpen={!!(approvalId && approvalId.id && approvalId.status === ApprovalTypes.REJECTED)}
                onReject={() => onApproveOrDecline(ApprovalTypes.REJECTED)}
                subTitle="Reject user"
            />
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this reliever?"
            />
            <NotifyUserModal isOpen={isNotifyModalOpen} handleCancel={handleNotifyModalCancel} relieverId={reliever?.relieverId} />
            <FileUploadModal
                handleCancel={handleCancelForUploadModal}
                isOpen={uploadModal}
                files={files}
                setFiles={setFiles}
                onSubmit={onSubmitXlsxForm}
                onReset={onResetXlsxForm}
                subTitle="Bulk upload for reliever"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={columns}
                    data={relieverTable.tableData ?? []}
                    tableKey="relieverId"
                    currentOrder={relieverTable.currentOrder}
                    reload={relieverTable.fetchApi}
                    onSetOrderBy={relieverTable.onSetOrderBy}
                    loading={relieverTable.loading}
                    error={relieverTable.error}
                    component={
                        <>
                            <SearchForm
                                resetSearch={relieverTable.resetSearch}
                                onSearch={relieverTable.onSearch}
                                searchPlaceHolder="Search by name or email"
                            />
                            <div className="flex flex-col w-full mt-5 sm:mt-0 sm:flex-row sm:w-auto mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltipReliever" place="top" />
                                <button
                                    data-tooltip-id="simpleTooltipReliever"
                                    data-tooltip-content="Add new reliever"
                                    className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    onClick={() => {
                                        navigate("/user/reliever/add");
                                    }}
                                >
                                    Add new
                                </button>
                                <button
                                    className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipReliever"
                                    data-tooltip-content="Import XLS"
                                    onClick={() => setUploadModal(true)}
                                >
                                    <FileText className="w-4 h-4 mr-1 flex items-center justify-center" />
                                    Import
                                </button>
                                <button
                                    className="btn btn-outline-success mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipReliever"
                                    data-tooltip-content="Download sample XLS"
                                    onClick={onSampleXlsxDownload}
                                >
                                    <Download className="w-4 h-4 mr-1 flex items-center justify-center" />
                                    Sample file
                                </button>
                            </div>
                        </>
                    }
                    tableOffset={relieverTable.tableOffset}
                />
            </div>
        </UserTabs>
    );
};

export default Reliever;
