import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_BACKEND_API;

const FRONTEND_ENDPOINT = "";

const dataService = axios.create({
    baseURL: API_ENDPOINT,
    headers: { "Content-Type": "application/json" },
});

dataService.interceptors.request.use(function (config) {
    const token = localStorage.getItem("Token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

export { dataService, API_ENDPOINT, FRONTEND_ENDPOINT };
