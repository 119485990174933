import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { dataService } from "../config/DataService";
import { handleErrorForFetch } from "../helper/helper";
import { ResponseInterface } from "../helper/interface";

const useFetch = <DataInterface, ExtraDataInterface = object>(url: string) => {
    const [loading, setLoading] = useState(true);
    const [res, setRes] = useState<ResponseInterface<DataInterface> & { extraData: ExtraDataInterface }>();
    const [error, setError] = useState<string | null>(null);

    const fetchApi = (updatedUrl?: string) => {
        setLoading(true);
        dataService
            .get(updatedUrl ? updatedUrl : url)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .then((json) => {
                setLoading(false);
                setRes(json);
            })
            .catch((error: AxiosError) => {
                handleErrorForFetch(error, setError);
            });
    };

    useEffect(() => {
        fetchApi();
    }, []);

    return { loading, res, fetchApi, error };
};

export default useFetch;
