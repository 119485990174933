import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { handleCatchResponse } from "../helper/helper";
import { ChangePasswordInterface, LoginInterface } from "../helper/interface";
import { setLoading } from "../store/slice/BaseSlice";
import { successToast } from "../helper/toast";
import { FormikState } from "formik";

export const onLogin = async (payload: LoginInterface, dispatch: Dispatch, navigate: NavigateFunction) => {
    try {
        dispatch(setLoading(true));
        const response = await dataService.post(api.admin.login, payload);

        const res = response.data;

        localStorage.setItem("Token", res.data.token);

        navigate("/dashboard");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onChangePassword = async (
    data: ChangePasswordInterface,
    dispatch: Dispatch,
    resetForm: (nextState?: Partial<FormikState<ChangePasswordInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.post(api.admin.changePassword, data);

        const res = response.data;

        resetForm();

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
