import React from "react";
import Modal from "./Modal";
import { RejectModalInterface, StateInterface } from "../../helper/interface";
import { XOctagon } from "react-feather";
import { useSelector } from "react-redux";
import Loading from "../../container/Loading";
import { documentStatus } from "../../helper/constant";

const RejectModal = ({ isOpen, handleCancel, onReject, subTitle }: RejectModalInterface) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Modal isOpen={isOpen}>
            <>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <>
                        <div className="p-5 text-center">
                            <XOctagon className="w-16 h-16 text-warning mx-auto mt-3" />
                            <div className="text-3xl mt-5">Are you sure?</div>
                            <div className="text-slate-500 mt-2">
                                {subTitle}
                                <br />
                                This process cannot be undone.
                            </div>
                        </div>
                        <div className="px-5 pb-8 text-center">
                            <button
                                type="button"
                                data-tw-dismiss="modal"
                                className="btn btn-outline-secondary w-24 mr-3"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-warning w-24" onClick={() => onReject(documentStatus[1])}>
                                Reject
                            </button>
                        </div>
                    </>
                )}
            </>
        </Modal>
    );
};

export default RejectModal;
