import { Form } from "formik";
import { useSelector } from "react-redux";
import { FormInterface, InvoiceInterface, SchedulerForSelect, StateInterface, ZIndexMaintainer } from "../../helper/interface";
import moment from "moment";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { Listbox, Transition } from "@headlessui/react";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { ImageComponent } from "../imageComponent";
import { useState } from "react";
import { onDatePickerClose, onDatePickerOpen } from "../../helper/helper";

const AddEditInvoiceForm = ({
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    dirty,
    setFieldValue,
    setFieldTouched,
    isEdit = false,
}: FormInterface<InvoiceInterface> & { isEdit?: boolean }) => {
    const [zIndex, setZIndex] = useState<ZIndexMaintainer>({});

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const schedulersData = useFetch<SchedulerForSelect[]>(`${api.admin.schedulersForCentre}`);

    const onChangeScheduler = (value: SchedulerForSelect | null) => {
        setFieldValue("scheduler", value);
        setFieldValue("schedulerId", value?.schedulerId);
    };

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                    <label htmlFor="fromDate" className="form-label">
                        From Date
                    </label>
                    <CustomDatePicker
                        id="fromDate"
                        name="fromDate"
                        selected={values.fromDate ? new Date(moment(values.fromDate).format("yyyy-MM-DD")) : null}
                        hasError={Boolean(errors.fromDate && touched.fromDate)}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                    />
                    {errors.fromDate && touched.fromDate && <div className="text-danger">{errors.fromDate}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 datePicker" style={{ zIndex: zIndex.toDate }}>
                    <label htmlFor="toDate" className="form-label">
                        To Date
                    </label>
                    <CustomDatePicker
                        id="toDate"
                        name="toDate"
                        selected={values.toDate ? new Date(moment(values.toDate).format("yyyy-MM-DD")) : null}
                        hasError={Boolean(errors.toDate && touched.toDate)}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onCalendarOpen={onDatePickerOpen(setZIndex, "toDate")}
                        onCalendarClose={onDatePickerClose(setZIndex, "toDate")}
                    />
                    {errors.toDate && touched.toDate && <div className="text-danger">{errors.toDate}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="amount" className="form-label">
                        Amount
                    </label>
                    <input
                        id="amount"
                        name="amount"
                        type="number"
                        min={0}
                        step="any"
                        onChange={handleChange}
                        value={values?.amount}
                        onBlur={handleBlur}
                        className={`form-control ${errors.amount && touched.amount && "border-danger"}`}
                        placeholder="Amount"
                    />
                    {errors.amount && touched.amount && <div className="text-danger">{errors.amount}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="description" className="form-label">
                        Description
                    </label>
                    <input
                        id="description"
                        name="description"
                        type="text"
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                        className={`form-control ${errors.description && touched.description && "border-danger"}`}
                        placeholder="Description"
                    />
                    {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="description" className="form-label">
                        Scheduler
                    </label>
                    <div className="tom-select">
                        <Listbox value={values.scheduler} onChange={onChangeScheduler}>
                            <Listbox.Button className="form-control ts-input w-full">
                                <div className="item">{values?.scheduler?.name ?? "Select a scheduler"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("schedulerId", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {schedulersData?.res?.data ? (
                                            <>
                                                {schedulersData?.res?.data?.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.scheduler?.name === data.name ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={data.schedulerId}
                                                        value={data}
                                                    >
                                                        {data.name}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.schedulerId && touched.schedulerId && <div className="text-danger">{errors.schedulerId}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6 datePicker" style={{ zIndex: zIndex.dueDate }}>
                    <label htmlFor="dueDate" className="form-label">
                        Due Date
                    </label>
                    <CustomDatePicker
                        id="dueDate"
                        name="dueDate"
                        selected={values.dueDate ? new Date(moment(values.dueDate).format("yyyy-MM-DD")) : null}
                        hasError={Boolean(errors.dueDate && touched.dueDate)}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onCalendarOpen={onDatePickerOpen(setZIndex, "dueDate")}
                        onCalendarClose={onDatePickerClose(setZIndex, "dueDate")}
                    />
                    {errors.dueDate && touched.dueDate && <div className="text-danger">{errors.dueDate}</div>}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="invoiceFile" className="form-label">
                        Invoice File
                    </label>
                    <ImageComponent setFieldValue={setFieldValue} fieldName="invoiceFile" value={values?.invoiceFile} isEdit={isEdit} />
                    {errors.invoiceFile && touched.invoiceFile && <div className="text-danger">{errors.invoiceFile}</div>}
                </div>

                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        Send
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddEditInvoiceForm;
