import { useRef, RefObject } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import { CentreForSelect, FormInterface, SchedulerInterface, StateInterface } from "../../../helper/interface";
import moment from "moment";
import UserAvatar from "../../../assets/images/userAvatar.png";
import { getImageUrl } from "../../../helper/constant";
import { Camera } from "react-feather";
import useFetch from "../../../hooks/useFetch";
import { api } from "../../../config/Api";
import Select, { MultiValue } from "react-select";
import CustomDatePicker from "../../datepicker/CustomDatePicker";

const AddEditScheduler = ({
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
    dirty,
    setFieldValue,
    setFieldTouched,
}: FormInterface<SchedulerInterface>) => {
    const profileImgRef = useRef() as RefObject<HTMLInputElement>;

    const centreData = useFetch<CentreForSelect[]>(`${api.admin.getCentre}`);

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const deactivatedScheduler = Boolean(values?.deletedAt);
    return (
        <Form className="addNewSchedulerPage">
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="preview mb-5 col-span-12">
                    <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                        <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 avatar-circle image-fit relative">
                            <img
                                alt="Profile picture"
                                className="rounded-full img-bordered"
                                src={values?.profileImg ? getImageUrl(values?.profileImg) : UserAvatar}
                            />
                            <div
                                className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary rounded-full p-2 svg-bordered-white"
                                onClick={() => profileImgRef?.current?.click()}
                            >
                                <Camera
                                    className={`lucide lucide-camera w-4 h-4 text-white ${!deactivatedScheduler && "cursor-pointer"}`}
                                />
                                <input
                                    ref={profileImgRef}
                                    name="profileImg"
                                    type="file"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e?.target?.files?.[0]) {
                                            setFieldValue("profileImg", e?.target?.files?.[0]);
                                        }
                                    }}
                                    accept="image/*"
                                    disabled={loading || deactivatedScheduler}
                                    className="hidden"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6" style={{ zIndex: 1 }}>
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Example"
                        value={values.name}
                        type="text"
                        disabled={loading || deactivatedScheduler}
                        className={`form-control ${errors.name && touched.name && "border-danger"}`}
                    />
                    {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6" style={{ zIndex: 1 }}>
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        disabled={loading || deactivatedScheduler}
                        className={`form-control ${errors.email && touched.email && "border-danger"}`}
                        placeholder="example@gmail.com"
                    />
                    {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6" style={{ zIndex: 1 }}>
                    <label htmlFor="mobileNumber" className="form-label">
                        Mobile number
                    </label>
                    <div className="input-group">
                        <div className="input-group-text">+64</div>
                        <input
                            id="mobileNumber"
                            name="mobileNumber"
                            disabled={loading || deactivatedScheduler}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobileNumber}
                            type="text"
                            className={`form-control ${errors.mobileNumber && touched.mobileNumber && "border-danger"}`}
                            placeholder="874 256 4587"
                        />
                    </div>
                    {errors.mobileNumber && touched.mobileNumber && <div className="text-danger">{errors.mobileNumber}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Centre</label>
                    <div className="">
                        {centreData?.res?.data ? (
                            <Select
                                isMulti
                                isDisabled={loading || deactivatedScheduler}
                                name="centre"
                                options={centreData?.res?.data}
                                value={values?.centre}
                                onBlur={() => setFieldTouched("centre", true)}
                                onChange={(selectedItem: MultiValue<CentreForSelect>) => {
                                    setFieldValue("centre", selectedItem);
                                }}
                                className={`form-control tom-select ${errors.centre && touched.centre && "border-danger"}`}
                                classNamePrefix="select"
                                getOptionLabel={(option) => option.centreName}
                                getOptionValue={(option) => option.centreId}
                            />
                        ) : null}
                    </div>
                    {errors.centre && touched.centre && <div className="text-danger">{errors.centre}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                    <label htmlFor="dob" className="form-label">
                        Date of birth
                    </label>
                    <CustomDatePicker
                        handleBlur={handleBlur}
                        id="dob"
                        disabled={loading || deactivatedScheduler}
                        name="dob"
                        hasError={Boolean(errors.dob && touched.dob)}
                        selected={values.dob ? new Date(values.dob) : null}
                        setFieldValue={setFieldValue}
                        maxDate={new Date(moment().subtract(18, "years").format("yyyy-MM-DD"))}
                    />
                    {errors.dob && touched.dob && <div className="text-danger">{errors.dob}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5" style={{ zIndex: 1 }}>
                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty || deactivatedScheduler}>
                        Reset
                    </button>
                    <button className={"btn btn-primary w-24 ml-2"} disabled={loading || !dirty || deactivatedScheduler} type="submit">
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddEditScheduler;
