import { useEffect, useState, useCallback, useMemo } from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import {
    AddEditJobInterface,
    AgeGroupTableInterface,
    BreakInterface,
    CentreInterface,
    CommonDataForJobInterface,
    FormInterface,
    PositionInterface,
    RelieverForSendSpecific,
    RelieverInterface,
    RelieverSelectionPayload,
    RepeatInterface,
    SchedulerForSelect,
    SelectInterface,
    StateInterface,
} from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { Listbox, Transition } from "@headlessui/react";
import { api } from "../../config/Api";
import moment from "moment";
import Select, { MultiValue } from "react-select";
import { daysOfWeeks, repeatValue } from "../../helper/constant";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { getDate, maxWeekDay, serializeQueryParams } from "../../helper/helper";

const days = daysOfWeeks.map((ele) => ({ value: ele, label: ele }));

const AddEditJob = ({
    errors,
    touched,
    setFieldValue,
    values,
    setFieldTouched,
    handleChange,
    handleBlur,
    dirty,
}: FormInterface<AddEditJobInterface>) => {
    const [disabledDays, setDisabledDays] = useState<string[] | null>(null);
    const loading = useSelector((state: StateInterface) => state.base.loading);

    const centreData = useFetch<CentreInterface[]>(`${api.admin.getCentre}`);
    const commonData = useFetch<CommonDataForJobInterface>(`${api.admin.getCommonDataForJob}`);

    const relieverPayload: RelieverSelectionPayload = useMemo(() => {
        const payload: RelieverSelectionPayload = {};

        if (values.startDate && values.repeat) {
            const startDateTime = moment.utc(values.startDate).format("YYYY-MM-DD");

            payload.startDate = startDateTime;
            payload.repeat = values.repeat;

            if (values.repeat === "Don't repeat") {
                const endDateTime = moment.utc(values.startDate).format("YYYY-MM-DD");
                payload.endDate = endDateTime;
            }

            if (values.repeat === "Same week" && values?.daysOfWeek) {
                const daysArray = values.daysOfWeek.map((ele) => ele.value);

                const weekDay = maxWeekDay(daysArray);

                if (weekDay) {
                    const selectedDayDate = getDate(startDateTime, weekDay);
                    const momentDate = moment(selectedDayDate, "DD/MM/YYYY");
                    const formattedDate = momentDate.format("YYYY-MM-DD");
                    const formatedDateTime = moment(formattedDate).format("YYYY-MM-DD");
                    payload.endDate = formatedDateTime;
                }
            }
        }

        if (values.endDate && values.repeat !== "Don't repeat" && values.repeat !== "Same week") {
            const endDateTime = moment(values.endDate).format("YYYY-MM-DD");
            payload.endDate = endDateTime;
        }

        if (values.positionForJob) {
            payload.positionId = values.positionForJob.positionId;
        }

        if (Array.isArray(values?.daysOfWeek)) {
            payload.daysOfWeek = values?.daysOfWeek?.map((ele) => ele.value).join(",");
        }

        return payload;
    }, [values]);

    const relieverData = useFetch<RelieverInterface[]>(`${api.admin.relieverForJobCreate}?${serializeQueryParams(relieverPayload)}`);

    useEffect(() => {
        if (values.relieverSelection && values.sendSpecific) {
            relieverData.fetchApi(`${api.admin.relieverForJobCreate}?${serializeQueryParams(relieverPayload)}`);
        }
    }, [
        values.startDate,
        values.repeat,
        values.endDate,
        values.daysOfWeek,
        values.positionForJob,
        values.relieverSelection,
        values.sendSpecific,
        relieverPayload,
    ]);

    const ageGroups = commonData.res?.data?.ageGroup;
    const positions = commonData.res?.data?.position;
    const breaks = commonData.res?.data?.break;

    const isCompletedJob = values?.status === "completed";

    const onChangeScheduler = useCallback((value: SchedulerForSelect) => {
        setFieldValue("scheduler", value);
    }, []);

    const onChangeCentre = useCallback((value: CentreInterface) => {
        setFieldValue("centreForJob", value);
        setFieldValue("scheduler", null);
    }, []);

    const onChangeAgeGroup = useCallback((value: AgeGroupTableInterface) => {
        setFieldValue("ageGroupForJob", value);
    }, []);

    const onChangePosition = useCallback((value: Required<PositionInterface>) => {
        setFieldValue("positionForJob", value);
    }, []);

    const onChangeBreak = useCallback((value: Required<BreakInterface>) => {
        setFieldValue("breakForJob", value);
    }, []);

    const resetSelectedReliever = useCallback(() => {
        setFieldValue("reliever", null);
    }, []);

    const onChangeRepeat = useCallback(
        (value: RepeatInterface) => {
            if (value === repeatValue[0]) {
                setFieldValue("daysOfWeek", []);
            } else if (value === repeatValue[1]) {
                setFieldValue("endDate", "");
            }
            resetSelectedReliever();
            setFieldValue("repeat", value);
        },
        [repeatValue]
    );

    const onChangeSendSpecific = useCallback(() => {
        const value = !values.sendSpecific;
        setFieldValue("sendSpecific", value);
        if (value) {
            setFieldValue("inFavorite", false);
            setFieldValue("inDistrict", false);
        } else {
            setFieldValue("reliever", null);
        }
    }, [values.sendSpecific]);

    useEffect(() => {
        if (values.startDate && values.repeat === repeatValue[1]) {
            const selectedDate = new Date(values.startDate);

            const indexOfDay = selectedDate.getDay();

            const disabledDayForSet = daysOfWeeks.filter((_, i: number) => i < indexOfDay - 1);

            const newDaysOfWeekValue = values.daysOfWeek?.filter((ele) => !disabledDayForSet.includes(ele.value));

            setFieldValue("daysOfWeek", newDaysOfWeekValue);

            setDisabledDays(disabledDayForSet);
        } else {
            setDisabledDays([]);
        }
    }, [values.startDate, values.repeat]);

    const onChangeInDistrict = useCallback((value: boolean) => {
        if (value) {
            setFieldValue("sendSpecific", false);
        }
        setFieldValue("inDistrict", value);
    }, []);

    const onChangeInFavorite = useCallback((value: boolean) => {
        if (value) {
            setFieldValue("sendSpecific", false);
        }
        setFieldValue("inFavorite", value);
    }, []);

    const onChangeRelieverSelection = useCallback((value: boolean) => {
        if (!value) {
            setFieldValue("sendSpecific", false);
            setFieldValue("inFavorite", false);
            setFieldValue("inDistrict", false);
            setFieldValue("reliever", null);
        }
        setFieldValue("relieverSelection", value);
    }, []);

    const onChangeDayOfWeek = useCallback((selectedItem: MultiValue<SelectInterface>) => {
        setFieldValue("daysOfWeek", selectedItem);

        resetSelectedReliever();
    }, []);

    return (
        <Form className="addnewjobpage">
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Centre</label>
                    <div className="tom-select">
                        <Listbox value={values.centreForJob} onChange={onChangeCentre} disabled={isCompletedJob}>
                            <Listbox.Button className={`ts-input ${isCompletedJob && "disabled"}`}>
                                <div className="item">{values?.centreForJob?.centreName ?? "Select a centre"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("centreForJob", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {centreData.res?.data ? (
                                            <>
                                                {centreData.res?.data.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.centreForJob?.centreName === data.centreName
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.centreId}
                                                        value={data}
                                                    >
                                                        {data?.centreName}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.centreForJob && touched.centreForJob && <div className="text-danger">{errors.centreForJob}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Scheduler</label>
                    <div className="tom-select">
                        <Listbox value={values.scheduler} onChange={onChangeScheduler} disabled={!values.centreForJob}>
                            <Listbox.Button className={`form-control ts-input ${(!values.centreForJob || isCompletedJob) && "disabled"}`}>
                                <div className="item">{values?.scheduler?.name ?? "Select a scheduler"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("scheduler", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {values?.centreForJob?.schedulerList ? (
                                            <>
                                                {values?.centreForJob?.schedulerList.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.scheduler?.name === data.scheduler?.name
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data?.schedulerId}
                                                        value={data?.scheduler}
                                                    >
                                                        {data?.scheduler?.name}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.scheduler && touched.scheduler && <div className="text-danger">{errors.scheduler}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="startTime" className="form-label">
                        Start time
                    </label>
                    <input
                        id="startTime"
                        name="startTime"
                        type="time"
                        onChange={handleChange}
                        value={`${moment(values?.startTime ?? "", "HH:mm").format("HH:mm")}`}
                        onBlur={handleBlur}
                        className={`form-control ${errors.startTime && touched.startTime && "border-danger"}`}
                        disabled={isCompletedJob}
                    />
                    {errors.startTime && touched.startTime && <div className="text-danger">{errors.startTime}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="endTime" className="form-label">
                        End time
                    </label>
                    <input
                        id="endTime"
                        name="endTime"
                        type="time"
                        onChange={handleChange}
                        value={`${moment(values?.endTime ?? "", "HH:mm").format("HH:mm")}`}
                        onBlur={handleBlur}
                        disabled={isCompletedJob}
                        className={`form-control ${errors.endTime && touched.endTime && "border-danger"}`}
                    />
                    {errors.endTime && touched.endTime && <div className="text-danger">{errors.endTime}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="noOfRelievers" className="form-label">
                        No of relievers
                    </label>
                    <input
                        id="noOfRelievers"
                        name="noOfRelievers"
                        type="number"
                        min={1}
                        onChange={handleChange}
                        value={values.noOfRelievers}
                        onBlur={handleBlur}
                        className={`form-control ${errors.noOfRelievers && touched.noOfRelievers && "border-danger"}`}
                        placeholder="Enter number of reliever for job"
                        disabled={isCompletedJob}
                    />
                    {errors.noOfRelievers && touched.noOfRelievers && <div className="text-danger">{errors.noOfRelievers}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                    <label htmlFor="startDate" className="form-label">
                        Date
                    </label>
                    <CustomDatePicker
                        id="startDate"
                        name="startDate"
                        minDate={new Date(moment().format("yyyy-MM-DD"))}
                        selected={values.startDate ? new Date(moment(values.startDate).format("yyyy-MM-DD")) : null}
                        hasError={Boolean(errors.startDate && touched.startDate)}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onChangeEffect={resetSelectedReliever}
                        disabled={isCompletedJob}
                    />
                    {errors.startDate && touched.startDate && <div className="text-danger">{errors.startDate}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Age group</label>

                    <div className="tom-select">
                        <Listbox value={values.ageGroupForJob} onChange={onChangeAgeGroup} disabled={isCompletedJob}>
                            <Listbox.Button className={`ts-input ${isCompletedJob && "disabled"}`}>
                                <div className="item">{values?.ageGroupForJob?.name ?? "Select a age group"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("ageGroupForJob", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {ageGroups ? (
                                            <>
                                                {ageGroups.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values.ageGroupForJob?.name === data.name ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={data.ageGroupId}
                                                        value={data}
                                                    >
                                                        {data?.name}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>

                    {errors.ageGroupForJob && touched.ageGroupForJob && <div className="text-danger">{errors.ageGroupForJob}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Position</label>
                    <div className="tom-select">
                        <Listbox value={values.positionForJob} onChange={onChangePosition} disabled={isCompletedJob}>
                            <Listbox.Button className={`ts-input ${isCompletedJob && "disabled"}`}>
                                <div className="item">{values?.positionForJob?.name ?? "Select a position"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("positionForJob", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {positions ? (
                                            <>
                                                {positions.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.positionForJob?.name === data.name ? "optionColor" : null
                                                            }`
                                                        }
                                                        key={data.positionId}
                                                        value={data}
                                                    >
                                                        {data.name}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.positionForJob && touched.positionForJob && <div className="text-danger">{errors.positionForJob}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Break</label>
                    <div className="tom-select">
                        <Listbox value={values.breakForJob} onChange={onChangeBreak} disabled={isCompletedJob}>
                            <Listbox.Button className={`ts-input ${isCompletedJob && "disabled"}`}>
                                <div className="item">{values?.breakForJob?.breakTime ?? "Select a break"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("breakForJob", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {breaks ? (
                                            <>
                                                {breaks.map((data) => (
                                                    <Listbox.Option
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                active || values?.breakForJob?.breakTime === data.breakTime
                                                                    ? "optionColor"
                                                                    : null
                                                            }`
                                                        }
                                                        key={data.breakId}
                                                        value={data}
                                                    >
                                                        {data?.breakTime}
                                                    </Listbox.Option>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.breakForJob && touched.breakForJob && <div className="text-danger">{errors.breakForJob}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Repeat</label>
                    <div className="tom-select">
                        <Listbox name="repeat" value={values.repeat} onChange={onChangeRepeat} disabled={isCompletedJob}>
                            <Listbox.Button className={`ts-input ${isCompletedJob && "disabled"}`}>
                                <div className="item">{values?.repeat ?? "Select a value"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("repeat", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {repeatValue.map((ele, index) => (
                                            <Listbox.Option
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                        active || values.repeat === ele ? "optionColor" : null
                                                    }`
                                                }
                                                value={ele}
                                                key={index}
                                            >
                                                {ele}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                    {errors.repeat && touched.repeat && <div className="text-danger">{errors.repeat}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6 formField">
                    <label htmlFor="note" className="form-label">
                        Note
                    </label>
                    <textarea
                        id="note"
                        name="note"
                        onChange={handleChange}
                        value={values?.note ?? ""}
                        onBlur={handleBlur}
                        disabled={isCompletedJob}
                        className={`form-control ${errors.note && touched.note && "border-danger"}`}
                        placeholder="Enter any note if you want"
                    />
                    {errors.note && touched.note && <div className="text-danger">{errors.note}</div>}
                </div>
                {values?.repeat === repeatValue[1] || values?.repeat === repeatValue[2] ? (
                    <>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <label className="mb-2 form-label">Days</label>
                            <div className="ts-control tom-select w-full ">
                                <Select
                                    isMulti
                                    isDisabled={isCompletedJob}
                                    name="daysOfWeek"
                                    value={values.daysOfWeek}
                                    options={days}
                                    isOptionDisabled={(option) => Boolean(disabledDays?.includes(option.value))}
                                    onBlur={() => setFieldTouched("daysOfWeek", true)}
                                    onChange={onChangeDayOfWeek}
                                    className={`form-control ${errors.daysOfWeek && touched.daysOfWeek && "border-danger"}`}
                                    classNamePrefix="select"
                                />
                            </div>
                            {errors.daysOfWeek && touched.daysOfWeek && <div className="text-danger">{errors.daysOfWeek}</div>}
                        </div>
                    </>
                ) : null}
                {values?.repeat === repeatValue[2] ? (
                    <div className="intro-y col-span-12 sm:col-span-6 datePicker">
                        <label htmlFor="endDate" className="form-label">
                            End date
                        </label>
                        <CustomDatePicker
                            id="endDate"
                            name="endDate"
                            minDate={new Date(moment(values.startDate).format("YYYY-MM-DD"))}
                            selected={values.endDate ? new Date(values.endDate) : null}
                            hasError={Boolean(errors.endDate && touched.endDate)}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            onChangeEffect={resetSelectedReliever}
                        />
                        {errors.endDate && touched.endDate && <div className="text-danger">{errors.endDate}</div>}
                    </div>
                ) : null}
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Select reliever</label>
                    <div className="form-switch flex justify-center flex-col" style={{ height: "37px" }}>
                        <input
                            id="relieverSelection"
                            name="relieverSelection"
                            onChange={() => onChangeRelieverSelection(!values.relieverSelection)}
                            checked={values?.relieverSelection ?? false}
                            className={"form-check-input"}
                            type="checkbox"
                            disabled={isCompletedJob}
                        />
                    </div>
                </div>
                {values.relieverSelection ? (
                    <div className="intro-y col-span-6 sm:col-span-6 w-full flex flex-col justify-start md:justify-around">
                        <div className="flex w-full">
                            <div className="mr-2 flex">
                                <input
                                    id="inFavorite"
                                    name="inFavorite"
                                    onChange={() => onChangeInFavorite(!values.inFavorite)}
                                    checked={values.inFavorite}
                                    className={"form-check-input"}
                                    type="checkbox"
                                    disabled={isCompletedJob}
                                />
                                <label className="ml-2 form-label">Favourite</label>
                            </div>
                            <div className="mr-2 flex">
                                <input
                                    id="inDistrict"
                                    name="inDistrict"
                                    onChange={() => onChangeInDistrict(!values.inDistrict)}
                                    checked={values.inDistrict}
                                    className={"form-check-input"}
                                    type="checkbox"
                                    disabled={isCompletedJob}
                                />
                                <label className="ml-2 form-label">In same district </label>
                            </div>
                            <div className="mr-2 flex">
                                <input
                                    id="sendSpecific"
                                    name="sendSpecific"
                                    onChange={() => onChangeSendSpecific()}
                                    checked={values.sendSpecific}
                                    className={"form-check-input"}
                                    type="checkbox"
                                    disabled={isCompletedJob}
                                />
                                <label className="ml-2 form-label">Send specific</label>
                            </div>
                        </div>
                        {errors.relieverSelection && touched.relieverSelection && (
                            <div className="text-danger">{errors.relieverSelection}</div>
                        )}
                    </div>
                ) : null}
                {values.sendSpecific ? (
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <label className="mb-2 form-label">Reliever</label>
                        <div className="ts-control tom-select w-full ">
                            {relieverData?.res?.data ? (
                                <Select
                                    isMulti
                                    name="reliever"
                                    isDisabled={isCompletedJob}
                                    noOptionsMessage={() => "No records"}
                                    options={relieverData?.res?.data}
                                    value={values?.reliever}
                                    onBlur={() => setFieldTouched("reliever", true)}
                                    onChange={(selectedItem: MultiValue<RelieverInterface>) => {
                                        setFieldValue("reliever", selectedItem);
                                    }}
                                    className={`form-control ${errors.reliever && touched.reliever && "border-danger"}`}
                                    classNamePrefix="select"
                                    getOptionLabel={(option: RelieverForSendSpecific) =>
                                        option.jobs && option.jobs?.length > 0 ? option.name + "*" : option.name
                                    }
                                    getOptionValue={(option) => option.name}
                                />
                            ) : null}
                        </div>
                        {errors.reliever && touched.reliever && <div className="text-danger">{errors.reliever}</div>}
                    </div>
                ) : null}
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label className="mb-2 form-label">Notify relievers</label>
                    <div className="form-switch flex justify-center flex-col" style={{ height: "37px" }}>
                        <input
                            id="notifyRelievers"
                            name="notifyRelievers"
                            onChange={() => setFieldValue("notifyRelievers", !values.notifyRelievers)}
                            checked={values?.notifyRelievers ?? false}
                            className={"form-check-input"}
                            type="checkbox"
                            disabled={isCompletedJob}
                        />
                    </div>
                </div>
                {!isCompletedJob ? (
                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                        <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                            Reset
                        </button>
                        <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                            Save
                        </button>
                    </div>
                ) : null}
            </div>
        </Form>
    );
};

export default AddEditJob;
