import Modal from "./Modal";
import { StateInterface, UploadModalInterface } from "../../helper/interface";
import { useDropzone } from "react-dropzone";
import { XCircle } from "react-feather";
import { useSelector } from "react-redux";
import Loading from "../../container/Loading";

const FileUploadModal = ({ isOpen, handleCancel, files, setFiles, onSubmit, onReset, subTitle }: UploadModalInterface) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    return (
        <Modal isOpen={isOpen}>
            <>
                <div className="modal-header">
                    <h2 className="font-medium text-base w-full mr-auto">{subTitle}</h2>
                </div>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <div className="p-3">
                        {files && files[0]?.name ? (
                            <div className="p-3 flex flex-row justify-center items-center">
                                <div className="">{files[0].name}</div>
                                <div className="cursor-pointer text-danger ml-2">
                                    <XCircle width={"15px"} onClick={onReset} />
                                </div>
                            </div>
                        ) : (
                            <form data-single="true" className="dropzone" {...getRootProps()}>
                                <div className="fallback">
                                    <input name="file" type="file" className="hidden" {...getInputProps()} />
                                </div>
                                <div className="dz-message">
                                    <div className="text-lg font-medium">Click to upload or drop a file.</div>
                                    <div className="text-slate-500">
                                        This will support <span className="font-medium">XLSX</span> files only.
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                )}
                <div className="px-5 pb-8 modal-footer text-center">
                    <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary w-24 mr-3" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary w-24" onClick={onSubmit} disabled={loading}>
                        Submit
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default FileUploadModal;
