import { Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../store/slice/BaseSlice";
import { ReportFormInterface, ReportResponseInterface } from "../helper/interface";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { AxiosError } from "axios";
import { handleCatchResponse, serializeQueryParams } from "../helper/helper";

export const onGetReport = async (
    payload: ReportFormInterface,
    dispatch: Dispatch,
    setReportDetails: React.Dispatch<React.SetStateAction<ReportResponseInterface | null>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.get(`${api.admin.report}?${serializeQueryParams(payload)}`);

        const res = response.data;

        setReportDetails(res.data);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
