import Modal from "./Modal";
import { DocumentStateInterface, DocumentUploadInterface, StateInterface } from "../../helper/interface";
import { useDropzone } from "react-dropzone";
import { XCircle } from "react-feather";
import { useSelector } from "react-redux";
import Loading from "../../container/Loading";
import { Tooltip } from "react-tooltip";
import CustomDatePicker from "../datepicker/CustomDatePicker";
import { Listbox, Transition } from "@headlessui/react";
import { DocumentType } from "../../helper/constant";

const DocumentUpload = ({ isOpen, handleCancel, files, setFiles, onSubmit, subTitle }: DocumentUploadInterface) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    const onChangeFile = (acceptedFiles: DocumentStateInterface[]) => {
        setFiles([...files, ...acceptedFiles]);
    };

    const onCancelFile = (index: number) => {
        const temp = [...files];

        temp.splice(index, 1);

        setFiles(temp);
    };

    const onChangeExpire = (index: number, expiryDate: Date | null) => {
        const temp = [...files];
        temp[index] = { ...temp[index], expiryDate };

        setFiles(temp);
    };

    const onChangeDocumentType = (index: number, documentType: DocumentType) => {
        const temp = [...files];
        temp[index] = { ...temp[index], documentType };

        setFiles(temp);
    };

    const onChangePrivate = (index: number, isPrivate: boolean) => {
        const temp = [...files];
        temp[index] = { ...temp[index], isPrivate };

        setFiles(temp);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "text/csv": [".csv"],
            "application/msword": [".doc"],
            "application/pdf": [".pdf"],
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
        onDrop: (acceptedFiles) => {
            onChangeFile(
                acceptedFiles.map((file) => {
                    return {
                        document: Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        }),
                        documentType: DocumentType.ACADEMIC_TRANSCRIPTS,
                        isPrivate: false,
                        expiryDate: null,
                    };
                })
            );
        },
    });

    return (
        <Modal isOpen={isOpen}>
            <>
                <div className="modal-header">
                    <h2 className="font-medium text-base w-full mr-auto">{subTitle}</h2>
                </div>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <div className="p-3">
                        <form data-single="true" className="dropzone" {...getRootProps()}>
                            <div className="fallback">
                                <input name="file" type="file" className="hidden" {...getInputProps()} />
                            </div>
                            <div className="dz-message">
                                <div className="text-lg font-medium">Click to upload or drop a file.</div>
                                <div className="text-slate-500">
                                    This will support <span className="font-medium">PNG, DOC, PDF, JPG, JPEG</span> files only.
                                </div>
                            </div>
                        </form>
                        <Tooltip id="simpleTooltipForDocumentName" place="top" />
                        {Array.isArray(files) && files.length > 0 ? (
                            <>
                                <table className="document-upload-table">
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th>Document Type</th>
                                            <th>Expiry Date</th>
                                            <th>Private</th>
                                            <th>Action</th>
                                        </tr>
                                        {files.map((ele, index) => (
                                            <tr key={index} className="intro-y">
                                                <td
                                                    className="documentName min-w-36"
                                                    data-tooltip-id="simpleTooltipForDocumentName"
                                                    data-tooltip-content={ele.document.name}
                                                >
                                                    {ele.document.name}
                                                </td>
                                                <td className="document-type-selection">
                                                    <div className="tom-select">
                                                        <Listbox
                                                            value={files[index].documentType}
                                                            onChange={(value: DocumentType) => {
                                                                onChangeDocumentType(index, value);
                                                            }}
                                                            disabled={loading}
                                                        >
                                                            <Listbox.Button className="form-control ts-input">
                                                                <div className="item">
                                                                    {files[index].documentType || "Select document type"}
                                                                </div>
                                                            </Listbox.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0"
                                                            >
                                                                <Listbox.Options className="ts-dropdown single w-full">
                                                                    <div role="listbox" className="ts-dropdown-content">
                                                                        {Object.values(DocumentType).map((data) => (
                                                                            <Listbox.Option
                                                                                className={({ active }) =>
                                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                                        active || files[index].documentType === data
                                                                                            ? "optionColor"
                                                                                            : null
                                                                                    }`
                                                                                }
                                                                                key={data}
                                                                                value={data}
                                                                            >
                                                                                {data || "Select document type"}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </div>
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </Listbox>
                                                    </div>
                                                </td>
                                                <td className="input min-w-36">
                                                    <CustomDatePicker
                                                        id="expiryDate"
                                                        name="expiryDate"
                                                        selected={files[index].expiryDate ? files[index].expiryDate : null}
                                                        customChangeFunction={onChangeExpire}
                                                        metaData={index}
                                                    />
                                                </td>
                                                <td className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="isPrivate"
                                                        checked={ele.isPrivate}
                                                        onChange={() => onChangePrivate(index, !ele.isPrivate)}
                                                    />
                                                </td>
                                                <td className="cursor-pointer text-danger ml-2">
                                                    <XCircle width={"20px"} onClick={() => onCancelFile(index)} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : null}
                    </div>
                )}
                <div className="px-5 pb-8 modal-footer text-center">
                    <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary w-24 mr-3" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary w-24" onClick={onSubmit} disabled={loading}>
                        Submit
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default DocumentUpload;
