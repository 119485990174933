import { Formik } from "formik";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { addOrEditJobValidation } from "../../helper/constant";
import { AddEditJobInterface, StateInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import usePathName from "../../hooks/usePathName";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import AddEditJob from "./AddEditJob";
import { onEditJob } from "../../service/job";
import { JobPayloadFn } from "./Utils";

const EditJob: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { jobId = "" } = useParams();

    const { res, loading, error } = useFetch<AddEditJobInterface>(`${api.admin.job}/${jobId}`);

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    usePathName([
        { pathName: "Job", href: `/job/${tableData.tabFor}` },
        { pathName: "Edit", href: `/job/edit/${jobId}` },
    ]);

    const onSubmit = (values: AddEditJobInterface) => {
        const payload = JobPayloadFn(values);
        onEditJob(payload, +jobId, dispatch, navigate, tableData.tabFor);
    };

    const initialValues = res?.data;

    useEffect(() => {
        const startTimeValue = new Date(res?.data?.startDate ?? "");

        const startTime = startTimeValue.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

        const endTimeValue = new Date(res?.data?.endDate ?? "");

        const endTime = endTimeValue.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

        if (initialValues) {
            initialValues.startTime = startTime;
            initialValues.endTime = endTime;
        }
    }, [res]);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate(`/job/${tableData.tabFor}`)} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit job</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addOrEditJobValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditJob {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditJob;
