import React, { useState } from "react";
import { Formik } from "formik";
import usePathName from "../../hooks/usePathName";
import ReportForm from "./ReportForm";
import { ReportFormFields, ReportFormInterface, ReportResponseInterface } from "../../helper/interface";
import { onGetReport } from "../../service/getReport";
import { useDispatch } from "react-redux";
import { reportValidation } from "../../helper/constant";
import moment from "moment";

const initialValue: ReportFormInterface = {
    fieldName: ReportFormFields.scheduler,
    schedulerId: null,
    relieverId: null,
    reliever: null,
    scheduler: null,
    from: "",
    to: "",
};

const Report = () => {
    const dispatch = useDispatch();

    const [reportDetails, setReportDetails] = useState<ReportResponseInterface | null>(null);

    usePathName([{ pathName: "Report", href: "/report" }]);

    const onReportSubmit = (reportValue: ReportFormInterface) => {
        const reportSearch: ReportFormInterface = {
            from: moment(reportValue.from)?.startOf("day").toISOString(),
            to: moment(reportValue.to)?.endOf("day").toISOString(),
        };

        if (reportValue?.scheduler) reportSearch.schedulerId = reportValue.scheduler.schedulerId;
        if (reportValue?.reliever) reportSearch.relieverId = reportValue.reliever.relieverId;

        onGetReport(reportSearch, dispatch, setReportDetails);
    };

    return (
        <>
            <div className="intro-y box p-5 sm:py-10 mt-5">
                <Formik initialValues={initialValue} enableReinitialize validationSchema={reportValidation} onSubmit={onReportSubmit}>
                    {(props) => <ReportForm {...props} setReportDetails={setReportDetails} />}
                </Formik>
            </div>

            <div className="flex w-full justify-between mt-5 gap-5 flex-col sm:flex-row relative z-10">
                {reportDetails ? (
                    <>
                        <div className="intro-y box flex-1">
                            <div className="border-b border-slate-200/60 p-5">
                                <h1 className="font-medium text-base">
                                    {reportDetails?.scheduler && "Scheduler Details"} {reportDetails?.reliever && "Reliever Details"}
                                </h1>
                            </div>
                            <div className="form-inline p-5 flex flex-col items-start">
                                <div>
                                    <label htmlFor="horizontal-form-1" className="mr-2">
                                        Name:
                                    </label>
                                    <h1 className="text-base font-medium inline">
                                        {reportDetails?.scheduler && reportDetails.scheduler.name}
                                        {reportDetails?.reliever && reportDetails.reliever.name}
                                    </h1>
                                </div>

                                <div className="mt-5">
                                    <label htmlFor="horizontal-form-1" className="mr-2">
                                        Email:
                                    </label>
                                    <h1 className="text-base font-medium inline">
                                        {reportDetails?.scheduler && reportDetails.scheduler.email}
                                        {reportDetails?.reliever && reportDetails.reliever.email}
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y box flex-1">
                            <div className="border-b border-slate-200/60 p-5">
                                <h1 className="font-medium text-base">Details</h1>
                            </div>
                            <div className="form-inline p-5 flex flex-col items-start">
                                {reportDetails?.scheduler && (
                                    <div>
                                        <label htmlFor="horizontal-form-1" className="mr-2">
                                            Total billable amount:
                                        </label>
                                        <h1 className="text-base font-medium inline">{reportDetails.totalAmountToBill}</h1>
                                    </div>
                                )}

                                {reportDetails?.reliever && (
                                    <div>
                                        <label htmlFor="horizontal-form-1" className="mr-2">
                                            Total payable amount:
                                        </label>
                                        <h1 className="text-base font-medium inline">{reportDetails.totalAmountToPay}</h1>
                                    </div>
                                )}

                                <div className="mt-5">
                                    <label htmlFor="horizontal-form-1" className="mr-2">
                                        Total hours:
                                    </label>
                                    <h1 className="text-base font-medium inline">{reportDetails?.totalHours}</h1>
                                </div>

                                <div className="mt-5">
                                    <label htmlFor="horizontal-form-1" className="mr-2">
                                        Total completed job:
                                    </label>
                                    <h1 className="text-base font-medium inline">{reportDetails?.totalNoOfCompletedJobs}</h1>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default Report;
