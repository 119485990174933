import { Form } from "formik";
import { useSelector } from "react-redux";
import { BreakInterface, FormInterface, StateInterface } from "../../helper/interface";

const AddEditBreak = ({ errors, touched, handleChange, values, handleBlur, dirty }: FormInterface<BreakInterface>) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Form>
            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="breakTime" className="form-label">
                        Break time
                    </label>
                    <input
                        id="breakTime"
                        name="breakTime"
                        type="text"
                        autoFocus
                        onChange={handleChange}
                        value={values.breakTime}
                        onBlur={handleBlur}
                        className={`form-control ${errors.breakTime && touched.breakTime && "border-danger"}`}
                        placeholder="30 min"
                    />
                    {errors.breakTime && touched.breakTime && <div className="text-danger">{errors.breakTime}</div>}
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <label htmlFor="time" className="form-label">
                        Time
                    </label>
                    <div className="input-group">
                        <input
                            id="time"
                            name="time"
                            type="number"
                            onChange={handleChange}
                            min={0}
                            value={values.time}
                            onBlur={handleBlur}
                            className={`form-control ${errors.time && touched.time && "border-danger"}`}
                            placeholder="30"
                        />
                        <div className="input-group-text">Minutes</div>
                    </div>
                    {errors.time && touched.time && <div className="text-danger">{errors.time}</div>}
                </div>
                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                        Reset
                    </button>
                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                        Save
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default AddEditBreak;
