import React from "react";
import Modal from "./Modal";
import { ApprovalUserModalInterface, StateInterface } from "../../helper/interface";
import { CheckCircle } from "react-feather";
import Loading from "../../container/Loading";
import { useSelector } from "react-redux";

const ApprovalUserModal = ({ isOpen, handleCancel, onOk }: ApprovalUserModalInterface) => {
    const loading = useSelector((state: StateInterface) => state.base.loading);

    return (
        <Modal isOpen={isOpen}>
            <>
                {loading ? (
                    <Loading forTable={false} />
                ) : (
                    <>
                        <div className="p-5 text-center">
                            <CheckCircle className="w-16 h-16 text-success mx-auto mt-3" />
                            <div className="text-3xl mt-5">Are you sure?</div>
                            <div className="text-slate-500 mt-2">After approve the user. This process cannot be undone.</div>
                        </div>
                        <div className="px-5 pb-8 text-center">
                            <button
                                type="button"
                                data-tw-dismiss="modal"
                                className="btn btn-outline-secondary w-24 mr-3"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary w-24" onClick={onOk}>
                                Approve
                            </button>
                        </div>
                    </>
                )}
            </>
        </Modal>
    );
};

export default ApprovalUserModal;
