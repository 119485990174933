import { Dispatch } from "@reduxjs/toolkit";
import {
    ActivateDeactivateRelieverInterface,
    ActivateDeactivateSchedulerInterface,
    AddEditRelieverInterface,
    DocumentStateInterface,
    ResponseInterface,
    SchedulerInterface,
} from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { FormikState } from "formik";
import { setLoading } from "../store/slice/BaseSlice";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";
import { ApprovalTypes } from "../helper/constant";

const backendApi = process.env.REACT_APP_BACKEND_API;

export const onAddScheduler = async (
    data: SchedulerInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<SchedulerInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            name: data.name,
            email: data.email,
            profileImg: data.profileImg,
            dob: data.dob,
            mobileNumber: data.mobileNumber,
            centre: data.centre?.map((ele) => ele.centreId),
        };

        const response = await dataService.post(api.admin.addScheduler, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res: Required<ResponseInterface<SchedulerInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/user/scheduler/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/user/scheduler/edit/${res.data.schedulerId}`,
        };

        resetForm();

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/user/scheduler");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onAddReliever = async (
    data: AddEditRelieverInterface,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    resetForm: (nextState?: Partial<FormikState<AddEditRelieverInterface>>) => void
) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            position: data.position?.positionId,
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber,
            region: data.region?.regionId,
            suburb: data.suburb?.suburbId,
            district: data.district?.districtId,
            wage: data.wage,
            registrationNo: data.registrationNo,
            expiryOfRegistration: data.expiryOfRegistration,
            aidCertificate: data.aidCertificate,
            expiryOfAidCertificate: data.expiryOfAidCertificate,
            streetAddress: data?.streetAddress || null,
            addressLine: data?.addressLine || null,
            postalCode: data?.postalCode,
            dob: data.dob,
            about: data?.about,
            grade: data?.grade,
            profileImg: data?.profileImg,
            unavailability: data?.unavailability,
            academicTranscripts: data?.academicTranscripts,
            signUpPlatform: "Admin",
            portrait: data?.portrait,
            cv: data?.cv,
            workingEligibility: data?.workingEligibility,
            primaryProofType: data?.primaryProofType,
            primaryProofFile: data?.primaryProofFile,
            secondaryProofType: data?.secondaryProofType,
            secondaryProofFile: data?.secondaryProofFile,
            workingHoursLetter: data?.workingHoursLetter,
            ...(data?.IRDNumber ? { IRDNumber: data?.IRDNumber } : {}),
            IRDNumber: data?.IRDNumber,
            IRDFile: data?.IRDFile,
            account: data?.account ? data?.account.replace(/-/g, "") : "",
            bank: data?.bank,
            expiryOfPrimaryProofType: data?.expiryOfPrimaryProofType,
            expiryOfSecondaryProofType: data?.expiryOfSecondaryProofType,
        };

        const response = await dataService.post(api.admin.addReliever, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res: Required<ResponseInterface<AddEditRelieverInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/user/reliever/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/user/reliever/edit/${res.data.relieverId}`,
        };

        resetForm();

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/user/reliever");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditScheduler = async (data: SchedulerInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            dob: data.dob,
            email: data.email,
            mobileNumber: data.mobileNumber,
            name: data.name,
            profileImg: data?.profileImg,
            centre: data.centre?.map((ele) => ele.centreId),
        };

        const response = await dataService.post(`${api.admin.editScheduler}/${data.schedulerId}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res: Required<ResponseInterface<SchedulerInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/user/scheduler/edit/${res.data.schedulerId ?? data.schedulerId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);
        navigate("/user/scheduler");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onEditReliever = async (data: AddEditRelieverInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            position: data.position?.positionId,
            name: data.name,
            email: data.email,
            mobileNumber: data.mobileNumber,
            region: data.region?.regionId,
            suburb: data.suburb?.suburbId,
            district: data.district?.districtId,
            registrationNo: data.registrationNo,
            expiryOfRegistration: data.expiryOfRegistration,
            aidCertificate: data.aidCertificate,
            expiryOfAidCertificate: data.expiryOfAidCertificate,
            streetAddress: data?.streetAddress || null,
            addressLine: data?.addressLine || null,
            postalCode: data?.postalCode,
            wage: data.wage,
            dob: data.dob,
            about: data?.about,
            grade: data?.grade,
            profileImg: data?.profileImg,
            unavailability: data?.unavailability,
            academicTranscripts: data?.academicTranscripts,
            portrait: data?.portrait,
            cv: data?.cv,
            workingEligibility: data?.workingEligibility,
            primaryProofType: data?.primaryProofType,
            primaryProofFile: data?.primaryProofFile,
            secondaryProofType: data?.secondaryProofType,
            secondaryProofFile: data?.secondaryProofFile,
            workingHoursLetter: data?.workingHoursLetter,
            IRDNumber: data?.IRDNumber,
            IRDFile: data?.IRDFile,
            bank: data?.bank,
            expiryOfPrimaryProofType: data?.expiryOfPrimaryProofType,
            expiryOfSecondaryProofType: data?.expiryOfSecondaryProofType,
            account: data?.account ? data?.account.replace(/-/g, "") : "",
        };

        const response = await dataService.post(`${api.admin.editReliever}/${data.relieverId}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res: Required<ResponseInterface<AddEditRelieverInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/user/reliever/edit/${res.data.relieverId ?? data.relieverId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);
        navigate("/user/reliever");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteScheduler = async (
    schedulerId: number,
    dispatch: Dispatch,
    reload: () => void,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteScheduler}/${schedulerId}`);

        const res = response.data;

        successToast(res.message);

        setIsOpen(false);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onDeleteReliever = async (
    relieverId: number,
    dispatch: Dispatch,
    reload: () => void,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.reliever}/${relieverId}`);

        const res = response.data;

        successToast(res.message);

        setIsOpen(false);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onActivateDeactivateScheduler = async (data: ActivateDeactivateSchedulerInterface, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.activateDeactivateScheduler}/${data.schedulerId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onApproveDeclineUser = async (id: number, status: ApprovalTypes, dispatch: Dispatch, reload: () => void, endPoint: string) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.patch(`${endPoint}/${id}`, { approval: status });

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onActivateDeactivateReliever = async (data: ActivateDeactivateRelieverInterface, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.reliever}/${data.relieverId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onSchedulerBulkUpload = async (data: File, dispatch: Dispatch, onSuccess: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            users: data,
        };

        const response = await dataService.post(`${api.admin.schedulerBulkUpload}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res = response.data;

        if (res?.data) {
            const fileUrl = backendApi + res.data;

            window.open(fileUrl, "_blank");
        }

        successToast(res.message);

        onSuccess();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onRelieverBulkUpload = async (data: File, dispatch: Dispatch, onSuccess: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            users: data,
        };

        const response = await dataService.post(`${api.admin.relieverBulkUpload}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res = response.data;

        if (res?.data) {
            const fileUrl = backendApi + res.data;

            window.open(fileUrl, "_blank");
        }

        successToast(res.message);

        onSuccess();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onRelieverSampleFileDownload = async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.get(`${api.admin.getSampleXlsxReliever}`);

        const res = response.data;

        const fileUrl = backendApi + res?.data;

        window.open(fileUrl, "_blank");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onSchedulerSampleFileDownload = async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.get(`${api.admin.getSampleXlsxScheduler}`);

        const res = response.data;

        const fileUrl = backendApi + res?.data;

        window.open(fileUrl, "_blank");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const uploadDocument = async (data: DocumentStateInterface[], relieverId: number, dispatch: Dispatch, success: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            documents: data.map((ele) => ele.document),
            expiryDate: data.map((ele) => ele.expiryDate),
            isPrivate: data.map((ele) => ele.isPrivate),
            relieverId,
        };

        const response = await dataService.post(`${api.admin.relieverDocuments}/${relieverId}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const res = response.data;

        successToast(res.message);

        success();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onDeleteDocument = async (id: number, dispatch: Dispatch, onSuccess: () => void) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteDocument}/${id}`);

        const res = response.data;

        successToast(res.message);

        onSuccess();
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
