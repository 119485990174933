import { useState, useCallback } from "react";
import { Edit, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { api } from "../../config/Api";
import { AgeGroupInterface, ActivateDeactivateAgeGroup, AgeGroupTableInterface } from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { useDispatch } from "react-redux";
import SearchForm from "../table/SearchForm";
import DeleteModal from "../modal/DeleteModal";
import { onActivateDeactivateAgeGroup, onDeleteAgeGroup } from "../../service/ageGroup";
import { Tooltip } from "react-tooltip";

const AgeGroup = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [ageGroup, setAgeGroup] = useState<AgeGroupInterface | null>(null);
    const dispatch = useDispatch();
    usePathName([{ pathName: "Age group", href: "/age-group" }]);

    const ageGroupTable = useTable<AgeGroupTableInterface>("ageGroup", api.admin.getAgeGroup);

    const onDeleteClick = useCallback((record: AgeGroupInterface) => {
        setAgeGroup(record);
        setIsOpen(true);
    }, []);

    const ageGroupCols = [
        {
            title: "#",
            key: "ageGroupId",
            render: (record: AgeGroupTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "AGE GROUP",
            key: "name",
            sortable: true,
            render: (record: AgeGroupTableInterface) => {
                return <span>{record.name}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: AgeGroupTableInterface) => {
                const payload = {
                    ageGroupId: record.ageGroupId,
                    isActive: !record.isActive,
                };
                return (
                    <div className="form-switch form-check flex justify-center flex-col">
                        <input
                            id="isActive"
                            name="isActive"
                            onChange={() => onActivateDeactivate(payload)}
                            checked={record.isActive}
                            className={"form-check-input"}
                            type="checkbox"
                        />
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: AgeGroupTableInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDeleteAgeGroup" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteAgeGroup"
                                data-tooltip-content={"Edit age group"}
                                onClick={() => navigate(`/age-group/edit/${record.ageGroupId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                Edit
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteAgeGroup"
                                data-tooltip-content={"Delete age group"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onDelete = () => {
        if (ageGroup?.ageGroupId) {
            onDeleteAgeGroup(ageGroup.ageGroupId, dispatch, ageGroupTable.fetchApi);
            setIsOpen(false);
        }
    };

    const onActivateDeactivate = (payload: ActivateDeactivateAgeGroup) => {
        onActivateDeactivateAgeGroup(payload, dispatch, ageGroupTable.fetchApi);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this age group?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={ageGroupCols}
                    data={ageGroupTable.tableData ?? []}
                    tableKey="ageGroupId"
                    reload={ageGroupTable.fetchApi}
                    currentOrder={ageGroupTable.currentOrder}
                    error={ageGroupTable.error}
                    loading={ageGroupTable.loading}
                    tableOffset={ageGroupTable.tableOffset}
                    onSetOrderBy={ageGroupTable.onSetOrderBy}
                    component={
                        <>
                            <SearchForm
                                resetSearch={ageGroupTable.resetSearch}
                                onSearch={ageGroupTable.onSearch}
                                searchPlaceHolder="Search by age group"
                            />
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltipAgeGroup" place="top" />
                                <button
                                    className="btn btn-outline-primary w-full md:w-1/2 sm:w-auto"
                                    data-tooltip-id="simpleTooltipAgeGroup"
                                    data-tooltip-content="Add new age group"
                                    onClick={() => {
                                        navigate("/age-group/add");
                                    }}
                                >
                                    Add new
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default AgeGroup;
