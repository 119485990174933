import React from "react";
import { Form, FormikProps } from "formik";
import moment from "moment";
import { Listbox, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import CustomDatePicker from "../components/datepicker/CustomDatePicker";
import { AvailabilityInterface, PositionInterface, StateInterface } from "../helper/interface";
import useFetch from "../hooks/useFetch";
import { api } from "../config/Api";
import { RelieverStatus } from "../helper/constant";

const AvailabilityForm = ({
    values,
    setFieldTouched,
    setFieldValue,
    resetForm,
    errors,
    touched,
    dirty,
    handleBlur,
}: FormikProps<AvailabilityInterface>) => {
    const positions = useFetch<PositionInterface[]>(`${api.admin.getAllPositionForSelect}`);

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const onChangePosition = (value: PositionInterface) => {
        setFieldValue("position", value);
    };

    const onRelieverStatus = (value: string) => {
        setFieldValue("status", value);
    };

    const handleResetField = () => {
        resetForm();
    };

    return (
        <Form id="tabulator-html-filter-form" className="flex items-center align-items-baseline availableForm">
            <div className="flex items-center datePicker">
                <div className="flex flex-col items-end">
                    <div className="flex items-center">
                        <label htmlFor="date" className="mb-0 mr-2 ml-4 availableFormLebel">
                            Date
                        </label>
                        <CustomDatePicker
                            id="date"
                            name="date"
                            selected={values.date ? new Date(moment(values.date).format("yyyy-MM-DD")) : null}
                            hasError={Boolean(errors.date && touched.date)}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                        />
                    </div>
                    {errors.date && touched.date && <div className="text-danger">{errors.date}</div>}
                </div>
            </div>

            <div className="flex items-center">
                <div className="flex flex-col items-end">
                    <div className="flex items-center">
                        <label className="mb-0 mr-2 ml-4 availableFormLebel">Position</label>
                        <div className="tom-select z-50">
                            <Listbox value={values.position} onChange={onChangePosition}>
                                <Listbox.Button className="form-control ts-input">
                                    <div className="item">{values?.position?.name ?? "Select a position"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    onBlur={() => setFieldTouched("position", true)}
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {positions.res?.data ? (
                                                <>
                                                    {positions.res?.data.map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || values.position?.positionId === data?.positionId
                                                                        ? "optionColor"
                                                                        : null
                                                                }`
                                                            }
                                                            key={data.positionId}
                                                            value={data}
                                                        >
                                                            {data.name}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                    {errors.position && touched.position && <div className="text-danger">{errors.position?.positionId}</div>}
                </div>
            </div>

            <div className="flex items-center">
                <div className="flex flex-col items-end">
                    <div className="flex items-center">
                        <label className="mb-0 mr-2 ml-4 availableFormLebel">Status</label>
                        <div className="tom-select z-40">
                            <Listbox value={values.status} onChange={onRelieverStatus}>
                                <Listbox.Button className="form-control ts-input">
                                    <div className="item">{values?.status ?? "Select a status"}</div>
                                </Listbox.Button>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    onBlur={() => setFieldTouched("status", true)}
                                >
                                    <Listbox.Options className="ts-dropdown single w-full">
                                        <div role="listbox" className="ts-dropdown-content">
                                            {RelieverStatus ? (
                                                <>
                                                    {RelieverStatus.map((data) => (
                                                        <Listbox.Option
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                    active || values.status === data ? "optionColor" : null
                                                                }`
                                                            }
                                                            key={data}
                                                            value={data}
                                                        >
                                                            {data}
                                                        </Listbox.Option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                        </div>
                    </div>
                    {errors.status && touched.status && <div className="text-danger">{errors.status}</div>}
                </div>
            </div>

            <div className="ml-4">
                <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={loading || !dirty}>
                    Go
                </button>
                <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    className="btn btn-secondary w-16 ml-2"
                    onClick={handleResetField}
                    disabled={!dirty}
                >
                    Reset
                </button>
            </div>
        </Form>
    );
};

export default AvailabilityForm;
