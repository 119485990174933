import React from "react";
import DatePicker from "react-datepicker";
import { CustomDatePickerProps } from "../../helper/interface";
import moment from "moment";
import CustomDatePickerHeader from "./CustomDatePickerHeader";

const CustomDatePicker = ({
    name,
    selected,
    minDate,
    maxDate,
    handleBlur,
    id,
    hasError,
    setFieldValue,
    disabled,
    customChangeFunction,
    onChangeEffect,
    metaData,
    onCalendarClose,
    onCalendarOpen,
}: CustomDatePickerProps) => {
    const onChange = (date: Date | null) => {
        if (customChangeFunction) {
            customChangeFunction(metaData ?? 0, date);
        } else if (setFieldValue) {
            setFieldValue(`${name}`, date ? moment(date).format("YYYY-MM-DD") : null);
        }

        if (onChangeEffect) {
            onChangeEffect();
        }
    };

    return (
        <DatePicker
            renderCustomHeader={(props) => <CustomDatePickerHeader {...props} />}
            id={id}
            name={name}
            selected={selected}
            onCalendarOpen={onCalendarOpen}
            onCalendarClose={onCalendarClose}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="DD/MM/YYYY"
            onBlur={handleBlur}
            dateFormat={"dd/MM/yyyy"}
            isClearable={!disabled}
            onChange={(date) => onChange(date)}
            data-single-mode="true"
            className={`form-control ${hasError && "border-danger"}`}
            disabled={disabled}
            autoComplete={"off"}
        />
    );
};

export default CustomDatePicker;
