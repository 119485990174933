import usePathName from "../hooks/usePathName";
import { FeedbackExtraInterface, FeedbackTableInterface } from "../helper/interface";
import useTable from "../hooks/useTable";
import { api } from "../config/Api";
import CustomTable from "../components/table/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftCircle } from "react-feather";

const Feedback = () => {
    const navigate = useNavigate();

    usePathName([{ pathName: "Availability", href: "/availability" }]);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const schedulerId = searchParams.get("schedulerId");
    const relieverId = searchParams.get("relieverId");

    const feedbackTable = useTable<FeedbackTableInterface, FeedbackExtraInterface>(
        "feedback",
        `${api.admin.getFeedbackList}?${schedulerId ? `schedulerId=${schedulerId}` : ""}${relieverId ? `relieverId=${relieverId}` : ""}`,
        true
    );

    const tableData = feedbackTable?.tableData;
    const response = feedbackTable?.res;

    const reliverCloumn = {
        title: "RELIEVER",
        key: "feedback",
        render: (record: FeedbackTableInterface) => {
            return <span>{record?.reliever?.name}</span>;
        },
    };

    const schedulerColumn = {
        title: "CENTRE NAME",
        key: "feedback",
        render: (record: FeedbackTableInterface) => {
            return <span>{record?.centre?.centreName}</span>;
        },
    };

    const columns = [
        {
            title: "#",
            key: "relieverId",
            render: (record: FeedbackTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        ...(!relieverId ? [reliverCloumn] : []),
        ...(!schedulerId ? [schedulerColumn] : []),
        {
            title: "RATING",
            key: "rating",
            render: (record: FeedbackTableInterface) => {
                return <span>{record.rating}</span>;
            },
        },
        {
            title: "FEEDBACK",
            key: "feedback",
            render: (record: FeedbackTableInterface) => {
                return <span>{record.feedBack}</span>;
            },
        },
    ];

    return (
        <>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={columns}
                    data={tableData ?? []}
                    tableKey="relieverId"
                    currentOrder={feedbackTable.currentOrder}
                    reload={feedbackTable.fetchApi}
                    onSetOrderBy={feedbackTable.onSetOrderBy}
                    loading={feedbackTable.loading}
                    error={feedbackTable.error}
                    tableOffset={feedbackTable.tableOffset}
                    component={
                        <>
                            <div className="flex-2 ml-4 mr-10">
                                <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate(-1)} />
                            </div>
                            {schedulerId ? (
                                <div>
                                    <div>
                                        <span>Schedular name :</span> {response?.extraData?.scheduler?.name}
                                    </div>
                                    <div>
                                        <span>Schedular email :</span> {response?.extraData?.scheduler?.email}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {relieverId ? (
                                <div>
                                    <div>
                                        <span>Reliever name :</span> {response?.extraData?.reliever?.name}
                                    </div>
                                    <div>
                                        <span>Reliever email :</span> {response?.extraData?.reliever?.email}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Feedback;
