import Routes from "./routes/Routes";
import "./assets/css/app.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer } from "react-toastify";
import "./assets/css/Index.css";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
    return (
        <>
            <ToastContainer />
            <Routes />
        </>
    );
};

export default App;
