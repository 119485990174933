import React, { RefObject, useRef } from "react";
import { AlertTriangle, Camera, HelpCircle } from "react-feather";
import Loading from "../../container/Loading";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { GlobalSettingsInterface, StateInterface } from "../../helper/interface";
import { Formik, Form } from "formik";
import { getImageUrl, maxTimeForGlobalSetting, updateGlobalSettingValidation } from "../../helper/constant";
import { onEditGlobalSetting } from "../../service/globalSetting";
import { Tooltip } from "react-tooltip";
import Logo from "../../assets/images/ArohaWhiteLogo.png";
import { useDispatch, useSelector } from "react-redux";
import usePathName from "../../hooks/usePathName";

const GlobalSetting = () => {
    const dispatch = useDispatch();
    const submitLoading = useSelector((state: StateInterface) => state.base.loading);

    usePathName([{ pathName: "Global Settings", href: "/global-settings" }]);

    const { res, loading, error, fetchApi } = useFetch<GlobalSettingsInterface>(`${api.admin.globalSettings}`);

    const onSubmit = (value: GlobalSettingsInterface) => {
        const payload: GlobalSettingsInterface = {
            createJob: value.createJob,
            cancelJob: value.cancelJob,
            updateJob: value.updateJob,
            OTPExpire: value.OTPExpire,
            OTPResend: value.OTPResend,
            withdrawJob: value.withdrawJob,
            deactivationEmail: value.deactivationEmail,
            contactUsEmail: value.contactUsEmail,
            birthday: value.birthday,
            workAnniversary: value.workAnniversary,
        };

        onEditGlobalSetting(payload, dispatch, fetchApi);
    };

    const birthdayImgRef = useRef() as RefObject<HTMLInputElement>;
    const workAnniversaryImgRef = useRef() as RefObject<HTMLInputElement>;

    const initialValues = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-1 font-medium text-center text-lg -ml-2">Global settings</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={updateGlobalSettingValidation} onSubmit={onSubmit}>
                        {({ values, handleChange, handleBlur, errors, touched, dirty, setFieldValue }) => (
                            <Form className="globalsetting-form">
                                <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForBirthday" place="top" />
                                        <label htmlFor="workAnniversary" className="form-label">
                                            Birthday cover image
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForBirthday"
                                                data-tooltip-content="Image will show as a background image in birthday card"
                                            />
                                        </label>
                                        <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                                            <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 global-setting-image image-fit relative">
                                                <img
                                                    alt="Birthday cover image"
                                                    className="img-bordered border-radius-8"
                                                    src={values?.birthday ? getImageUrl(values?.birthday) : Logo}
                                                />
                                                <div
                                                    className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary p-2 svg-bordered-white"
                                                    onClick={() => birthdayImgRef?.current?.click()}
                                                >
                                                    <Camera className={`lucide lucide-camera w-4 h-4 text-white ${"cursor-pointer"}`} />
                                                    <input
                                                        ref={birthdayImgRef}
                                                        name="birthday"
                                                        id="birthday"
                                                        type="file"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (e?.target?.files?.[0]) {
                                                                setFieldValue("birthday", e?.target?.files?.[0]);
                                                            }
                                                        }}
                                                        accept="image/*"
                                                        disabled={loading}
                                                        className="hidden"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForWorkAnniversary" place="top" />
                                        <label htmlFor="workAnniversary" className="form-label">
                                            Work anniversary cover image
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForWorkAnniversary"
                                                data-tooltip-content="Image will show as a background image in work anniversary card"
                                            />
                                        </label>
                                        <div className="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col">
                                            <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 global-setting-image image-fit relative">
                                                <img
                                                    alt="Birthday cover image"
                                                    className="img-bordered border-radius-8"
                                                    src={values?.workAnniversary ? getImageUrl(values?.workAnniversary) : Logo}
                                                />
                                                <div
                                                    className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-primary p-2 svg-bordered-white cursor-pointer"
                                                    onClick={() => workAnniversaryImgRef?.current?.click()}
                                                >
                                                    <Camera className={"lucide lucide-camera w-4 h-4 text-white"} />
                                                    <input
                                                        ref={workAnniversaryImgRef}
                                                        name="workAnniversary"
                                                        id="workAnniversary"
                                                        type="file"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            if (e?.target?.files?.[0]) {
                                                                setFieldValue("workAnniversary", e?.target?.files?.[0]);
                                                            }
                                                        }}
                                                        accept="image/*"
                                                        disabled={loading}
                                                        className="hidden"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForCancelJob" place="top" />
                                        <label htmlFor="cancelJob" className="form-label">
                                            Cancel job duration
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForCancelJob"
                                                data-tooltip-content="The minimum time before the start time after which the scheduler can't cancel any job"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="cancelJob"
                                                name="cancelJob"
                                                type="number"
                                                min={0}
                                                step="any"
                                                max={maxTimeForGlobalSetting}
                                                onChange={handleChange}
                                                value={values.cancelJob}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.cancelJob && touched.cancelJob && "border-danger"}`}
                                                placeholder="2.5"
                                            />
                                            <div className="input-group-text">Hours</div>
                                        </div>
                                        {errors.cancelJob && touched.cancelJob && <div className="text-danger">{errors.cancelJob}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForWithdrawJob" place="top" />
                                        <label htmlFor="withdrawJob" className="form-label">
                                            Withdraw job duration
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForWithdrawJob"
                                                data-tooltip-content="The minimum time before the start time after which the reliever can't withdraw any job"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="withdrawJob"
                                                name="withdrawJob"
                                                type="number"
                                                min={0}
                                                step="any"
                                                onChange={handleChange}
                                                value={values.withdrawJob}
                                                max={maxTimeForGlobalSetting}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.withdrawJob && touched.withdrawJob && "border-danger"}`}
                                                placeholder="2.5"
                                            />
                                            <div className="input-group-text">Hours</div>
                                        </div>
                                        {errors.withdrawJob && touched.withdrawJob && (
                                            <div className="text-danger">{errors.withdrawJob}</div>
                                        )}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForUpdateJob" className="custom-tooltip" place="top" />
                                        <label htmlFor="updateJob" className="form-label">
                                            Update job duration
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForUpdateJob"
                                                data-tooltip-content="The minimum time before the start time after which the scheduler can't update any job"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="updateJob"
                                                name="updateJob"
                                                type="number"
                                                min={0}
                                                step="any"
                                                onChange={handleChange}
                                                value={values.updateJob}
                                                max={maxTimeForGlobalSetting}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.updateJob && touched.updateJob && "border-danger"}`}
                                                placeholder="2.5"
                                            />
                                            <div className="input-group-text">Hours</div>
                                        </div>
                                        {errors.updateJob && touched.updateJob && <div className="text-danger">{errors.updateJob}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForCreateJob" className="custom-tooltip" place="top" />
                                        <label htmlFor="createJob" className="form-label">
                                            Create job duration
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForCreateJob"
                                                data-tooltip-content="The minimum time before the start time after which the scheduler can't create any job"
                                            />
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="createJob"
                                                name="createJob"
                                                type="number"
                                                min={0}
                                                step="any"
                                                onChange={handleChange}
                                                value={values.createJob}
                                                max={maxTimeForGlobalSetting}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.createJob && touched.createJob && "border-danger"}`}
                                                placeholder="2.5"
                                            />
                                            <div className="input-group-text">Hours</div>
                                        </div>
                                        {errors.createJob && touched.createJob && <div className="text-danger">{errors.createJob}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForContactUsEmail" className="custom-tooltip" place="top" />
                                        <label htmlFor="contactUsEmail" className="form-label">
                                            Contact us email
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForContactUsEmail"
                                                data-tooltip-content="The email on which the app queries will be received"
                                            />
                                        </label>
                                        <input
                                            id="contactUsEmail"
                                            name="contactUsEmail"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.contactUsEmail}
                                            onBlur={handleBlur}
                                            className={`form-control ${errors.contactUsEmail && touched.contactUsEmail && "border-danger"}`}
                                            placeholder="admin@arohaconnect.com"
                                        />
                                        {errors.contactUsEmail && touched.contactUsEmail && (
                                            <div className="text-danger">{errors.contactUsEmail}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForDeactivationEmail" className="custom-tooltip" place="top" />
                                        <label htmlFor="deactivationEmail" className="form-label">
                                            Deactivation email
                                            <HelpCircle
                                                className="inline w-4 ml-1"
                                                data-tooltip-id="simpleTooltipForDeactivationEmail"
                                                data-tooltip-content="The email on which the user profile deactivation alerts will be sent"
                                            />
                                        </label>
                                        <input
                                            id="deactivationEmail"
                                            name="deactivationEmail"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.deactivationEmail}
                                            onBlur={handleBlur}
                                            className={`form-control ${
                                                errors.deactivationEmail && touched.deactivationEmail && "border-danger"
                                            }`}
                                            placeholder="admin@arohaconnect.com"
                                        />
                                        {errors.deactivationEmail && touched.deactivationEmail && (
                                            <div className="text-danger">{errors.deactivationEmail}</div>
                                        )}
                                    </div>
                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForUpdateJob" className="custom-tooltip" place="top" />
                                        <label htmlFor="OTPResend" className="form-label">
                                            OTP resend time
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="OTPResend"
                                                name="OTPResend"
                                                type="number"
                                                min={0}
                                                step="any"
                                                onChange={handleChange}
                                                value={values.OTPResend}
                                                max={maxTimeForGlobalSetting}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.OTPResend && touched.OTPResend && "border-danger"}`}
                                                placeholder="2"
                                            />
                                            <div className="input-group-text">Minutes</div>
                                        </div>
                                        {errors.OTPResend && touched.OTPResend && <div className="text-danger">{errors.OTPResend}</div>}
                                    </div>

                                    <div className="intro-y col-span-12 sm:col-span-6">
                                        <Tooltip id="simpleTooltipForCreateJob" className="custom-tooltip" place="top" />
                                        <label htmlFor="createJob" className="form-label">
                                            OTP expire time
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="OTPExpire"
                                                name="OTPExpire"
                                                type="number"
                                                min={0}
                                                step="any"
                                                onChange={handleChange}
                                                value={values.OTPExpire}
                                                max={maxTimeForGlobalSetting}
                                                onBlur={handleBlur}
                                                className={`form-control ${errors.OTPExpire && touched.OTPExpire && "border-danger"}`}
                                                placeholder="2"
                                            />
                                            <div className="input-group-text">Minutes</div>
                                        </div>
                                        {errors.OTPExpire && touched.OTPExpire && <div className="text-danger">{errors.OTPExpire}</div>}
                                    </div>
                                    <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                        <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                                            Reset
                                        </button>
                                        <button
                                            className="btn btn-primary w-24 ml-2"
                                            type="submit"
                                            disabled={loading || submitLoading || !dirty}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default GlobalSetting;
