import { Dispatch } from "@reduxjs/toolkit";
import { InvoiceInterface, InvoiceTableInterface, ResponseInterface } from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { setLoading } from "../store/slice/BaseSlice";
import { api } from "../config/Api";
import { dataService } from "../config/DataService";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";

export const onAddInvoice = async (payload: InvoiceInterface, dispatch: Dispatch, navigate: NavigateFunction) => {
    try {
        dispatch(setLoading(true));
        delete payload.scheduler;
        const response = await dataService.post(api.admin.addInvoice, payload, { headers: { "Content-Type": "multipart/form-data" } });

        const res: Required<ResponseInterface<InvoiceTableInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/invoice/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/invoice/edit/${res.data.invoiceId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/invoice");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        // resetForm();
        dispatch(setLoading(false));
    }
};

export const onEditInvoice = async (data: InvoiceInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.put(`${api.admin.updateInvoice}`, data, { headers: { "Content-Type": "multipart/form-data" } });

        const res: Required<ResponseInterface<InvoiceInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/invoice/edit/${res.data.invoiceId ?? data.invoiceId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);

        navigate("/invoice");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onInvoiceDelete = async (
    invoiceId: number,
    dispatch: Dispatch,
    reload: () => void,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteInvoice}/${invoiceId}`);

        const res = response.data;

        successToast(res.message);

        setIsOpen(false);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};
