import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    BarChart2,
    Coffee,
    Zap,
    Users,
    Briefcase,
    MapPin,
    User,
    ChevronUp,
    ChevronDown,
    FilePlus,
    Settings,
    Book,
    UserCheck,
    Bell,
    Clock,
    File,
    Home,
} from "react-feather";
import Logo from "../../assets/images/ArohaWhiteLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { StateInterface } from "../../helper/interface";
import { setTableData } from "../../store/slice/BaseSlice";

const MobileMenu: FC = () => {
    const dispatch = useDispatch();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    const { pathname } = useLocation();

    useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    return (
        <>
            <div className="mobile-menu md:hidden">
                <div className="mobile-menu-bar">
                    <div className="menu__icon flex mr-auto">
                        <img alt="Aroha Connect" className="h-8" src={Logo} />
                    </div>
                    <div id="mobile-menu-toggler" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <BarChart2 className="w-8 h-8 text-white transform -rotate-90" />
                    </div>
                </div>
                <ul className={`border-t border-white/[0.08] py-5  ${isMenuOpen ? "" : "hidden"}`}>
                    <li>
                        <Link to="dashboard" className={`menu  ${pathname.includes("dashboard") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Home />
                            </div>
                            <div className="menu__title"> Dashboard </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="user/scheduler" className={`menu  ${pathname.includes("scheduler") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <User />
                            </div>
                            <div className="menu__title"> Schedulers </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="user/reliever" className={`menu  ${pathname.includes("user/reliever") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <User />
                            </div>
                            <div className="menu__title"> Relievers </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="centre" className={`menu  ${pathname.includes("centre") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <MapPin />
                            </div>
                            <div className="menu__title"> Centre </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="job/pending"
                            className={`menu ${pathname.includes("job") && "side-menu--active"}`}
                            onClick={() => {
                                dispatch(setTableData({ ...tableData, tabFor: "pending" }));
                            }}
                        >
                            <div className="menu__icon">
                                <Briefcase />
                            </div>
                            <div className="menu__title"> Jobs </div>

                            {pathname.includes("job") ? (
                                <ChevronUp aria-hidden="true" className="mr-4" />
                            ) : (
                                <ChevronDown aria-hidden="true" className="mr-4" />
                            )}
                        </Link>

                        {pathname.includes("job") && (
                            <ul
                                className="cursor-pointer menu__sub-open"
                                onClick={() => {
                                    navigate("/job/add");
                                }}
                            >
                                <div>
                                    <div className={`menu ${pathname.includes("job/add") ? "font-bold" : ""}`}>
                                        <div className="menu__icon">
                                            <FilePlus />
                                        </div>
                                        <div className="menu__title">Add new</div>
                                    </div>
                                </div>
                            </ul>
                        )}
                    </li>
                    <li>
                        <Link to="position" className={`menu ${pathname.includes("position") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Zap />
                            </div>
                            <div className="menu__title"> Position </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="age-group" className={`menu ${pathname.includes("age-group") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Users />
                            </div>
                            <div className="menu__title"> Age groups </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="break" className={`menu ${pathname.includes("break") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Coffee />
                            </div>
                            <div className="menu__title"> Breaks </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="report" className={`menu ${pathname.includes("report") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Book />
                            </div>
                            <div className="menu__title"> Reports </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="availability" className={`menu ${pathname.includes("availability") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <UserCheck />
                            </div>
                            <div className="menu__title"> Availability </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="timesheet" className={`menu ${pathname.includes("timesheet") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Clock />
                            </div>
                            <div className="menu__title"> Timesheet </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="invoice" className={`menu ${pathname.includes("invoice") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <File />
                            </div>
                            <div className="menu__title"> Invoice </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="notification" className={`menu ${pathname.includes("notification") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Bell />
                            </div>
                            <div className="menu__title"> Notification </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="global-settings" className={`menu ${pathname.includes("global-settings") && "side-menu--active"}`}>
                            <div className="menu__icon">
                                <Settings />
                            </div>
                            <div className="menu__title"> Global settings </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default MobileMenu;
