import { FC } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes as ReactRouter } from "react-router-dom";
import Centre from "../components/centre/Centre";
import Reliever from "../components/users/reliever/Reliever";
import AddScheduler from "../components/users/scheduler/AddScheduler";
import Scheduler from "../components/users/scheduler/Scheduler";
import Dashboard from "../container/Dashboard";
import Login from "../container/Login";
import Position from "../components/position/Position";
import { checkAdminAuth } from "../helper/helper";
import AddPotion from "../components/position/AddPosition";
import EditPosition from "../components/position/EditPosition";
import AgeGroup from "../components/ageGroup/AgeGroup";
import AddAgeGroup from "../components/ageGroup/AddAgeGroup";
import EditAgeGroup from "../components/ageGroup/EditAgeGroup";
import Break from "../components/break/Break";
import AddBreak from "../components/break/AddBreak";
import EditBreak from "../components/break/EditBreak";
import Location from "../components/centre/Location";
import EditScheduler from "../components/users/scheduler/EditScheduler";
import AddCentre from "../components/centre/AddCentre";
import EditCentre from "../components/centre/EditCentre";
import NotFoundPage from "../components/notFound/NotFoundPage";
import ChangePassword from "../components/changePassword/ChangePassword";
import AddReliever from "../components/users/reliever/AddReliever";
import EditReliever from "../components/users/reliever/EditReliever";
import AddJob from "../components/job/AddJob";
import Job from "../components/job/Job";
import EditJob from "../components/job/EditJob";
import JobTable from "../components/job/JobTable";
import GlobalSetting from "../components/globalSettings/GlobalSetting";
import Documents from "../components/users/reliever/Documents";
import Report from "../components/report/Report";
import Availability from "../availability/Availability";
import Feedback from "../feedback/Feedback";
import RelieverJobHours from "../components/relieverJobHours/RelieverJobHours";
import EditRelieverJobHour from "../components/relieverJobHours/EditRelieverJobHour";
import Invoice from "../components/invoice/invoice";
import AddInvoice from "../components/invoice/AddInvoice";
import EditInvoice from "../components/invoice/EditInvoice";
import Notification from "../components/notification/Notification";
import DashboardStatistics from "../components/dashboard";

export const ProtectedRoutes = () => {
    const isLogin = checkAdminAuth();
    return <>{isLogin ? <Outlet /> : <Navigate to={"/login"} />}</>;
};

const Routes: FC = () => {
    return (
        <BrowserRouter>
            <ReactRouter>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="login" element={<Login />} />
                <Route path="" element={<ProtectedRoutes />}>
                    <Route path="" element={<Dashboard />}>
                        <Route path="dashboard" element={<DashboardStatistics />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="user/scheduler" element={<Scheduler />} />
                        <Route path="user/scheduler/add" element={<AddScheduler />} />
                        <Route path="user/scheduler/edit/:schedulerId" element={<EditScheduler />} />
                        <Route path="user/reliever" element={<Reliever />} />
                        <Route path="user/reliever/documents/:relieverId" element={<Documents />} />
                        <Route path="user/reliever/add" element={<AddReliever />} />
                        <Route path="user/reliever/edit/:relieverId" element={<EditReliever />} />
                        <Route path="centre" element={<Centre />} />
                        <Route path="centre/add" element={<AddCentre />} />
                        <Route path="centre/edit/:centreId" element={<EditCentre />} />
                        <Route path="position" element={<Position />} />
                        <Route path="position/add" element={<AddPotion />} />
                        <Route path="position/edit/:positionId" element={<EditPosition />} />
                        <Route path="age-group" element={<AgeGroup />} />
                        <Route path="age-group/add" element={<AddAgeGroup />} />
                        <Route path="age-group/edit/:ageGroupId" element={<EditAgeGroup />} />
                        <Route path="break" element={<Break />} />
                        <Route path="break/add" element={<AddBreak />} />
                        <Route path="break/edit/:breakId" element={<EditBreak />} />
                        <Route path="report" element={<Report />} />
                        <Route path="centre/location" element={<Location />} />
                        <Route path="job/add" element={<AddJob />} />
                        <Route path="notification" element={<Notification />} />
                        <Route path="job/edit/:jobId" element={<EditJob />} />
                        <Route path="job" element={<Job />}>
                            <Route path=":pageType" element={<JobTable />} />
                        </Route>
                        <Route path="availability" element={<Availability />} />
                        <Route path="feedback" element={<Feedback />} />
                        <Route path="global-settings" element={<GlobalSetting />} />
                        <Route path="timesheet" element={<RelieverJobHours />} />
                        <Route path="timesheet/edit/:id" element={<EditRelieverJobHour />} />
                        <Route path="invoice" element={<Invoice />} />
                        <Route path="invoice/add" element={<AddInvoice />} />
                        <Route path="invoice/edit/:invoiceId" element={<EditInvoice />} />
                    </Route>
                </Route>
            </ReactRouter>
        </BrowserRouter>
    );
};

export default Routes;
