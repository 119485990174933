import React, { useState, useEffect, useCallback } from "react";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { useNavigate, useParams } from "react-router-dom";
import { ColInterface, JobInterface, MetaFilterInterface, StateInterface } from "../../helper/interface";
import { api } from "../../config/Api";
import { CheckSquare, Eye, Trash2 } from "react-feather";
import moment from "moment";
import usePathName from "../../hooks/usePathName";
import DeleteModal from "../modal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { onDeleteJob } from "../../service/job";
import { Tooltip } from "react-tooltip";
import { Formik } from "formik";
import Filter from "./Filter";
import { setTableData } from "../../store/slice/BaseSlice";
import RelieverModal from "../modal/RelieverModal";

const JobTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pageType = "" } = useParams();

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    const initialValues = tableData?.metaFilterData;

    const [isOpen, setIsOpen] = useState(false);
    const [isRelieverModal, setIsRelieverModal] = useState(false);
    const [job, setJob] = useState<JobInterface | null>(null);
    const [columns, setColumns] = useState<ColInterface<JobInterface>[] | null>(null);

    const { onSetPage } = usePathName([
        { pathName: "Job", href: `/job/${tableData.tabFor}` },
        { pathName: pageType.charAt(0).toUpperCase() + pageType.slice(1), href: `/job/${pageType}` },
    ]);

    const onDeleteClick = useCallback((record: JobInterface) => {
        setJob(record);
        setIsOpen(true);
    }, []);

    const jobTable = useTable<JobInterface>("job", `${api.admin.getJobs}?type=${pageType}`, true);

    const defaultColumns: ColInterface<JobInterface>[] = [
        {
            title: "#",
            key: "schedulerId",
            render: (record: JobInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "CENTRE",
            key: "centreName",
            render: (record: JobInterface) => {
                return <span>{record?.centreForJob?.centreName ?? "-"}</span>;
            },
        },
        {
            title: "SCHEDULER",
            key: "schedulerName",
            render: (record: JobInterface) => {
                return <span>{record?.scheduler?.name ?? "-"}</span>;
            },
        },
        {
            title: "POSITION",
            key: "positionName",
            render: (record: JobInterface) => {
                return <span>{record?.positionForJob?.name ?? "-"}</span>;
            },
        },
        {
            title: "BREAK",
            key: "breakTime",
            render: (record: JobInterface) => {
                return <span>{record?.breakForJob?.breakTime}</span>;
            },
        },
        {
            title: "DATE",
            key: "date",
            render: (record: JobInterface) => {
                const startDate = moment(record.startDate).format("DD/MM/YYYY"),
                    endDate = moment(record.endDate).format("DD/MM/YYYY");

                if (new Date(record.startDate).getDate() !== new Date(record.endDate).getDate()) {
                    return (
                        <span>
                            {startDate} <strong> - </strong>
                            {endDate}
                        </span>
                    );
                } else {
                    return <span>{startDate}</span>;
                }
            },
        },
        {
            title: "TIME",
            key: "time",
            render: (record: JobInterface) => {
                const startTime = new Date(record.startDate).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                });

                const endTime = new Date(record.endDate).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                });

                return <span>{startTime + " - " + endTime}</span>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: JobInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center">
                            <Tooltip id="simpleTooltipJobPending" place="top" />
                            {pageType === "completed" || pageType === "active" ? (
                                <div
                                    className="flex items-center mr-3 text-success cursor-pointer"
                                    data-tooltip-id="simpleTooltipJobPending"
                                    data-tooltip-content={"View accepted reliever"}
                                    onClick={() => {
                                        setJob(record);
                                        setIsRelieverModal(true);
                                    }}
                                >
                                    <Eye className="w-4 h-4 mr-1" /> View
                                </div>
                            ) : null}
                            <div
                                className="flex items-center mr-3 text-primary cursor-pointer"
                                data-tooltip-id="simpleTooltipJobPending"
                                data-tooltip-content={"Edit job"}
                                onClick={() => navigate(`/job/edit/${record.jobId}`)}
                            >
                                <CheckSquare className="w-4 h-4 mr-1" /> Edit
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipJobPending"
                                data-tooltip-content={"Delete job"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        setColumns(defaultColumns);

        onSetPage();

        jobTable.fetchApi();
    }, [pageType]);

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleRelieverModalCancel = () => {
        setIsRelieverModal(false);
    };

    const onDelete = () => {
        if (job?.jobId) {
            onDeleteJob(job.jobId, dispatch, jobTable.fetchApi, setIsOpen);
        }
    };

    const onMetaFilteredSubmit = (filterValues: MetaFilterInterface) => {
        const metaFilters: MetaFilterInterface = { fieldName: filterValues.fieldName };

        if (filterValues.value) {
            metaFilters.value = filterValues.value;
        }

        if (filterValues?.startDate) metaFilters.startDate = moment(filterValues.startDate)?.startOf("day").toISOString();

        if (filterValues?.endDate) metaFilters.endDate = moment(filterValues.endDate)?.endOf("day").toISOString();

        if (filterValues.schedulerId) metaFilters.schedulerId = filterValues.schedulerId;

        if (filterValues.centreId) metaFilters.centreId = filterValues.centreId;

        if (filterValues.positionId) metaFilters.positionId = filterValues.positionId;

        if (filterValues.relieverId) metaFilters.relieverId = filterValues.relieverId;

        dispatch(setTableData({ ...tableData, metaFilter: metaFilters, metaFilterData: filterValues }));

        jobTable.onSearch<MetaFilterInterface>("", metaFilters);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this job?"
            />
            <RelieverModal
                handleCancel={handleRelieverModalCancel}
                isOpen={isRelieverModal}
                relieverList={job?.jobManagement?.map((ele) => ele.reliever) ?? null}
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={columns ?? defaultColumns}
                    data={jobTable.tableData ?? []}
                    tableKey="jobId"
                    currentOrder={jobTable.currentOrder}
                    reload={jobTable.fetchApi}
                    onSetOrderBy={jobTable.onSetOrderBy}
                    loading={jobTable.loading}
                    error={jobTable.error}
                    component={
                        <>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0 w-full items-center">
                                <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                    {(props) => <Filter {...props} reload={jobTable.fetchApi} />}
                                </Formik>
                            </div>
                        </>
                    }
                    tableOffset={jobTable.tableOffset}
                />
            </div>
        </>
    );
};

export default JobTable;
