import { Link } from "react-router-dom";
import { ImageComponetInterface } from "../../helper/interface";

export const ImageComponent = ({ setFieldValue, fieldName, value, isEdit }: ImageComponetInterface) => {
    const hasHttpOrHttps = (str: string) => {
        const regex = /https?:\/\//i;
        return regex.test(str);
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*,.pdf"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e?.target?.files?.[0]) {
                        setFieldValue(fieldName, e?.target?.files?.[0]);
                    }
                }}
            />
            {isEdit && value && hasHttpOrHttps(value) && (
                <Link to={value} target="_blank">
                    view
                </Link>
            )}
        </div>
    );
};
