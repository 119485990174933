import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setTableData } from "../../store/slice/BaseSlice";
import { JobFilterFields } from "../../helper/interface";

const Job = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const resetTableOffset = useCallback((tabName: string) => {
        dispatch(
            setTableData({
                page: 1,
                limit: 10,
                maxPage: 1,
                search: null,
                orderBy: null,
                tableFor: "job",
                tabFor: tabName,
                metaFilterData: {
                    fieldName: JobFilterFields.date,
                    schedulerId: null,
                    centreId: null,
                    positionId: null,
                    value: null,
                },
            })
        );
    }, []);

    return (
        <div>
            <ul className="nav nav-boxed-tabs mt-8" role="tablist">
                <li className="nav-item box flex-1" role="presentation">
                    <button
                        className={`nav-link w-full py-2 ${pathname.includes("pending") ? "active" : ""}`}
                        data-tw-toggle="pill"
                        type="button"
                        role="tab"
                        aria-selected="true"
                        onClick={() => {
                            resetTableOffset("pending");
                            navigate("/job/pending");
                        }}
                    >
                        Pending
                    </button>
                </li>
                <li className="nav-item box flex-1" role="presentation">
                    <button
                        className={`nav-link w-full py-2 ${pathname.includes("active") ? "active" : ""}`}
                        data-tw-toggle="pill"
                        type="button"
                        role="tab"
                        aria-selected="true"
                        onClick={() => {
                            resetTableOffset("active");
                            navigate("/job/active");
                        }}
                    >
                        Active
                    </button>
                </li>
                <li
                    className="nav-item flex-1"
                    role="presentation"
                    onClick={() => {
                        resetTableOffset("completed");
                        navigate("/job/completed");
                    }}
                >
                    <button
                        className={`nav-link box w-full py-2 ${pathname.includes("completed") ? "active" : ""}`}
                        data-tw-toggle="pill"
                        type="button"
                        role="tab"
                        aria-selected="false"
                    >
                        Completed
                    </button>
                </li>
            </ul>
            <div className="tab-content mt-5">{<Outlet />}</div>
        </div>
    );
};

export default Job;
