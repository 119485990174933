import { Formik } from "formik";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/Api";
import Loading from "../../container/Loading";
import { addOrEditInvoiceValidation } from "../../helper/constant";
import { InvoiceInterface } from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import AddEditInvoiceForm from "./AddEditInvoiceForm";
import { onEditInvoice } from "../../service/invoice";

const EditInvoice: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { invoiceId = "" } = useParams();

    const { res, loading, error } = useFetch<InvoiceInterface>(`${api.admin.getInvoiceById}/${invoiceId}`);

    const onSubmit = async (values: InvoiceInterface) => {
        const payload = {
            invoiceId: +invoiceId,
            description: values.description,
            invoiceFile: values.invoiceFile,
            fromDate: values.fromDate,
            toDate: values.toDate,
            amount: values.amount,
            schedulerId: values.schedulerId,
            dueDate: values.dueDate,
        };

        onEditInvoice(payload, navigate, dispatch);
    };

    const oldVal = res?.data;

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/invoice")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit invoice</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : oldVal ? (
                    <Formik initialValues={oldVal} validationSchema={addOrEditInvoiceValidation} onSubmit={onSubmit}>
                        {(props) => <AddEditInvoiceForm {...props} isEdit={true} />}
                    </Formik>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default EditInvoice;
