import React, { useEffect } from "react";
import { Form } from "formik";
import {
    CentreForSelect,
    FilterForJobTable,
    JobFilterFields,
    PositionForSelect,
    RelieverForSelect,
    SchedulerForSelect,
    StateInterface,
} from "../../helper/interface";
import { api } from "../../config/Api";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import { useParams } from "react-router";
import Select, { MultiValue } from "react-select";
import { setTableData } from "../../store/slice/BaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import { JobFilterFieldsType } from "../../helper/constant";
import CustomDatePicker from "../datepicker/CustomDatePicker";

const Filter = ({ setFieldValue, values, reload, resetForm, setFieldTouched }: FilterForJobTable) => {
    const { pageType = "" } = useParams();

    const dispatch = useDispatch();

    const schedulersData = useFetch<SchedulerForSelect[]>(api.admin.schedulersForCentre);

    const centreData = useFetch<CentreForSelect[]>(`${api.admin.getCentre}`);

    const positions = useFetch<PositionForSelect[]>(`${api.admin.getAllPositionForSelect}`);

    const relieverData = useFetch<RelieverForSelect[]>(api.admin.reliever);

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    useEffect(() => {
        resetForm();
    }, [pageType]);

    const handleSchedulerChange = (selectedItem: MultiValue<SchedulerForSelect>) => {
        const schedulers = selectedItem?.map((ele) => ele.schedulerId).join(",");
        setFieldValue("value", schedulers);
        setFieldValue("schedulerId", selectedItem);
    };

    const handleCentreChange = (selectedItem: MultiValue<CentreForSelect>) => {
        const centres = selectedItem?.map((ele) => ele.centreId).join(",");
        setFieldValue("value", centres);
        setFieldValue("centreId", selectedItem);
    };

    const handleRelieverChange = (selectedItem: MultiValue<RelieverForSelect>) => {
        const relievers = selectedItem?.map((ele) => ele.relieverId).join(",");
        setFieldValue("value", relievers);
        setFieldValue("relieverId", selectedItem);
    };

    const handlePositionChange = (selectedItem: MultiValue<PositionForSelect>) => {
        const positions = selectedItem?.map((ele) => ele.positionId).join(",");
        setFieldValue("value", positions);
        setFieldValue("positionId", selectedItem);
    };

    const handleReset = () => {
        dispatch(
            setTableData({
                page: 1,
                limit: 10,
                maxPage: tableData.maxPage,
                search: null,
                orderBy: null,
                tableFor: "job",
                tabFor: pageType,
                metaFilterData: {
                    fieldName: JobFilterFields.date,
                    schedulerId: null,
                    centreId: null,
                    positionId: null,
                    value: null,
                },
            })
        );

        reload(`${api.admin.getJobs}?type=${pageType}`);

        resetForm();
    };

    const getMinStartDate = () => {
        if (pageType === "pending" || pageType === "active") {
            return new Date(moment().format("YYYY-MM-DD"));
        }
    };

    const getMaxStartDate = () => {
        if (values.endDate) {
            return new Date(moment(values.endDate).format("YYYY-MM-DD"));
        } else if (pageType === "completed") {
            return new Date(moment().format("YYYY-MM-DD"));
        }
    };

    const getMinEndDate = () => {
        if (!values.startDate && (pageType === "pending" || pageType === "active")) {
            return new Date(moment().format("YYYY-MM-DD"));
        }

        if (values.startDate) {
            return new Date(moment(values.startDate).format("YYYY-MM-DD"));
        }
    };

    const getMaxEndDate = () => {
        if (pageType === "completed") {
            return new Date(moment().format("YYYY-MM-DD"));
        }
    };

    const handleOptionChange = (value: JobFilterFields) => {
        setFieldValue("fieldName", value);
    };

    return (
        <Form id="tabulator-html-filter-form" className="flex items-center job-pending-style align-items-baseline">
            <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                <label className="flex-none mr-2 mb-0">Select filter</label>
                <div className="tom-select">
                    <Listbox value={values.fieldName} onChange={handleOptionChange}>
                        <Listbox.Button className="form-control ts-input">
                            <div className="item">{values?.fieldName ?? "Select a scheduler"}</div>
                        </Listbox.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                            onBlur={() => setFieldTouched("fieldName", true)}
                        >
                            <Listbox.Options className="ts-dropdown single w-full">
                                <div role="listbox" className="ts-dropdown-content">
                                    {JobFilterFieldsType ? (
                                        <>
                                            {JobFilterFieldsType.map((data) => (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || values?.fieldName === data ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={data}
                                                    value={data}
                                                >
                                                    {data}
                                                </Listbox.Option>
                                            ))}
                                        </>
                                    ) : null}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </Listbox>
                </div>
            </div>

            {JobFilterFields.scheduler === values.fieldName ? (
                <div className="flex items-center sm:mr-4">
                    <label className="form-label">Schedulers</label>
                    <div>
                        {schedulersData?.res?.data ? (
                            <Select
                                isMulti
                                name="schedulerId"
                                options={schedulersData?.res?.data}
                                value={values?.schedulerId}
                                onChange={(selectedItem) => handleSchedulerChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style py-1"}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.schedulerId}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}

            {JobFilterFields.centre === values.fieldName ? (
                <div className="sm:flex flex items-center sm:mr-4">
                    <label className="form-label">Centre</label>
                    <div>
                        {centreData?.res?.data ? (
                            <Select
                                isMulti
                                name="centreId"
                                options={centreData?.res?.data}
                                value={values?.centreId}
                                onChange={(selectedItem) => handleCentreChange(selectedItem)}
                                className={"form-control job-filter-style py-1"}
                                classNamePrefix="select"
                                getOptionLabel={(option) => option?.centreName}
                                getOptionValue={(option) => option?.centreId}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}

            {JobFilterFields.reliever === values.fieldName ? (
                <div className="sm:flex flex items-center sm:mr-4">
                    <label className="form-label">Reliever</label>
                    <div>
                        {relieverData?.res?.data ? (
                            <Select
                                isMulti
                                name="relieverId"
                                options={relieverData?.res?.data}
                                value={values?.relieverId}
                                onChange={(selectedItem) => handleRelieverChange(selectedItem)}
                                className={"form-control job-filter-style py-1"}
                                classNamePrefix="select"
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.relieverId}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}

            {JobFilterFields.position === values.fieldName ? (
                <div className="sm:flex flex items-center sm:mr-4">
                    <label className="form-label">Position</label>
                    <div>
                        {positions.res?.data ? (
                            <Select
                                isMulti
                                name="positionId"
                                options={positions?.res?.data}
                                value={values?.positionId}
                                onChange={(selectedItem) => handlePositionChange(selectedItem)}
                                className={"form-control job-filter-style py-1"}
                                classNamePrefix="select"
                                getOptionLabel={(option) => option?.name}
                                getOptionValue={(option) => option?.name}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}

            <div>
                <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                    <label htmlFor="startDate">From</label>
                    <CustomDatePicker
                        id="startDate"
                        name="startDate"
                        minDate={getMinStartDate()}
                        maxDate={getMaxStartDate()}
                        selected={values.startDate ? new Date(values.startDate) : null}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div>
                <div className="flex items-center sm:mr-4 py-1 job-filter-form">
                    <label htmlFor="endDate" className="form-label">
                        To
                    </label>
                    <CustomDatePicker
                        id="endDate"
                        name="endDate"
                        minDate={getMinEndDate()}
                        maxDate={getMaxEndDate()}
                        selected={values.endDate ? new Date(values.endDate) : null}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div className="flex mt-2 md:mt-0">
                <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16">
                    Go
                </button>
                <button id="tabulator-html-filter-reset" type="button" className="btn btn-secondary w-16 ml-2" onClick={handleReset}>
                    Reset
                </button>
            </div>
        </Form>
    );
};

export default Filter;
