import { FC, useCallback, useState } from "react";
import usePathName from "../../../hooks/usePathName";
import UserTabs from "../UserTabs";
import { useNavigate } from "react-router-dom";
import {
    ActivateDeactivateSchedulerInterface,
    ApprovalState,
    PreviewFilesInterface,
    SchedulerInterface,
    SchedulerTableInterface,
} from "../../../helper/interface";
import { AlertTriangle, Bell, CheckSquare, Download, FileText, Send, Trash2 } from "react-feather";
import CustomTable from "../../table/CustomTable";
import useTable from "../../../hooks/useTable";
import { api } from "../../../config/Api";
import SearchForm from "../../table/SearchForm";
import DeleteModal from "../../modal/DeleteModal";
import {
    onActivateDeactivateScheduler,
    onApproveDeclineUser,
    onDeleteScheduler,
    onSchedulerBulkUpload,
    onSchedulerSampleFileDownload,
} from "../../../service/user";
import { useDispatch } from "react-redux";
import FileUploadModal from "../../modal/FileUploadModal";
import { Tooltip } from "react-tooltip";
import ApprovalUserModal from "../../modal/ApprovalUserModel";
import { ApprovalTypes } from "../../../helper/constant";
import RejectModal from "../../modal/RejectModal";
import NotifyUserModal from "../../modal/NotifyUser";

const Scheduler: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [isNotifyModalOpen, setNotifyModalOpen] = useState(false);
    const [scheduler, setScheduler] = useState<SchedulerInterface | null>(null);
    const [files, setFiles] = useState<PreviewFilesInterface[]>([]);
    const [showApproved, setShowApproved] = useState(true);
    const [approvalId, setApprovalId] = useState<ApprovalState | null>(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    usePathName([{ pathName: "Scheduler", href: "/user/scheduler" }]);

    const schedulerTable = useTable<SchedulerTableInterface>("scheduler", api.admin.getScheduler, false, {
        showApproved,
    });

    const onDeleteClick = useCallback((record: SchedulerInterface) => {
        setScheduler(record);
        setIsOpen(true);
    }, []);

    const onDelete = () => {
        if (scheduler?.schedulerId) {
            onDeleteScheduler(scheduler.schedulerId, dispatch, schedulerTable.fetchApi, setIsOpen);
        }
    };

    const onNotifyClick = useCallback((record: SchedulerInterface) => {
        setScheduler(record);
        setNotifyModalOpen(true);
    }, []);

    const handleNotifyModalCancel = () => {
        setNotifyModalOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const approveToggle = {
        title: "APPROVED",
        key: "approval",
        render: (record: SchedulerTableInterface) => {
            return (
                <span className={`${record.approval === ApprovalTypes.PENDING ? "text-warning" : "text-danger"}`}>{record.approval}</span>
            );
        },
    };

    const columns = [
        {
            title: "#",
            key: "schedulerId",
            render: (record: SchedulerTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "NAME",
            key: "name",
            sortable: true,
            render: (record: SchedulerTableInterface) => {
                return <span>{record.name}</span>;
            },
        },
        {
            title: "EMAIL",
            key: "email",
            sortable: true,
            render: (record: SchedulerTableInterface) => {
                return <span>{record.email}</span>;
            },
        },
        {
            title: "MOBILE NUMBER",
            key: "mobileNumber",
            render: (record: SchedulerTableInterface) => {
                return <span>{record.mobileNumber}</span>;
            },
        },
        {
            title: "PLATFORM",
            key: "signUpPlatform",
            render: (record: SchedulerTableInterface) => {
                return <span>{record.signUpPlatform}</span>;
            },
        },
        ...(!showApproved ? [approveToggle] : []),
        {
            title: "STATUS",
            key: "isActive",
            render: (record: SchedulerTableInterface) => {
                const payload = {
                    schedulerId: record.schedulerId,
                    isActive: !record.isActive,
                };
                return (
                    <div className="form-switch form-check flex justify-center flex-col">
                        <input
                            id="isActive"
                            name="isActive"
                            onChange={() => onActivateDeactivate(payload)}
                            checked={record.isActive}
                            className={"form-check-input"}
                            type="checkbox"
                        />
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: SchedulerTableInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center cursor-pointer">
                            <Tooltip id="simpleTooltipEditDeleteScheduler" place="top" />
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                data-tooltip-content={"View Feedback"}
                                onClick={() => {
                                    navigate(`/feedback?schedulerId=${record?.schedulerId}`);
                                }}
                            >
                                <Send className="w-4 h-4 mr-1" />
                            </button>
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                data-tooltip-content={"Send Notification"}
                                onClick={() => onNotifyClick(record)}
                            >
                                <Bell className="w-4 h-4 mr-1" />
                            </button>
                            {record.approval === ApprovalTypes.PENDING ? (
                                <>
                                    <button
                                        className="flex items-center mr-3 text-success"
                                        data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                        data-tooltip-content={"Edit reliever"}
                                        onClick={() => setApprovalId({ id: record.schedulerId, status: ApprovalTypes.APPROVE })}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        className="flex items-center mr-3 text-danger"
                                        data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                        data-tooltip-content={"Edit reliever"}
                                        onClick={() => setApprovalId({ id: record.schedulerId, status: ApprovalTypes.REJECTED })}
                                    >
                                        Reject
                                    </button>
                                </>
                            ) : null}
                            <button
                                className="flex items-center mr-3 text-primary"
                                data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                data-tooltip-content={"Edit scheduler"}
                                onClick={() => navigate(`/user/scheduler/edit/${record.schedulerId}`)}
                            >
                                <CheckSquare className="w-4 h-4 mr-1" /> Edit
                            </button>
                            <button
                                className={`flex items-center ${record?.deletedAt ? "color-red" : "text-danger"} cursor-pointer`}
                                data-tooltip-id="simpleTooltipEditDeleteScheduler"
                                data-tooltip-content={"Delete scheduler"}
                                onClick={() => onDeleteClick(record)}
                            >
                                {record?.deletedAt ? <AlertTriangle className="w-4 h-4 mr-1" /> : <Trash2 className="w-4 h-4 mr-1" />}{" "}
                                Delete
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

    const onActivateDeactivate = (payload: ActivateDeactivateSchedulerInterface) => {
        onActivateDeactivateScheduler(payload, dispatch, schedulerTable.fetchApi);
    };

    const onResetXlsxForm = () => {
        setFiles([]);
    };

    const handleCancelForUploadModal = () => {
        setUploadModal(false);
        onResetXlsxForm();
    };

    const onSuccessUpload = () => {
        schedulerTable.fetchApi();
        handleCancelForUploadModal();
    };

    const onSubmitXlsxForm = () => {
        onSchedulerBulkUpload(files[0], dispatch, onSuccessUpload);
    };

    const onSampleXlsxDownload = () => {
        onSchedulerSampleFileDownload(dispatch);
    };

    const onChangeApproveType = useCallback(
        (value: boolean) => {
            setShowApproved(value);

            const filterValue = { showApproved: value };

            schedulerTable.setLParams(filterValue);
            schedulerTable.onSearch("", filterValue);
        },
        [schedulerTable.setLParams, schedulerTable.onSearch]
    );

    const onCancelApprovalModel = useCallback(() => {
        setApprovalId(null);
    }, []);

    const onApproveOrDecline = useCallback(
        (status: ApprovalTypes) => {
            // On response of success
            const onSuccess = () => {
                schedulerTable.fetchApi();
                setApprovalId(null);
            };

            if (approvalId?.id) onApproveDeclineUser(approvalId.id, status, dispatch, onSuccess, api.admin.approveDeclineScheduler);
        },
        [approvalId]
    );

    return (
        <>
            <UserTabs onChangeApproveType={onChangeApproveType} showApproved={showApproved}>
                <ApprovalUserModal
                    handleCancel={onCancelApprovalModel}
                    isOpen={!!approvalId?.id}
                    onOk={() => onApproveOrDecline(ApprovalTypes.APPROVE)}
                />
                <RejectModal
                    handleCancel={onCancelApprovalModel}
                    isOpen={!!(approvalId && approvalId.id && approvalId.status === ApprovalTypes.REJECTED)}
                    onReject={() => onApproveOrDecline(ApprovalTypes.REJECTED)}
                    subTitle="Reject user"
                />
                <DeleteModal
                    handleCancel={handleCancel}
                    isOpen={isOpen}
                    onDelete={onDelete}
                    subTitle="Do you really want to delete this scheduler?"
                />
                <NotifyUserModal isOpen={isNotifyModalOpen} handleCancel={handleNotifyModalCancel} schedulerId={scheduler?.schedulerId} />
                <FileUploadModal
                    handleCancel={handleCancelForUploadModal}
                    isOpen={uploadModal}
                    files={files}
                    setFiles={setFiles}
                    onSubmit={onSubmitXlsxForm}
                    onReset={onResetXlsxForm}
                    subTitle="Bulk upload for scheduler"
                />
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <CustomTable
                        cols={columns}
                        onSetOrderBy={schedulerTable.onSetOrderBy}
                        data={schedulerTable.tableData ?? []}
                        tableKey="schedulerId"
                        reload={schedulerTable.fetchApi}
                        currentOrder={schedulerTable.currentOrder}
                        error={schedulerTable.error}
                        loading={schedulerTable.loading}
                        component={
                            <>
                                <SearchForm
                                    resetSearch={schedulerTable.resetSearch}
                                    onSearch={schedulerTable.onSearch}
                                    searchPlaceHolder="Search by name or email"
                                />
                                <div className="flex flex-col w-full mt-5 sm:mt-0 sm:flex-row sm:w-auto mr-2 sm:mr-0">
                                    <Tooltip id="simpleTooltipScheduler" place="top" />
                                    <button
                                        data-tooltip-id="simpleTooltipScheduler"
                                        data-tooltip-content="Add new scheduler"
                                        className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                        onClick={() => {
                                            navigate("/user/scheduler/add");
                                        }}
                                    >
                                        Add new
                                    </button>
                                    <button
                                        className="btn btn-outline-primary mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                        data-tooltip-id="simpleTooltipScheduler"
                                        data-tooltip-content="Import XLS"
                                        onClick={() => setUploadModal(true)}
                                    >
                                        <FileText className="w-4 h-4 mr-1 flex items-center justify-center" />
                                        Import
                                    </button>
                                    <button
                                        className="btn btn-outline-success mt-2 w-full mr-0 sm:mr-2 sm:mt-0 md:w-1/2 sm:w-auto"
                                        data-tooltip-id="simpleTooltipScheduler"
                                        data-tooltip-content="Download sample XLS"
                                        onClick={onSampleXlsxDownload}
                                    >
                                        <Download className="w-4 h-4 mr-1 flex items-center justify-center" />
                                        Sample file
                                    </button>
                                </div>
                            </>
                        }
                        tableOffset={schedulerTable.tableOffset}
                    />
                </div>
            </UserTabs>
        </>
    );
};

export default Scheduler;
