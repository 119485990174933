import moment from "moment";
import { AddEditJobInterface } from "../../helper/interface";
import { getDate, maxWeekDay } from "../../helper/helper";
import { default as momentTimeZone } from "moment-timezone";
import { NZTimeZone } from "../../helper/constant";

export const JobPayloadFn = (values: AddEditJobInterface) => {
    const payload: AddEditJobInterface = { ...values };

    const selectedStartDate = momentTimeZone.utc(values.startDate).tz(NZTimeZone).format("YYYY-MM-DD");
    const selectedEndDate = momentTimeZone.utc(values.endDate).tz(NZTimeZone).format("YYYY-MM-DD");

    const startDateTime = moment(selectedStartDate + values.startTime, "YYYY-MM-DD HH:mm:s").toISOString();

    if (values.repeat === "Don't repeat") {
        const endDateTime = moment(selectedEndDate + values.endTime, "YYYY-MM-DD HH:mm:s").toISOString();
        payload.startDate = startDateTime;
        payload.endDate = endDateTime;
    }

    if (values.repeat === "Same week" && values?.daysOfWeek) {
        const daysArray = values.daysOfWeek.map((ele) => ele.value);

        const weekDay = maxWeekDay(daysArray);

        if (weekDay) {
            const selectedDayDate = getDate(startDateTime, weekDay);
            const momentDate = moment(selectedDayDate, "DD/MM/YYYY");
            const formattedDate = momentDate.format("YYYY-MM-DD");
            const formatedDateTime = moment(formattedDate + values.endTime, "YYYY-MM-DD HH:mm:s").toISOString();
            payload.startDate = startDateTime;
            payload.endDate = formatedDateTime;
        }
    }

    if (values.endDate && values.repeat !== "Don't repeat" && values.repeat !== "Same week") {
        const endDateTime = moment(values.endDate + " " + values.endTime, "YYYY-MM-DD HH:mm").toISOString();
        payload.startDate = startDateTime;
        payload.endDate = endDateTime;
    }

    return payload;
};
