import { createSlice } from "@reduxjs/toolkit";
import {
    BaseSliceInterface,
    SetPageStateInterface,
    SetBooleanInterface,
    SetPagePathStateInterface,
    TableDataPayloadInterface,
    JobFilterFields,
} from "../../helper/interface";

const initialState: BaseSliceInterface = {
    page: "scheduler",
    pagePath: [{ pathName: "Home", href: "/dashboard" }],
    loading: false,
    tableData: {
        page: 1,
        limit: 10,
        maxPage: 1,
        search: null,
        orderBy: null,
        tableFor: null,
        tabFor: "pending",
        metaFilterData: {
            fieldName: JobFilterFields.date,
        },
    },
};

export const BaseSlice = createSlice({
    name: "base",
    initialState,
    reducers: {
        setPage: (state, action: SetPageStateInterface) => {
            state.page = action.payload;
        },
        setPagePath: (state, action: SetPagePathStateInterface) => {
            state.pagePath = action.payload;
        },
        setLoading: (state, action: SetBooleanInterface) => {
            state.loading = action.payload;
        },
        setTableData: (state, action: TableDataPayloadInterface) => {
            state.tableData = action.payload;
        },
    },
});

export const { setPage, setPagePath, setLoading, setTableData } = BaseSlice.actions;
export default BaseSlice.reducer;
