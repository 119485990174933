import { Formik } from "formik";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../config/Api";
import Loading from "../../../container/Loading";
import { addOrEditSchedulerValidation } from "../../../helper/constant";
import { SchedulerInterface } from "../../../helper/interface";
import useFetch from "../../../hooks/useFetch";
import usePathName from "../../../hooks/usePathName";
import AddEditScheduler from "./AddEditScheduler";
import { AlertTriangle, ArrowLeftCircle } from "react-feather";
import { onEditScheduler } from "../../../service/user";

const EditScheduler: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { schedulerId = "" } = useParams();

    const { res, loading, error } = useFetch<SchedulerInterface>(`${api.admin.getScheduler}/${schedulerId}`);

    usePathName([
        { pathName: "User", href: "/user/scheduler" },
        { pathName: "Scheduler", href: "/user/scheduler" },
        { pathName: "Edit", href: `/user/scheduler/edit/${schedulerId}` },
    ]);

    const onSubmitBreak = (values: SchedulerInterface) => {
        const payload = {
            dob: values.dob,
            mobileNumber: values.mobileNumber,
            email: values.email,
            name: values.name,
            schedulerId: +schedulerId,
            profileImg: values.profileImg,
            centre: values.centre,
        };

        onEditScheduler(payload, navigate, dispatch);
    };

    const initialValues = res?.data;

    useEffect(() => {
        const centreDetails = initialValues?.centreList?.map((ele) => ele.centreDetails);

        if (centreDetails && initialValues) {
            initialValues.centre = centreDetails;
        }
    }, [res?.data]);

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/user/scheduler")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Edit scheduler</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                {error ? (
                    <div className="alert alert-danger mt-6 show flex items-center mb-2" role="alert">
                        <AlertTriangle className="w-6 h-6 mr-2" /> {error}
                    </div>
                ) : loading ? (
                    <Loading />
                ) : initialValues ? (
                    <Formik initialValues={initialValues} validationSchema={addOrEditSchedulerValidation} onSubmit={onSubmitBreak}>
                        {(props) => <AddEditScheduler {...props} />}
                    </Formik>
                ) : null}
            </div>
        </div>
    );
};

export default EditScheduler;
