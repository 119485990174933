import { Form, Formik, FormikHelpers } from "formik";
import { sendNotificationValidation } from "../../helper/constant";
import { useDispatch, useSelector } from "react-redux";
import { StateInterface } from "../../helper/interface";
import { Listbox, Transition } from "@headlessui/react";
import { successToast } from "../../helper/toast";
import { handleCatchResponse } from "../../helper/helper";
import { setLoading } from "../../store/slice/BaseSlice";
import { AxiosError } from "axios";
import { dataService } from "../../config/DataService";
import { api } from "../../config/Api";
import usePathName from "../../hooks/usePathName";

enum USER_TYPE {
    BOTH = "",
    SCHEDULER = "Scheduler",
    RELIEVER = "Reliever",
}

enum DEVICE_TYPE {
    BOTH = "",
    ANDROID = "android",
    IOS = "ios",
}

interface NotificationForm {
    title: string;
    description: string;
    deviceType: DEVICE_TYPE;
    userType: USER_TYPE;
}

const Notification = () => {
    const loading = useSelector((state: StateInterface) => state.base.loading);
    const dispatch = useDispatch();

    usePathName([{ pathName: "Notification", href: "/notification" }]);

    const onSubmit = async (values: NotificationForm, { resetForm }: FormikHelpers<NotificationForm>) => {
        try {
            dispatch(setLoading(true));

            const payload = {
                title: values.title,
                description: values.description,
                deviceType: values.deviceType,
                userType: values.userType,
            };

            const response = await dataService.post(api.admin.sendNotification, payload);

            successToast(response.data?.message);
        } catch (error) {
            handleCatchResponse(error as AxiosError);
        } finally {
            resetForm();
            dispatch(setLoading(false));
        }
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-1 font-medium text-center text-lg -ml-2">Send notification</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik
                    initialValues={{
                        title: "",
                        description: "",
                        deviceType: DEVICE_TYPE.BOTH,
                        userType: USER_TYPE.BOTH,
                    }}
                    validationSchema={sendNotificationValidation}
                    onSubmit={onSubmit}
                >
                    {({ handleChange, handleBlur, values, errors, touched, dirty, setFieldTouched, setFieldValue }) => (
                        <Form>
                            <div className="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                                <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                                    <label className="mb-2 form-label">Device type</label>
                                    <div className="tom-select">
                                        <Listbox
                                            value={values.deviceType}
                                            onChange={(value: string) => {
                                                setFieldValue("deviceType", value);
                                            }}
                                            disabled={loading}
                                        >
                                            <Listbox.Button className="form-control ts-input">
                                                <div className="item">{values?.deviceType || "Select device type"}</div>
                                            </Listbox.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                                onBlur={() => setFieldTouched("deviceType", true)}
                                            >
                                                <Listbox.Options className="ts-dropdown single w-full">
                                                    <div role="listbox" className="ts-dropdown-content">
                                                        {Object.values(DEVICE_TYPE).map((data) => (
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.deviceType === data ? "optionColor" : null
                                                                    }`
                                                                }
                                                                key={data}
                                                                value={data}
                                                            >
                                                                {data || "Select device type"}
                                                            </Listbox.Option>
                                                        ))}
                                                    </div>
                                                </Listbox.Options>
                                            </Transition>
                                        </Listbox>
                                    </div>
                                    {errors.deviceType && touched.deviceType && <div className="text-danger">{errors.deviceType}</div>}
                                </div>
                                <div className="intro-y col-span-12 sm:col-span-6 position-custom">
                                    <label className="mb-2 form-label">User type</label>
                                    <div className="tom-select">
                                        <Listbox
                                            value={values.userType}
                                            onChange={(value: string) => {
                                                setFieldValue("userType", value);
                                            }}
                                            disabled={loading}
                                        >
                                            <Listbox.Button className="form-control ts-input">
                                                <div className="item">{values?.userType || "Select user type"}</div>
                                            </Listbox.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                                onBlur={() => setFieldTouched("userType", true)}
                                            >
                                                <Listbox.Options className="ts-dropdown single w-full">
                                                    <div role="listbox" className="ts-dropdown-content">
                                                        {Object.values(USER_TYPE).map((data) => (
                                                            <Listbox.Option
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                                        active || values.userType === data ? "optionColor" : null
                                                                    }`
                                                                }
                                                                key={data}
                                                                value={data}
                                                            >
                                                                {data || "Select user type"}
                                                            </Listbox.Option>
                                                        ))}
                                                    </div>
                                                </Listbox.Options>
                                            </Transition>
                                        </Listbox>
                                    </div>
                                    {errors.userType && touched.userType && <div className="text-danger">{errors.userType}</div>}
                                </div>
                                <div className="intro-y col-span-12 sm:col-span-6">
                                    <label htmlFor="title" className="form-label">
                                        Title
                                    </label>
                                    <input
                                        id="title"
                                        name="title"
                                        type="text"
                                        onChange={handleChange}
                                        value={values.title}
                                        onBlur={handleBlur}
                                        className={`form-control ${errors.title && touched.title && "border-danger"}`}
                                        placeholder="Enter your notification title here"
                                    />
                                    {errors.title && touched.title && <div className="text-danger">{errors.title}</div>}
                                </div>
                                <div className="intro-y col-span-12 sm:col-span-6">
                                    <label htmlFor="description" className="form-label">
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        name="description"
                                        type="text"
                                        onChange={handleChange}
                                        value={values.description}
                                        onBlur={handleBlur}
                                        className={`form-control ${errors.description && touched.description && "border-danger"}`}
                                        placeholder="Enter your description title here"
                                    />
                                    {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
                                </div>
                                <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                                    <button className="btn btn-secondary w-24" type="reset" disabled={!dirty}>
                                        Reset
                                    </button>
                                    <button className="btn btn-primary w-24 ml-2" type="submit" disabled={loading || !dirty}>
                                        Send
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Notification;
